import React, { useEffect, useRef } from "react";
import ScrollToTop from "react-scroll-to-top";

import styles from "./index.module.scss";

const BackToTop: React.FC = () => {
  const BackTopRef: any = useRef();

  const trackScrolling = () => {
    const currentPos: any = window?.pageYOffset;
    if (BackTopRef.current) {
      if (currentPos > 100) {
        BackTopRef.current.style.display = "block";
      } else {
        BackTopRef.current.style.display = "none";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);

    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  return (
    <div ref={BackTopRef} className={styles.scrollToTopWrapper}>
      <ScrollToTop
        className={styles.scrollToTop}
        smooth
        color="rgba(0,0,0,.8)"
      />
    </div>
  );
};

export default BackToTop;
