import React, { useEffect, useState } from "react";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import RoleContent from "./roleContent";

const ChooseRole: React.FC = ({ children }) => {
  const context = useTimeSheetContext();
  const { roleStep, teamAssociate } = context;
  const [rolesArray, setRolesArray] = useState<any>([]);


  useEffect(() => {
    if (teamAssociate?.authRoleList) {
      setRolesArray(teamAssociate?.authRoleList);
    }
  }, [teamAssociate]);





  return (
    <>
      {roleStep === "CHOOSE_ROLE" ? (
        <div>
          <RoleContent rolesArray={rolesArray} />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default ChooseRole;
