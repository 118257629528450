import React, { useEffect, useState } from "react";

import { getCookie, setCookie } from "common/helpers/cookies";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import Header from "common/components/Header";
import WorkSpaceContent from "./WorkSpaceContent";

const ChooseWorkSpace: React.FC = ({ children }) => {
  const context = useTimeSheetContext();
  const { wsStep, authInfo, screenRoleMappingForTeamMember } = context;
  const [companyArrayInfo, setCompanyArrayInfo] = useState<any>([]);
  const [teamAssoArrayInfo, setTeamAssoArrayInfo] = useState<any>([]);
  const currentWorkSpaceObj: any = getCookie("currentWorkSpace");
  const [compId, setCompId] = useState<any>();
  const [_email, _setEmail] = useState<any>();


  useEffect(() => {
    if (!currentWorkSpaceObj && authInfo?.hasMultiCompany) {
      setTeamAssoArrayInfo(authInfo.teamAssociateInfo); 
      setCompanyArrayInfo(authInfo.companyInfo);
      context.setWsStep("CHOOSE_WS");
    } else if (currentWorkSpaceObj && authInfo?.hasMultiCompany) {
      setCookie("email", currentWorkSpaceObj?.email);
      setCookie("snrId", currentWorkSpaceObj?.snrId || "SNR_APPS");
      setCookie("companyId", currentWorkSpaceObj?.companyId);

      _setEmail(currentWorkSpaceObj?.email);
      setCompId(currentWorkSpaceObj?.companyId);

      context.setWsStep("WS");
    } else if (!authInfo?.hasMultiCompany) {
      setCookie("authRoleCode", authInfo?.teamAssociateInfo?.authRoleCode);
      setCookie(
        "teamAssociateId",
        authInfo?.teamAssociateInfo?.teamAssociateId
      );
      setCookie("email", authInfo?.teamAssociateInfo?.email);
      setCookie("snrId", authInfo?.teamAssociateInfo?.snrId || "SNR_APPS");
      setCookie("companyId", authInfo?.teamAssociateInfo?.companyId);

      _setEmail(authInfo?.teamAssociateInfo?.email);
      setCompId(authInfo?.teamAssociateInfo?.companyId);
      context.setWsStep("WS");
    } else {
      console.log("this case is still not handle", {
        currentWorkSpaceObj,
        authInfo,
      });
    }
  }, [wsStep]);

  return (
    <>
      {wsStep === "CHOOSE_WS" ? (
        <div>
          <WorkSpaceContent
            companyArrayInfo={companyArrayInfo}
            teamAssoArrayInfo={teamAssoArrayInfo}
          />
        </div>
      ) : (
        <>{!!screenRoleMappingForTeamMember.length && children}</>
      )}
    </>
  );
};

export default ChooseWorkSpace;
