import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import makeRequest from "common/api/makeRequest";
import { Country, State } from "country-state-city";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCompanyActions from "timesheets/api/actions/companyCalls";
import { useSubCompanyActionCreators } from "timesheets/api/subCompanyActions";
import { timeSheetActionCreators } from "timesheets/api/timeSheetActions";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import {
  COMPANY_STATUS_REGISTERED,
  COMPANY_TYPE,
  SNR_APPS,
  UNITED_STATE_CODE,
} from "timesheets/utils/constant";
import * as Yup from "yup";
import { applActionCreators } from "../../../api/companyActions";
import CompanyTimeSheet from "../../../components/CompanyTimeSheet";
import {
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionTitleStyled,
  GridButtonStyled,
  GridContainerStyled,
  TitleBold,
  useStylesCommon,
} from "../../../styles/common";
import { handleCityCodeByCityName } from "../../../utils";
import CompanyBasic from "../CompanyBasic";
interface ICompCompanyInfoModal {
  closeModal: () => void;
}

const CheckBoxGridStyled = styled(Grid)`
  align-items: center;
  padding: 20px 0 0 30px;
`;

const CompanyInfoModal: React.FC<ICompCompanyInfoModal> = ({ closeModal }) => {
  const classes = useStylesCommon();
  const context = useTimeSheetContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [timeSheetFrequency, setTimeSheetFrequency] = useState<any>();
  const {
		companyInformation,
		teamAssociate,
		teamAssociate: { companyId, email, teamAssociateId },
	} = context;

  const {
    actionCreators: {
      updateCompanyInfo,
      updateTeamAssociateWithCompany,
      getTeamAssociateByEmailWithCompany,
    },
  } = applActionCreators();

  const {
    actionCreators: { updateCompanyInfomation },
  } = useSubCompanyActionCreators();

  const { fetchCompanyInfoInit } = useCompanyActions();

  const {
    actionCreators: { createClient },
  } = timeSheetActionCreators();

  useEffect(() => {
    if (timeSheetFrequency === "MONTHLY") {
      formik.setFieldValue("totalWorkingHours", "160");
    } else {
      formik.setFieldValue("totalWorkingHours", "40");
    }
  }, [timeSheetFrequency]);

  const fetchTeamAssociateWithCompany = useCallback(() => {
    makeRequest(
      () => {
        return getTeamAssociateByEmailWithCompany({ companyId, email });
      },
      (data: any) => {
        context.setTeamAssociate(data[0]);
      }
    );
  }, [getTeamAssociateByEmailWithCompany]);

  const allCountries = useMemo(() => {
    return Country.getAllCountries();
  }, []);

  //set default value for select country - state - city
  useEffect(() => {
    setCountryCode(companyMappingData?.countryCode);
  }, [companyInformation]);

  //code of country is selected
  const [countryCode, setCountryCode] = useState<string>("");

  //all data of state follow country to render
  const [allState, setAllState] = useState<any>([]);

  // set stateData from country code to render
  useEffect(() => {
    setAllState(State.getStatesOfCountry(countryCode || UNITED_STATE_CODE));
  }, [countryCode]);

  const handleSelectCountries = (e: any) => {
    setCountryCode(e.target.value);
    formik.setFieldValue("stateCode", "");
  };

  const [companyMappingData, setCompanyMappingData] = useState<any>();

  // mapping companyInformation
  useEffect(() => {
    const mappingData = {
      companyName: companyInformation?.companyName || "",
      firstName: companyInformation?.firstName || "",
      lastName: companyInformation?.lastName || "",
      address1: (companyInformation?.address ?? [])[0]?.address1 || "",
      address2: (companyInformation?.address ?? [])[0]?.address2 || "",
      zipCode: (companyInformation?.address ?? [])[0]?.zipCode || "",
      countryCode: (companyInformation?.address ?? [])[0]?.countryCode || "US",
      stateCode: (companyInformation?.address ?? [])[0]?.stateCode || "",
      cityName: (companyInformation?.address ?? [])[0]?.cityName || "",
      timeSheetFrequency:
        (companyInformation?.timeSheetInfo ?? [])[0]?.timeSheetFrequency || "",
      defaultWorkingHours:
        (companyInformation?.timeSheetInfo ?? [])[0]?.defaultWorkingHours || "",
      timeSheetDetailsFormat:
        (companyInformation?.timeSheetInfo ?? [])[0]?.timeSheetDetailsFormat ||
        "",
      // projectEndTrigger:
      //   (companyInformation?.timeSheetInfo ?? [])[0]?.projectEndTrigger || "",
      dayToSendReminder:
        (companyInformation?.timeSheetInfo ?? [])[0]?.dayToSendReminder || "",
    };
    setCompanyMappingData(mappingData);
  }, [companyInformation]);

  const validation = Yup.object({
    firstName: Yup.string().required("Required !"),
    lastName: Yup.string().required("Required !"),
    companyName: Yup.string().required("Required !"),
  });
  const formik = useFormik({
		initialValues: {
			companyName: companyMappingData?.companyName || "",
			firstName: companyMappingData?.fistName || "",
			lastName: companyMappingData?.lastName || "",
			address1: companyMappingData?.address1 || "",
			address2: companyMappingData?.address2 || "",
			zipCode: companyMappingData?.zipCode || "",
			countryCode: companyMappingData?.countryCode || UNITED_STATE_CODE,
			stateCode: companyMappingData?.stateCode || "",
			cityName: companyMappingData?.cityName || "",
			isFinalClient: context.trackingCheckbox || false,
			timeSheetFrequency: companyMappingData?.timeSheetFrequency || "WEEKLY",
			defaultWorkingHours: companyMappingData?.defaultWorkingHours || 8,
			isOvertime: companyMappingData?.isOvertime || true,
			timeSheetDetailsFormat: companyMappingData?.timeSheetDetailsFormat || "1",
			startingDay: companyMappingData?.startingDay || "MONDAY",
			dayToSendReminder: companyMappingData?.dayToSendReminder || "MONDAY",
			// projectEndTrigger: companyMappingData?.projectEndTrigger,
			requiredPjCode: "",
			requiredAttachmentTs: "",
			isBusinessCapability: false,
			isBusinessCapabilityRequired: false,
			isApplication: false,
			isApplicationRequired: false,
			isApplicationPhase: false,
			isApplicationPhaseRequired: false,
			reportViewList: "",
			timeSheetPattern: "",
      locations:''
		},
		enableReinitialize: true,
		validationSchema: validation,
		onSubmit: (values) => {
			const _isFinalClient = JSON.parse(values.isFinalClient);

			const timeSheetInfo = {
				...(companyInformation?.timeSheetInfo ?? [])[0],
				timeSheetFrequency: values.timeSheetFrequency,
				defaultWorkingHours: values.defaultWorkingHours,
				timeSheetDetailsFormat: values.timeSheetDetailsFormat,
				startingDay: values.startingDay,
				dayToSendReminder: values.dayToSendReminder,
				// projectEndTrigger: values.projectEndTrigger,
			};
			const payload = {
				companyId,
				email,
				snrId: SNR_APPS,
				teamAssociateId,
				companyName: values.companyName,
				companyStatus: COMPANY_STATUS_REGISTERED,
				enableFeatures: [{ isFinalClient: _isFinalClient }],
				companyType: _isFinalClient ? COMPANY_TYPE.END_CLIENT : COMPANY_TYPE.INTEGRATION_PARTNER,
				creationDate: teamAssociate.creationDate,
				isManager: true,
				firstName: values.firstName,
				lastName: values.lastName,
				clientName: values.companyName,
				address: [
					{
						address1: values.address1,
						address2: values.address2,
						cityCode: handleCityCodeByCityName(values.cityName),
						cityName: values.cityName,
						stateCode: values.stateCode,
						stateName:
							State.getStateByCodeAndCountry(values.stateCode, values.countryCode)?.name || "",
						countryCode: values.countryCode,
						countryName: Country.getCountryByCode(values.countryCode)?.name,
						zipCode: values.zipCode,
					},
				],
				isVisible: false,
				timeSheetInfo: [
					{
						...timeSheetInfo,
						startingDay: values.startingDay,
						reminderInfo: companyInformation.timeSheetInfo.reminderInfo,
						distributionInfo: {
							distributeTo: "",
							additionalTo: "",
						},

						reportViewList: values?.reportViewList || "",
						timeSheetPattern: values?.timeSheetPattern || "",
						requiredPjCode: values?.requiredPjCode || false,
						requiredAttachmentTs: values?.requiredAttachmentTs || false,
						isBusinessCapability: values?.isBusinessCapability || false,
						isBusinessCapabilityRequired:
							values?.isBusinessCapability === false
								? false
								: values?.isBusinessCapabilityRequired || false,
						isApplication: values?.isApplication || false,
						isApplicationRequired:
							values?.isApplication === false ? false : values?.isApplicationRequired || false,
						isApplicationPhase: values?.isApplicationPhase || false,
						isApplicationPhaseRequired:
							values?.isApplicationPhase === false
								? false
								: values?.isApplicationPhaseRequired || false,
					},
				],
				// locations: values?.locations,
			};

			handleUpdateFirstTimeForCompany(payload);
		},
	});

  const handleUpdateFirstTimeForCompany = useCallback(
    (payload) => {
      setLoading(true);
      makeRequest(
        () => {
          return updateCompanyInfomation(payload);
        },
        () => {
          fetchTeamAssociateWithCompany();
          context.setOpenSnackbarSuccess(true);
          setLoading(false);
          fetchCompanyInfoInit();
          closeModal();
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [updateCompanyInfomation]
  );

  return (
    <div>
      <AccordionStyled style={{ width: "100%" }}>
        <AccordionTitleStyled>
          <TitleBold>COMPANY INFORMATION</TitleBold>
        </AccordionTitleStyled>

        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className={classes.contentWrapper}
        >
          <CompanyBasic formik={formik}/>

          {/* Accordion Dropdown  */}
          <GridContainerStyled container spacing={2}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                  <Typography> Tell us more about your self ?</Typography>
                </AccordionSummaryStyled>
                <AccordionDetails>
                  {/* Checkbox */}
                  <CheckBoxGridStyled container spacing={2}>
                    <FormControl>
                      <RadioGroup
                        value={formik.values.isFinalClient}
                        name="isFinalClient"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      >
                        {/* <FormControlLabel
													value={true}
													control={<Radio />}
													label="Do you hire Candidates to work for your company (Is your company considered end client ?)"
												/> */}
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Do you hire Candidates to work for other companies (Is your company considered Integration Partner ?)"
                        />
                      </RadioGroup>
                    </FormControl>
                  </CheckBoxGridStyled>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </GridContainerStyled>

          <CompanyTimeSheet formik={formik} isModal={true} />

          {/* Button */}
          <GridButtonStyled container spacing={2}>
            <div className={classes.btnWrapper}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
              >
                SAVE
              </LoadingButton>
            </div>
          </GridButtonStyled>
        </form>
      </AccordionStyled>
    </div>
  );
};

export default CompanyInfoModal;
