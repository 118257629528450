import makeRequest from "common/api/makeRequest";
import { useCallback } from "react";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { ROUTES } from "components/Shared/pages";

const useAuthActions = () => {
  const context = useTimeSheetContext();
  const history = useHistory();
  const { setAuthInfo, setIsAuth } = context;

  const checkAuth = useCallback((isCheckAuth) => {
    makeRequest(
      () => {
        return Auth.currentAuthenticatedUser();
      },
      (user) => {
        const teamAssociateInfoEncrp =
          user.signInUserSession.idToken.payload.teamAssociateInfo;
        const teamAssociateInfoStr = atob(teamAssociateInfoEncrp);
        const teamAssociateInfo = JSON.parse(teamAssociateInfoStr);
        localStorage.setItem("associateToken", teamAssociateInfo.encryptAssociate)

        setAuthInfo(teamAssociateInfo);
        setIsAuth(true);
      },
      (e) => {
        if (isCheckAuth) {
          history.push(ROUTES.login);
        }
      }
    );
  }, []);

  return { checkAuth };
};

export default useAuthActions;
