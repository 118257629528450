import React from "react";
import { Breadcrumbs, Link as LinkMui, Box } from "@mui/material";

import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { Link } from "react-router-dom";

const BreadcrumbsBase: React.FC = () => {
	const { breadcrumbs } = useTimeSheetContext();
	const styleBox = {
		padding: "1rem",
		background: "rgba(0, 0, 0, 0.04)",
		"& a": {
			color: "inherit",
			textDecoration: "none",
		},
		"& span": {
			textTransform: "capitalize",
		}
	};

	return (
		<Box role="presentation" sx={styleBox}>
			<Breadcrumbs aria-label="breadcrumb">
				<Link to="/">
					<LinkMui
						component={"span"}
						underline="hover"
						color={breadcrumbs?.length > 0 ? "inherit" : "text.primary"}
					>
						Home
					</LinkMui>
				</Link>
				{breadcrumbs?.length > 0 &&
					breadcrumbs?.map((option: { active: boolean; url: string; label: string }, index) =>
						option?.active ? (
							<LinkMui
								key={`${option?.label}-${index}`}
								component={"span"}
								underline="hover"
								color={"text.primary"}
							>
								{option.label}
							</LinkMui>
						) : (
							<Link to={option?.url || '/'} key={`${option?.label}-${index}`}>
								<LinkMui component={"span"} underline="hover" color={"inherit"}>
									{option.label}
								</LinkMui>
							</Link>
						)
					)}
			</Breadcrumbs>
		</Box>
	);
};
export default BreadcrumbsBase;
