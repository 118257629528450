import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import makeRequest from "common/api/makeRequest";
import FormItemLabel from "common/components/FormItemLabel";
import moment from "moment";
import * as React from "react";
import useTimeSheetActions from "timesheets/api/actions/timesheetsCalls";
import { dashboardActionCreators } from "timesheets/api/dashboardActions";
import AssociateInfoDashboard from "timesheets/components/AssociateInfoDashboard";
import DashBoardChart from "timesheets/components/DashboardChart";
import { getYearlyData } from "timesheets/utils";
import { useTimeSheetContext } from "../../context/TimeSheetContext";
import {
  AccordionStyled,
  AccordionTitleStyled,
  GridContainerStyled,
  TitleBold,
} from "../../styles/common";
const cardSx = {
  padding: "0 1rem",
};
const cardSxItem = {
  boxShadow: "5px 1px 15px #e2e2e2",
  borderRadius: 4,
  minHeight: 500,
  padding: "2rem",
  position: "relative",
};

const renderDropdown = (loading, type, data) => {
  if (loading) {
    return (
      <MenuItem sx={{ justifyContent: "center" }} value={""}>
        <CircularProgress />
      </MenuItem>
    );
  } else {
    if (!data.length) {
      return (
        <MenuItem value={""}>
          {type === "VENDOR" ? "Vendors" : "Clients"} are not exist !
        </MenuItem>
      );
    } else {
      const res = data.filter((item) => item.isVisible !== false);
      return res.map((item, index) => {
        return (
          <MenuItem
            key={`${item.vendorId}-${index}`}
            value={type === "VENDOR" ? item?.vendorId : item?.clientId}
          >
            {type === "VENDOR" ? item?.vendorName : item?.clientName}
          </MenuItem>
        );
      });
    }
  }
};

const DashboardCompany: React.FC = () => {
  const context = useTimeSheetContext();
  const { vendors, clients, setBreadcrumbs, teamAssociate } = context;
  const companyId = teamAssociate?.companyId;

  const [dashboardType, setDashboardType] = React.useState<"VENDOR" | "CLIENT" | "COMPANY">(
		"COMPANY"
	);
  const [currentId, setCurrentId] = React.useState<string>("");
  const [dropdownData, setDropdownData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [companyChartData, setCompanyChartData] = React.useState<any>(false);
  const { fetchClients, fetchVendors } = useTimeSheetActions();
  const [year, setYear] = React.useState<any>("");
  const [datesData, setDatesData] = React.useState<any>([]);

  React.useEffect(() => {
    setBreadcrumbs([{ label: "Dashboard Client", active: true }]);
    fetchClients(setLoading);
    fetchVendors(setLoading);
    return () => context.setBreadcrumbs([]);
  }, []);

  React.useEffect(() => {
    if (dashboardType === "COMPANY") {
      setDatesData(getYearlyData(2, true));
      setYear(moment().format("YYYY"));
    }
  }, [dashboardType]);

  React.useEffect(() => {
    if (dashboardType === "COMPANY" && year) {
      handleGetChartDashboardCompany(year);
    }
  }, [dashboardType, year]);

  const {
    actionCreators: { getChartForCompany },
  } = dashboardActionCreators();

  const handleGetChartDashboardCompany = React.useCallback(
    (year) => {
      setLoading(true);
      makeRequest(
        () => {
          return getChartForCompany({ companyId, year });
        },
        (data: any) => {
          setCompanyChartData(data);
          setLoading(false);
        },
        () => setLoading(false)
      );
    },
    [getChartForCompany]
  );

  React.useEffect(() => {
    if (dashboardType === "VENDOR") {
      setDropdownData(vendors);
    }

    if (dashboardType === "CLIENT") {
      setDropdownData(clients);
    }
  }, [dashboardType, vendors, clients]);

      // prevent memory leak
      const [didMount, setDidMount] = React.useState(false);
      React.useEffect(() => {
        setDidMount(true);
        return () => setDidMount(false);
      }, []);
    
      if (!didMount) {
        return null;
      }

  return (
    <Box>
      <AccordionStyled>
        <AccordionTitleStyled>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TitleBold>Dashboard</TitleBold>
          </Box>
        </AccordionTitleStyled>
      </AccordionStyled>
      <Box sx={{ display: "flex", marginY: "20px" }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={"COMPANY"}
            name="radio-buttons-group"
            onChange={(e: any) => {
              setCurrentId('');
              setYear('');
              setDashboardType(e.target.value);
            }}
          >
            <FormControlLabel
              value="COMPANY"
              control={<Radio />}
              label="Company"
            />
            <FormControlLabel
              value="VENDOR"
              control={<Radio />}
              label="Vendor"
            />
            <FormControlLabel
              value="CLIENT"
              control={<Radio />}
              label="Client"
            />
          </RadioGroup>
        </FormControl>
        {dashboardType !== "COMPANY" ? (
          <FormItemLabel
            label={`Choose ${dashboardType === "VENDOR" ? "Vendor" : "Client"}`}
            required
          >
            <TextField
              select
              size="small"
              value={currentId || ''}
              onChange={(e) => setCurrentId(e.target.value)}
              sx={{
                minWidth: "200px",
              }}
            >
              {renderDropdown(loading, dashboardType, dropdownData)}
            </TextField>
          </FormItemLabel>
        ) : (
          <FormItemLabel label={`Choose year`}>
            <TextField
              size="small"
              select
              value={year || ''}
              onChange={(e) => {
                return setYear(moment(e.target.value).format("YYYY"));
              }}
              sx={{ minWidth: "100px" }}
            >
              {datesData.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.year}>
                    {moment(item.year).format("YYYY")}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormItemLabel>
        )}
      </Box>

      <Grid container sx={{ display: "flex" }}>
        <GridContainerStyled container spacing={4}>
          <Grid item sx={cardSx} xs={12} lg={6}>
            <Box sx={cardSxItem}>
              <DashBoardChart
                type={dashboardType}
                currentId={currentId}
                isCompanyView={true}
                title={"Payments"}
                companyChartData={companyChartData?.paymentsInfo}
              />
            </Box>
          </Grid>
          {dashboardType === "COMPANY" && (
            <Grid item sx={cardSx} xs={12} lg={6}>
              <Box sx={cardSxItem}>
                <DashBoardChart
                  type={dashboardType}
                  currentId={currentId}
                  isCompanyView={true}
                  title={"Invoices"}
                  companyChartData={companyChartData?.invoicesInfo}
                />
              </Box>
            </Grid>
          )}
          <Grid item sx={cardSx} xs={12} lg={6}>
            <Box sx={cardSxItem}>
              <AssociateInfoDashboard
                type={dashboardType}
                currentId={currentId}
                isCompanyView={true}
              />
            </Box>
          </Grid>
        </GridContainerStyled>
      </Grid>
    </Box>
  );
};

export default DashboardCompany;
