import { Alert, Box, Snackbar } from "@mui/material";
import React from "react";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";

interface ICustomSnackbar {
  children: any;
}
const CustomSnackbar: React.FC<ICustomSnackbar> = ({ children }) => {
  const {
    openSnackbarSuccess,
    openSnackbarFail,
    setOpenSnackbarSuccess,
    setOpenSnackbarFail,
    
    snackbarContentSuccess,
    snackbarContentFail,
    setSnackbarContentSuccess,
    setSnackbarContentFail,

    snackbarWarning,
    setSnackbarWarning,

    snackbarContentArraySuccess,
    setSnackbarContentArraySuccess,
    snackbarContentArrayFail,
    setSnackbarContentArrayFail,
  } = useTimeSheetContext();

  const closeSnackbarSuccess = () => {
    setOpenSnackbarSuccess(false);
    setSnackbarContentSuccess("");
  };

  const closeSnackbarFail = () => {
    setOpenSnackbarFail(false);
    setSnackbarContentFail("");
    setSnackbarContentArrayFail([]);
  };

  const closeSnackbarWarning = () => {
    setSnackbarWarning({ status: false, content: "" });
  };

  return (
    <>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbarSuccess || !!snackbarContentSuccess}
        autoHideDuration={3000}
        onClose={closeSnackbarSuccess}
      >
        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {openSnackbarSuccess && (
            <Alert onClose={closeSnackbarSuccess} severity="success" sx={{ width: "100%" }}>
              SUCCESS
            </Alert>
          )}

          {!!snackbarContentSuccess && (
            <Alert onClose={closeSnackbarSuccess} severity="success" sx={{ width: "100%" }}>
              {snackbarContentSuccess}
            </Alert>
          )}
        </Box>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbarFail || !!snackbarContentFail || !!snackbarContentArrayFail.length}
        autoHideDuration={5000}
        onClose={closeSnackbarFail}
      >
        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {openSnackbarFail && (
            <Alert onClose={closeSnackbarFail} severity="error" sx={{ width: "100%" }}>
              FAIL
            </Alert>
          )}

          {!!snackbarContentFail && (
            <Alert onClose={closeSnackbarFail} severity="error" sx={{ width: "100%" }}>
              {snackbarContentFail}
            </Alert>
          )}

          {snackbarContentArrayFail?.length > 0 &&
            snackbarContentArrayFail?.map((option, key) => (
              <Alert
                key={`${option.value}-${key}`}
                onClose={closeSnackbarFail}
                severity="error"
                sx={{ width: "100%" }}
              >
                Please check field "{option?.label}" required in Form
              </Alert>
            ))}
        </Box>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarWarning.status}
        autoHideDuration={3000}
        onClose={closeSnackbarWarning}
      >
        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {snackbarWarning.status && (
            <Alert onClose={closeSnackbarWarning} severity="warning" sx={{ width: "100%" }}>
              {snackbarWarning.content}
            </Alert>
          )}
        </Box>
      </Snackbar>
    </>
  );
};

export default CustomSnackbar;
