import type { Delete, Get, Post, Put } from "common/api/types";
import { gateway } from "../../common/helpers/wretch";
import wretch from "wretch";
import { getCookie } from "../../common/helpers/cookies";
import { Auth } from "../../common/schema/auth.schema";

export interface ActionCreators {
  getToken: Post<Record<string, unknown>, Auth>;
  //company
  getClientByVendors: Get<Record<string, unknown>, Record<string, unknown>>;
  getCompanyInfo: Get<Record<string, unknown>, Record<string, unknown>>;
  updateCompanyInfo: Put<Record<string, unknown>, Record<string, unknown>>;
  deleteCompany: Delete<Record<string, unknown>, Record<string, unknown>>;
  //department
  createCompanyDepartment: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getCompanyDepartment: Get<Record<string, unknown>, Record<string, unknown>>;
  updateDepartment: Put<Record<string, unknown>, Record<string, unknown>>;
  deleteCompanyDepartment: Delete<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  //application
  createDeptApp: Post<Record<string, unknown>, Record<string, unknown>>;
  getAppForDept: Get<Record<string, unknown>, Record<string, unknown>>;
  getAppForCompany: Get<Record<string, unknown>, Record<string, unknown>>;
  getAppForClient: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveAppForCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateApp: Put<Record<string, unknown>, Record<string, unknown>>;
  archiveApp: Put<Record<string, unknown>, Record<string, unknown>>;
  deleteDeptApp: Delete<Record<string, unknown>, Record<string, unknown>>;
  //teamAssociate
  getMember: Get<Record<string, unknown>, Record<string, unknown>>;
  getMemberWithFilters: Get<Record<string, unknown>, Record<string, unknown>>;
  getMemberForManager: Get<Record<string, unknown>, Record<string, unknown>>;
  getMemberForReviewer: Get<Record<string, unknown>, Record<string, unknown>>;
  createTeamMember: Post<Record<string, unknown>, Record<string, unknown>>;
  createTeamMemberForVendorAndClient: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteMember: Delete<Record<string, unknown>, Record<string, unknown>>;
  getArchieveTeamMember: Get<Record<string, unknown>, Record<string, unknown>>;
  archieveMember: Put<Record<string, unknown>, Record<string, unknown>>;
  updateTeamAssociateWithCompany: Put<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateTeamAssociateWithoutEffectiveDate: Put<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getTeamAssociateProfileByEmail: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getTeamAssociateByEmailWithCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getMemberByIdWithCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getMembersForClientWithCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getMembersForClient: Get<Record<string, unknown>, Record<string, unknown>>;
  getMembersForVendorWithCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getExternalClientManagersForCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getExternalMembersForCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;

  //CATEGORIES
  getCategories: Get<Record<string, unknown>, Record<string, unknown>>;
  updateCategories: Put<Record<string, unknown>, Record<string, unknown>>;
  createCategories: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteCategories: Delete<Record<string, unknown>, Record<string, unknown>>;

  //ASSIGNMENT
  getAssignmentsForTeamAssociate: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getAssignmentDetail: Get<Record<string, unknown>, Record<string, unknown>>;
  getAssignmentsForCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getAssignmentsForClient: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getAssignmentsForVendor: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getArchieveAssignmentForCompany: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateAssignments: Put<Record<string, unknown>, Record<string, unknown>>;
  createAssignments: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteAssignments: Delete<Record<string, unknown>, Record<string, unknown>>;
  archiveAssignment: Put<Record<string, unknown>, Record<string, unknown>>;
  archiveAssignmentCompany: Put<Record<string, unknown>, Record<string, unknown>>;
  assignNewManager: Put<Record<string, unknown>, Record<string, unknown>>;
  updateReviewerAssignments: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  // Dashboard
  getDashboardDataForClientReviewer: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getAssociateDataForClientReviewer: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;

  getDashboardDataForClientManager: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getAssociateDataForClientManager: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getEmploymentTypeCounts: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;

  //auth
  resetTempPassword: Post<Record<string, unknown>, Record<string, unknown>>;
  checkEmailVerify: Post<Record<string, unknown>, Record<string, unknown>>;
}

const token = `${process.env.REACT_APP_AUTH_API_URL}`;
const baseUrl = `${process.env.REACT_APP_COMPANYMGMT_API_URL}`;
//auth

const resetTempPasswordUrl = baseUrl + "/reset-temp-password";
const checkEmailVerifyUrl = baseUrl + "/check-email-verify";

//company
const companyMgmtUrl = baseUrl + "/companyMgmt";
const deleteCompanyUrl = baseUrl + "/deepDeleteCompany";
const companyDeptMgmtUrl = baseUrl + "/companyDeptMgmt";
const employeeTypeCountUrl = baseUrl + "/employment-type";

//deptApp
const deptAppMgmtUrl = baseUrl + "/deptApplMgmt";
const getAllApplMgmtUrl = baseUrl + "/getAllApplMgmt";
const archiveApplsUrl = baseUrl + "/archiveApplicationsMgmt";

//teamAssocite
const memberMgmtUrl = baseUrl + "/teamAssociate";
const memberVendorClientMgmtUrl = baseUrl + "/team-associate-vendor-client";
const teamAssociateWithCompanyUrl = baseUrl + "/teamAssociateWithCompany";
const teamAssociateWithoutEffectiveDateUrl = baseUrl + "/teamAssociate-without-effective-date";
const getMembersMgmtUrl = baseUrl + "/allTeamAssociatesForCompany";
const getMembersWithFilterMgmtUrl =
  baseUrl + "/allTeamAssociatesForCompany/filters";
const deleteTeamAssoc = baseUrl + "/ta-delete";
const teamAssociateByEmailWithCompanyUrl =
  baseUrl + "/teamAssociateByEmailWithCompany";
const teamAssociateProfileByEmailUrl = baseUrl + "/getTeamAssociateByEmail";
const teamAssociatesForManager = baseUrl + "/teamAssociatesForManager";

const teamAssociatesForReviewer = baseUrl + "/teamAssociatesForReviewer";

//CATEGORIES
const categoriesUrl = baseUrl + "/applicationCategorys";
const categoryUrl = baseUrl + "/applicationCategory";

// DASHBOARD
const clientManagerDasboardDataUrl = baseUrl + "/get-timesheet-dashboard";
const clientManagerAssociateDataUrl = baseUrl + "/get-associate-dashboard";
const clientReviewerDasboardDataUrl = baseUrl + "/get-timesheet-dashboard";
const clientReviewerAssociateDataUrl = baseUrl + "/get-associate-dashboard";

//assignment
const assignmentUrl = baseUrl + "/teamAssociateAssignments";
const getAssignmentManagerUrl = baseUrl + "/assignment-details";
const archieveUrl = baseUrl + "/archieveTeamMember";
const archieveAssignmentUrl = baseUrl + "/archieveAssignmentTeamMember";
const archieveAssignmentCompanyUrl = baseUrl + "/archive-assignment";
const assignmentForCompany = baseUrl + "/assignment-company";
const assignmentReviewerUrl = baseUrl + "/assignment-company-reviewer";
const assignmentForClientUrl =
  baseUrl + "/getTeamAssociatesAssignmentForClient";
const assignmentForVendorUrl =
  baseUrl + "/getTeamAssociatesAssignmentForVendor";
const deleteAssignmentForCompany = baseUrl + "/assignment-delete";

const getArchieveTeamMemberUrl = baseUrl + "/getArchieveTeamMember";
const getArchieveAssignmentForCompanyUrl =
  baseUrl + "/getArchieveAssignmentForCompany";
const assignmentNewManagerUrl = baseUrl + "/assignmentNewManager";
const getMemberByIdWithCompanyUrl =
  baseUrl + "/getTeamAssociateByIdWithCompany";
const getMembersForClientWithCompanyUrl =
  baseUrl + "/getTeamAssociateForClient";
const getMembersWithAssignmentForClientCompanyUrl =
  baseUrl + "/getTeamAssociatesAssignmentForClient";
const getMembersForVendorWithCompanyUrl =
  baseUrl + "/getTeamAssociateForVendor";
const externalClientMgrUrl = baseUrl + "/external-managers";
const externalMembersMgrUrl = baseUrl + "/external-members";

const clientByVendorUrl = process.env.REACT_APP_COMPANYMGMT_API_URL + "/getClientAssignmentByVendor"

export const getActionCreators = (gateway: any): ActionCreators => ({
  //auth
  getToken: (body) =>
    gateway
      .query({
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_COMPANY_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_COMPANY_AUTH_CLIENT_SECRET,
      })
      .url(token)
      .post(body)
      .json(),
  resetTempPassword: (body) =>
    gateway.url(resetTempPasswordUrl).post(body).json(),
  checkEmailVerify: (body) =>
    gateway.url(checkEmailVerifyUrl).post(body).json(),
  getClientByVendors: (queryParams = {}) =>
    gateway.url(`${clientByVendorUrl}/${queryParams.companyId}/${queryParams.vendorId}`).get().json(),
  //company
  getCompanyInfo: (queryParams = {}) =>
    gateway
      .url(`${companyMgmtUrl}/${queryParams.snrId}/${queryParams.companyId}`)
      .get()
      .json()
      .catch(console.error),
  updateCompanyInfo: (body) => gateway.url(companyMgmtUrl).put(body).json(),
  deleteCompany: (companyId, snrId, teamMemberInfo = {}) =>
    gateway
      .url(
        `${deleteCompanyUrl}/${companyId}/${snrId}/${teamMemberInfo.teamMemberId}/${teamMemberInfo.email}`
      )
      .delete()
      .json(),
  //department
  createCompanyDepartment: (body) =>
    gateway.url(companyDeptMgmtUrl).post(body).json(),
  getCompanyDepartment: (queryParams = {}) =>
    gateway.url(`${companyDeptMgmtUrl}/${queryParams.companyId}`).get().json(),
  updateDepartment: (body) => gateway.url(companyDeptMgmtUrl).put(body).json(),
  deleteCompanyDepartment: (companyId, companyDeptId) =>
    gateway
      .url(`${companyDeptMgmtUrl}-delete/${companyId}/${companyDeptId}`)
      .delete()
      .json(),
  //application
  createDeptApp: (body) => gateway.url(deptAppMgmtUrl).post(body).json(),
  getAppForDept: (queryParams = {}) =>
    gateway.url(`${deptAppMgmtUrl}/${queryParams.companyDeptId}`).get().json(),
  getAppForCompany: (queryParams = {}) =>
    gateway.url(`${getAllApplMgmtUrl}/${queryParams.companyId}`).get().json(),
  getAppForClient: (queryParams = {}) =>
    gateway
      .url(
        `${getAllApplMgmtUrl}-client/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
  getArchiveAppForCompany: (queryParams = {}) =>
    gateway.url(`${archiveApplsUrl}/${queryParams.companyId}`).get().json(),
  updateApp: (body) => gateway.url(deptAppMgmtUrl).put(body).json(),
  archiveApp: (body) => gateway.url(archiveApplsUrl).put(body).json(),
  deleteDeptApp: (companyId, applicationId) =>
    gateway
      .url(`${deptAppMgmtUrl}-delete/${companyId}/${applicationId}`)
      .delete()
      .json(),
  //member
  getMemberWithFilters: (body) =>
    gateway.url(getMembersWithFilterMgmtUrl).post(body).json(),
  getMember: (queryParams = {}) =>
    gateway.url(`${getMembersMgmtUrl}/${queryParams.companyId}`).get().json(),
  getMemberForManager:(queryParams = {}) => gateway .url(`${teamAssociatesForManager}/${queryParams.companyId}/${queryParams.managerId}`).get().json(),
  getMemberForReviewer:(queryParams = {}) => gateway .url( `${teamAssociatesForReviewer}/${queryParams.companyId}/${queryParams.reviewerId}`).get().json(),
  createTeamMember: (body) => gateway.url(memberMgmtUrl).post(body).json(),
  createTeamMemberForVendorAndClient: (body) => gateway.url(memberVendorClientMgmtUrl).post(body).json(),
  deleteMember: (companyId, teamMemberId) =>
    gateway
      .url(`${deleteTeamAssoc}/${companyId}/${teamMemberId}`)
      .delete()
      .json(),
  archieveMember: (body) => gateway.url(archieveUrl).put(body).json(),
  getArchieveTeamMember: (queryParams = {}) =>
    gateway
      .url(`${getArchieveTeamMemberUrl}/${queryParams.companyId}`)
      .get()
      .json(),
  updateTeamAssociateWithCompany: (body) =>
    gateway.url(teamAssociateWithCompanyUrl).put(body).json(),
  updateTeamAssociateWithoutEffectiveDate: (body) =>
    gateway.url(teamAssociateWithoutEffectiveDateUrl).put(body).json(),
  getTeamAssociateProfileByEmail: (queryParams = {}) =>
    gateway
      .url(`${teamAssociateProfileByEmailUrl}/${queryParams.email}`)
      .get()
      .json(),
  getTeamAssociateByEmailWithCompany: (queryParams = {}) =>
    gateway
      .url(
        `${teamAssociateByEmailWithCompanyUrl}/${queryParams.companyId}/${queryParams.email}`
      )
      .get()
      .json(),
  getMemberByIdWithCompany: (queryParams = {}) =>
    gateway
      .url(
        `${getMemberByIdWithCompanyUrl}/${queryParams.companyId}/${queryParams.teamAssociateId}`
      )
      .get()
      .json(),
  getMembersForClientWithCompany: (queryParams = {}) =>
    gateway
      .url(
        `${getMembersForClientWithCompanyUrl}/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
  getMembersForClient: (queryParams = {}) =>
    gateway
      .url(
        `${getMembersWithAssignmentForClientCompanyUrl}/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
  getMembersForVendorWithCompany: (queryParams = {}) =>
    gateway
      .url(
        `${getMembersForVendorWithCompanyUrl}/${queryParams.companyId}/${queryParams.vendorId}`
      )
      .get()
      .json(),
  getExternalClientManagersForCompany: (queryParams = {}) =>
    gateway
      .url(`${externalClientMgrUrl}/${queryParams.companyId}`)
      .get()
      .json(),
  getExternalMembersForCompany: (queryParams = {}) =>
    gateway
      .url(`${externalMembersMgrUrl}/${queryParams.companyId}`)
      .get()
      .json(),

  //CATEGORIES
  getCategories: (queryParams = {}) =>
    gateway.url(`${categoriesUrl}/${queryParams.companyId}`).get().json(),
  createCategories: (body) => gateway.url(categoryUrl).post(body).json(),
  updateCategories: (body) => gateway.url(categoryUrl).put(body).json(),
  deleteCategories: (companyId, applicationCategoryId) =>
    gateway
      .url(
        `${categoryUrl}-delete/${companyId}/${applicationCategoryId}`
      )
      .delete()
      .json(),

  //ASSIGNMENT
  getAssignmentsForTeamAssociate: (queryParams = {}) =>
    gateway
      .url(
        `${assignmentUrl}/${queryParams.companyId}/${queryParams.teamAssociateId}`
      )
      .get()
      .json(),
  getAssignmentDetail: (queryParams = {}) =>
    gateway
      .url(
        `${getAssignmentManagerUrl}/${queryParams.companyId}/${queryParams.teamAssociateAssignmentId}`
      )
      .get()
      .json(),
  getAssignmentsForCompany: (queryParams = {}) =>
    gateway
      .url(`${assignmentForCompany}/${queryParams.companyId}`)
      .get()
      .json(),
  getAssignmentsForClient: (queryParams = {}) =>
    gateway
      .url(
        `${assignmentForClientUrl}/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
  getAssignmentsForVendor: (queryParams = {}) =>
    gateway
      .url(
        `${assignmentForVendorUrl}/${queryParams.companyId}/${queryParams.vendorId}`
      )
      .get()
      .json(),
  getArchieveAssignmentForCompany: (queryParams = {}) =>
    gateway
      .url(`${getArchieveAssignmentForCompanyUrl}/${queryParams.companyId}`)
      .get()
      .json(),
  updateAssignments: (body) =>
    gateway.url(assignmentForCompany).put(body).json(),
  updateReviewerAssignments: (body) =>
    gateway.url(assignmentReviewerUrl).post(body).json(),
  createAssignments: (body) => gateway.url(assignmentUrl).post(body).json(),
  deleteAssignments: (companyId, teamAssociateAssignmentId) =>
    gateway
      .url(
        `${deleteAssignmentForCompany}/${companyId}/${teamAssociateAssignmentId}`
      )
      .delete()
      .json(),
  archiveAssignment: (body) =>
    gateway.url(archieveAssignmentUrl).put(body).json(),
    archiveAssignmentCompany: (body) =>
    gateway.url(archieveAssignmentCompanyUrl).post(body).json(),
  assignNewManager: (body) => gateway.url(assignmentNewManagerUrl).put(body).json(),
  // getDashboardDataForClientReviewer: (body) =>
  //     gateway.url(clientReviewerDasboardDataUrl).put(body).json(),
  getEmploymentTypeCounts: (queryParams = {}) =>
    gateway
      .url(`${employeeTypeCountUrl}/${queryParams.companyId}`)
      .get()
      .json()
      .catch(console.error),
  getDashboardDataForClientReviewer: (queryParams = {}) =>
    gateway
      .url(
        `${clientReviewerDasboardDataUrl}/${queryParams.companyId}/reviewer/${queryParams.clientReviewerId}`
      )
      .get()
      .json()
      .catch(console.error),
  getAssociateDataForClientReviewer: (queryParams = {}) =>
    gateway
      .url(
        `${clientReviewerAssociateDataUrl}/${queryParams.companyId}/reviewer/${queryParams.clientReviewerId}`
      )
      .get()
      .json()
      .catch(console.error),
  getDashboardDataForClientManager: (queryParams = {}) =>
    gateway
      .url(
        `${clientManagerDasboardDataUrl}/${queryParams.companyId}/manager/${queryParams.managerId}`
      )
      .get()
      .json()
      .catch(console.error),
  getAssociateDataForClientManager: (queryParams = {}) =>
    gateway
      .url(
        `${clientManagerAssociateDataUrl}/${queryParams.companyId}/manager/${queryParams.managerId}`
      )
      .get()
      .json()
      .catch(console.error),
});

export const applActionCreators = (): {
  actionCreators: ActionCreators;
} => {
  // const authHeaders: { [key: string]: string } = {
  //   "Content-Type": "application/x-www-form-urlencoded",
  // };

  // const defaultHeaders: { [key: string]: string } = {
  //   // 'Session-Id': getCookie('sessionId'),
  //   "X-Api-Key": process.env.REACT_APP_COMPANY_API_KEY as any,
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${getCookie("accessToken")}` || ``,
  // };

  // const headers = !getCookie("accessToken") ? authHeaders : defaultHeaders;
  // const w = wretch();
  // const gateway = w.headers(headers);
  const actionCreators = getActionCreators(
    gateway({
      token: getCookie("accessToken"),
      xApiKey: process.env.REACT_APP_COMPANY_API_KEY as any,
      contentType: !getCookie("accessToken")
        ? "application/x-www-form-urlencoded"
        : "application/json",
    })
  );

  return { actionCreators };
};
