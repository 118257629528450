import { makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { LoadingButton } from "@mui/lab";
import { Box, CssBaseline, IconButton, Drawer as MuiDrawer, Toolbar } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";
import { Auth } from "aws-amplify";
import makeRequest from "common/api/makeRequest";
import BreadcrumbsBase from "common/components/BreadcrumbsBase";
import Header from "common/components/Header";
import LeftSidebar, { SIDE_BAR_TABS } from "common/components/LeftSidebar";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuthActions from "timesheets/api/actions/authCall";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { convertArrayFromTestData } from "timesheets/utils";
import { COMPANY_TYPE, listCookie, screenCode } from "timesheets/utils/constant";
import { getCookie, removeCookie } from "../../common/helpers/cookies";
import { ROUTES } from "../../components/Shared/pages";
import { applActionCreators } from "../api/companyActions";

type IPageLayout = {
	children: React.ReactNode;
	isCheckAuth: boolean;
};

const useStyles = makeStyles((theme) => {
	return {
		mainPageLayOut: {
			flex: 1,
			width: "calc(100% - 300px)",
			marginBottom: "5rem",
		},
		wrapperPageLayOut: {
			width: "100%",
			minHeight: "calc(100vh - 88px)",
		},
		pageWrapperLayout: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
		},
		pageBox: {
			padding: "1.5rem",
			[theme.breakpoints.down("md")]: {
				padding: "1rem",
			},
			[theme.breakpoints.down("xs")]: {
				padding: "0.5rem",
			},
		},
		headerLayout: {
			marginLeft: "auto",
		},
		emailHeader: {
			position: "fixed",
			top: 60,
			backgroundColor: "#c5e0e5",
			boxShadow: "1px 2px 25px #6f736f",
			display: "flex",
			gap: 2,
			padding: "0.5rem 2rem",
			justifyContent: "space-between",
			alignItems: "center",
			fontWeight: "bold",
			zIndex: 2,
			[theme.breakpoints.down("md")]: {
				marginLeft: "65px",
				width: "calc(100% - 65px)",
			},
		},
	};
});

const PageLayout: React.FC<IPageLayout> = ({ children, isCheckAuth }) => {
	const [drawerWidth, setDrawerWidth] = useState(300);
	const [verLoading, setVerLoading] = useState(false);
	const history = useHistory();
	const context = useTimeSheetContext();
	const {
		emailVerified,
		setEmailVerified,
		setIsAuth,
		setAuthInfo,
		emailChecked,
		setEmailChecked,
		screenRoleMappingForTeamMember,
		companyType,
	} = context;

	useEffect(() => {
		if (window) {
			if (window.innerWidth < 1556) {
				setDrawerWidth(200);
			}
		}
		window.addEventListener("resize", () => {
			if (window.innerWidth < 1556) {
				setDrawerWidth(200);
			} else {
				setDrawerWidth(300);
			}
		});
	}, []);

	const openedMixin = (theme: Theme): CSSObject => ({
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: "hidden",
	});

	const closedMixin = (theme: Theme): CSSObject => ({
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up("sm")]: {
			width: `calc(${theme.spacing(8)} + 1px)`,
		},
	});

	const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	}));

	interface AppBarProps extends MuiAppBarProps {
		open?: boolean;
	}

	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== "open",
	})<AppBarProps>(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	const Drawer = styled(MuiDrawer, {
		shouldForwardProp: (prop) => prop !== "open",
	})(({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
		boxSizing: "border-box",
		...(open && {
			...openedMixin(theme),
			"& .MuiDrawer-paper": openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			"& .MuiDrawer-paper": closedMixin(theme),
		}),
	}));
	const classes = useStyles();
	const { isAuth, isMobile } = useTimeSheetContext();
	const { checkAuth } = useAuthActions();
	const {
		actionCreators: { checkEmailVerify },
	} = applActionCreators();

	const theme = useTheme();
	const [open, setOpen] = React.useState(true);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		checkAuth(isCheckAuth);
	}, [isAuth, isCheckAuth]);

	useEffect(() => {
		setOpen(!isMobile);
	}, [isMobile]);
	const email = getCookie("email");

	const handleCheckEmailVerify = useCallback((email, cb?) => {
		makeRequest(
			() => {
				return checkEmailVerify({ email });
			},
			(data) => {
				if (data?.Enabled === false) {
					handleLogout();
				}

				if (data.email_verified === "true") {
					setEmailVerified(true);
				} else {
					setEmailVerified(false);
				}
				setEmailChecked(true);
			},
			() => {
				cb && cb();
			}
		);
	}, []);

	// signout || logout
	const handleLogout = async (setLoading?, callback?) => {
		// handleClose();
		setLoading && setLoading(true);
		await Auth.signOut();
		setLoading && setLoading(false);
		setIsAuth(false);
		setAuthInfo({});
		context.setCompanyInformation({});
		context.setTeamAssociate({});
		// remove all cookie
		listCookie.forEach((value: string) => removeCookie(value));
		callback && callback();
	};

	useEffect(() => {
		if (email && !emailChecked) {
			handleCheckEmailVerify(email, () => handleCheckEmailVerify(email));
		}
	}, [email, emailChecked]);

	const handleVerifyNow = () => {
		setVerLoading(true);

		Auth.verifyCurrentUserAttribute("email")
			.then(() => {
				setVerLoading(false);
				history.push(`/${ROUTES.verifyEmail}`);
			})
			.catch((err) => {
				setVerLoading(false);
			});
	};
	const { END_CLIENT } = COMPANY_TYPE;

	const handleRenderTabs = useCallback(() => {
		const tabDetails =
			screenRoleMappingForTeamMember[screenRoleMappingForTeamMember.length - 1]?.tabDetails;
		if (!tabDetails) return;

		const _tabDetails =
			typeof tabDetails === "string"
				? JSON.parse(convertArrayFromTestData(tabDetails))
				: tabDetails;

		let resArray = [
			...SIDE_BAR_TABS.filter((item) =>
				_tabDetails.some((detail) => detail.name === item.tabDetails)
			),
		];
		if (companyType === END_CLIENT) {
			resArray = resArray.filter((item) => item.tabDetails !== screenCode.clientCode);
		}
		return resArray;
	}, [screenRoleMappingForTeamMember]);

	// prevent memory leak

	const [didMount, setDidMount] = useState(false);
	useEffect(() => {
		setDidMount(true);
		return () => setDidMount(false);
	}, []);

	if (!didMount) {
		return null;
	}

	return (
		isAuth && (
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBar position="fixed" open={open}>
					<Toolbar>
						{isMobile && (
							<>
								<img
									src={TSLogo}
									alt="logo"
									style={{ width: 60, height: 60, marginRight: "1rem" }}
								/>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={handleDrawerOpen}
									edge="start"
									sx={{
										marginRight: 5,
										...(open && { display: "none" }),
									}}
								>
									<MenuIcon />
								</IconButton>
							</>
						)}
						<Box className={classes.headerLayout}>
							<Header handleLogout={handleLogout} />
						</Box>
					</Toolbar>
				</AppBar>
				{!emailVerified && (
					<Box
						className={classes.emailHeader}
						sx={{ marginLeft: `${drawerWidth}px`, width: `calc(100% - ${drawerWidth}px)` }}
					>
						Your email is not verified.
						<LoadingButton
							loading={verLoading}
							onClick={() => {
								handleVerifyNow();
							}}
							variant="contained"
							color="info"
						>
							Verify now
						</LoadingButton>
					</Box>
				)}
				<Drawer variant="permanent" open={open}>
					<DrawerHeader>
						{isMobile ? (
							<IconButton onClick={handleDrawerClose}>
								{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
							</IconButton>
						) : (
							<Box sx={{ margin: "0 auto" }}>
								<img src={TSLogo} alt="logo" style={{ width: 60, height: 60 }} />
							</Box>
						)}
					</DrawerHeader>

					<LeftSidebar open={open} leftData={handleRenderTabs() || []} />
				</Drawer>
				<Box component="main" className={classes.mainPageLayOut}>
					<DrawerHeader />
					<Box component="section" className={classes.wrapperPageLayOut}>
						<Box className={classes.pageWrapperLayout}>
							<BreadcrumbsBase />
							<Box className={classes.pageBox}>{children}</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	);
};

export default PageLayout;
