import makeRequest from "common/api/makeRequest";
import { getCookie, setCookie } from "common/helpers/cookies";
import { useCallback, useEffect, useState } from "react";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { checkAuthRole } from "timesheets/utils";
import {
	AUTH_ROLE_CODE,
	COMPANY_TYPE,
	NO_RECORDS_FOUND,
	SNR_APPS,
} from "timesheets/utils/constant";
import { applActionCreators } from "../companyActions";
import { roleMgmtActionCreators } from "../roleMgmtAction";
import { archiveActionCreators } from "../archiveActions";
import { allArchiveActionCreators } from "../allArchiveActions";

const useCompanyActions = () => {
	const {
		setIsGlobalLoading,
		setCompanyDepartmentInformation,
		setOpenSnackbarFail,
		setClientManagers,
		setExternalMembers,
		setMembers,
		setExternalManagers,
		setAssignments,
		setAssignmentsForClient,
		setMembersForClient,
		setMembersForVendor,
		setCompanyInformation,
		setTrackingCheckbox,
		setCompanyType,
		setTimeSheetDetailsFormat,
		setScreenRoleMappingForTeamMember,
		setTeamAssociate,
		setOpenSnackbarSuccess,
		setAppInfo,
	} = useTimeSheetContext();

	const { END_CLIENT, INTEGRATION_PARTNER } = COMPANY_TYPE;
	const { REPORTING_MGR } = AUTH_ROLE_CODE;

	const companyId = getCookie("companyId");
	const email = getCookie("email");
	const snrId = SNR_APPS;
	
	const {
		actionCreators: {
			getMember,
			getClientByVendors,
			getMemberWithFilters,
			getExternalClientManagersForCompany,
			getCompanyInfo,
			getTeamAssociateByEmailWithCompany,
			assignNewManager,
			updateTeamAssociateWithCompany,
			updateTeamAssociateWithoutEffectiveDate,
			getExternalMembersForCompany,
			getCompanyDepartment,
			getMembersForClientWithCompany,
			getMembersForClient,
			getMembersForVendorWithCompany,
			getMemberForManager,
			getMemberForReviewer,
			createTeamMember,
			deleteMember,

			getAppForClient,
			getAppForCompany,

			// Department
			createCompanyDepartment,
			updateDepartment,
			deleteCompanyDepartment,

			// Categories
			getCategories,
			createCategories,
			deleteCategories,
			updateCategories,
			

			//Assignments
			getAssignmentsForCompany,
			getAssignmentsForVendor,
			getAssignmentsForClient,
			getAssignmentsForTeamAssociate,
			updateAssignments,

			// Application
			createDeptApp,
			updateApp,
			deleteDeptApp,

			createTeamMemberForVendorAndClient
		},
	} = applActionCreators();

	const {actionCreators:{ archiveDeptApp, archiveCategories }} = archiveActionCreators()

	const {
		actionCreators: { getScreenRoleMappingForTeamMember },
	} = roleMgmtActionCreators();
	
	const fetchClientByVendors = useCallback(
		(setLoading, vendorId, callback?: (arg0: any) => any, companyId?) => {
			setLoading && setLoading(true);
			if (companyId != "" && vendorId != "") {
				makeRequest(
					() => {
						return getClientByVendors({ companyId, vendorId });
					},
					(data: any) => {
							callback && callback(data)
						// data.length ? setClients(data.localData) : setClients([]);
						setLoading && setLoading(false);
					},
					() => {
						setLoading && setLoading(false);
						setOpenSnackbarFail(true);
					}
				);
			}
		},
		[getClientByVendors]
	);

	const {
		actionCreators: {
			getArchiveTaskCategory, 
			getArchiveApplication,
			deleteTeamAssociateForClient,
			deleteTeamAssociateForVendor,
		} 
	} = allArchiveActionCreators();

	const fetchCompanyDepartment = useCallback(
		(setLoading, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return getCompanyDepartment({ companyId });
				},
				(data: any) => {
					setLoading(false);
					data.length ? setCompanyDepartmentInformation(data) : setCompanyDepartmentInformation([]);

					cb && cb();
				},
				(err: any) => {
					if (err) {
						setLoading(false);
						setOpenSnackbarFail(true);
					}
				}
			);
		},
		[getCompanyDepartment]
	);

	const handleFetchApplications = (
		setIsLoading: any,
		clientId: string,
		setData: (params: any) => void,
		callback?: (arg0: any) => any
	) => {
		if (clientId) {
			handleFetchApplForClient(setIsLoading, clientId, setData, (data: any) => {
				callback && callback(data);
			});
		} else {
			handleFetchApplForComp(setIsLoading, setData, (data: any) => {
				callback && callback(data);
			});
		}
	};

	const handleFetchArchivedApplications = useCallback((
		setIsLoading
	) => {
		setIsLoading(true);
		makeRequest(
		  () => {
			return getArchiveApplication({companyId});
		  },
		  (data: any) => {
			setIsLoading(false);
			data.length ? setAppInfo(data) : setAppInfo([]);
		  },
		  () => {
			setIsLoading(false);
			setOpenSnackbarFail(true);
		  }
		);
	}, [getArchiveApplication]);

	const handleFetchApplForClient = useCallback(
		(setLoading, clientId, setData: (params: any) => void, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAppForClient({ companyId, clientId });
				},
				(data: any) => {
					setLoading(false);
					data?.length > 0 ? setData(data) : setData([]);
					cb && cb(data);
				},
				(err: any) => {
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},

		[getAppForClient]
	);

	const handleFetchApplForComp = useCallback(
		(setLoading, setData, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAppForCompany({ companyId });
				},
				(data: any) => {
					setLoading(false);
					const _data = data.length ? data : [];
					const fl = _data.filter((item) => Boolean(item.clientId) === false);
					setData(fl);
					cb && cb(data);
				},
				(err: any) => {
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[getAppForCompany]
	);

	const fetchClientsManagerForCompany = useCallback(
		(setLoading: any, clientId: string, isSelfCompany: boolean) => {
			setLoading(true);
			makeRequest(
				() => {
					return getMember({ companyId });
				},
				(data: any) => {
					let result: any;
					if (isSelfCompany) {
						result = data?.length
							? data?.filter(
									(item) =>
										item?.authRoleCode === REPORTING_MGR ||
										checkAuthRole(REPORTING_MGR, item?.authRoleList)
							  )
							: [];
					} else {
						result = data?.length
							? data?.filter((item) => item?.clientId === clientId && !!item?.isManager)
							: [];
					}

					if (data?.status === NO_RECORDS_FOUND) {
						setClientManagers([]);
					} else {
						setClientManagers(result);
					}
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		},
		[getExternalClientManagersForCompany]
	);

	const fetchExternalMemberForCompany = useCallback(
		(setLoading: any, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return getExternalMembersForCompany({
						companyId,
					});
				},
				(data: any) => {
					if (data?.status === NO_RECORDS_FOUND) {
						setExternalMembers([]);
					} else {
						setExternalMembers(data);
					}
					cb && cb();
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		},
		[getExternalMembersForCompany]
	);

	const fetchTeamMember = useCallback(
		(
			setLoading: (param: boolean) => void,
			payload?: { companyId: string },
			cbR?: (param: any) => void,
			cbE?: (param: any) => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return getMember({ companyId: payload?.companyId || companyId });
				},
				(data: any) => {
					if (data.status === NO_RECORDS_FOUND) {
						setMembers([]);
					} else {
						setMembers(data);
					}
					cbR && cbR(data);
					setLoading(false);
				},
				(error) => {
					cbE && cbE(error);
					setLoading(false);
				}
			);
		},
		[getMember]
	);

	const fetchTeamMemberWithFilter = useCallback(
		(
			setLoading: (param: boolean) => void,
			payload: any,
			cbR?: (param?: any) => void,
			cbE?: (param?: any) => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return getMemberWithFilters({ companyId, ...payload });
				},
				(data: any) => {
					if (data.status === NO_RECORDS_FOUND) {
						setMembers([]);
					} else {
						setMembers(data);
					}
					cbR && cbR(data);
					setLoading(false);
				},
				(error) => {
					cbE && cbE(error);
					setLoading(false);
				}
			);
		},
		[getMemberWithFilters]
	);

	const fetchExternalManagers = useCallback(
		(setLoading) => {
			setLoading(true);
			makeRequest(
				() => {
					return getExternalClientManagersForCompany({ companyId });
				},
				(data: any) => {
					if (data.status === NO_RECORDS_FOUND) {
						setExternalManagers([]);
					} else {
						setExternalManagers(data);
					}
					setLoading(false);
				},
				() => setLoading(false)
			);
		},
		[getExternalClientManagersForCompany]
	);

	const fetchAssignments = useCallback((setLoading) => {
		setLoading(true);
		makeRequest(
			() => {
				return getAssignmentsForCompany({ snrId, companyId });
			},
			(data: any) => {
				data.length ? setAssignments(data) : setAssignments([]);
				setLoading(false);
			},
			(err: any) => {
				setOpenSnackbarFail(true);
				setLoading(false);
			}
		);
	}, []);

	const fetchAssignmentsForClient = useCallback((setLoading, payload, cbR?, cbE?) => {
		setLoading(true);
		makeRequest(
			() => {
				return getAssignmentsForClient({
					companyId: payload?.companyId || companyId,
					clientId: payload?.clientId,
				});
			},
			(data: any) => {
				data.length ? setAssignmentsForClient(data) : setAssignmentsForClient([]);
				setLoading(false);
				cbR && cbR(data);
			},
			(err: any) => {
				cbE && cbE(err);
				setOpenSnackbarFail(true);
				setLoading(false);
			}
		);
	}, []);

	const fetchAssignmentForVendorLocal = useCallback(
		(setLoading, setData, payload) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAssignmentsForVendor({ companyId: payload.companyId || companyId, vendorId: payload.vendorId });
				},
				(data: any) => {
					if (data.length) {
						setData(data);
					} else {
						setData([]);
					}
					setLoading(false);
				},
				() => {
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[getAssignmentsForVendor]
	);

	const fetchMembersForClientWithCompany = useCallback((setLoading, clientId) => {
		setLoading(true);
		makeRequest(
			() => {
				return getMembersForClientWithCompany({ companyId, clientId });
			},
			(data: any) => {
				data.length ? setMembersForClient(data) : setMembersForClient([]);
				setLoading(false);
			},
			(err: any) => {
				setOpenSnackbarFail(true);
				setLoading(false);
			}
		);
	}, []);

	const fetchMembersForClient = useCallback((setLoading, clientId) => {
		setLoading(true);
		makeRequest(
			() => {
				return getMembersForClient({ companyId, clientId });
			},
			(data: any) => {
				data.length ? setMembersForClient(data) : setMembersForClient([]);
				setLoading(false);
			},
			(err: any) => {
				setOpenSnackbarFail(true);
				setLoading(false);
			}
		);
	}, []);

	const fetchMembersForVendorWithCompany = useCallback((setLoading, vendorId) => {
		setLoading(true);
		makeRequest(
			() => {
				return getMembersForVendorWithCompany({ companyId, vendorId });
			},
			(data: any) => {
				data.length ? setMembersForVendor(data) : setMembersForVendor([]);
				setLoading(false);
			},
			(err: any) => {
				setOpenSnackbarFail(true);
				setLoading(false);
			}
		);
	}, []);

	const fetchCompanyInfoInit = useCallback(() => {
		makeRequest(
			() => {
				return getCompanyInfo({
					// companyId: authInfo?.teamAssociateInfo?.companyId,
					// snrId: authInfo?.teamAssociateInfo?.snrId,
					companyId,
					snrId,
				});
			},
			(data: any) => {
				if (getCookie("authRoleCode") !== "COMPANY_ADMIN") {
					setCookie("timeSheetFrequency", data?.timeSheetInfo?.timeSheetFrequency || "WEEKLY");
					setCookie("companyStatus", data?.companyStatus || "NEW");
					setCookie(
						"timeSheetDetailsFormat",
						(data?.timeSheetInfo ?? [])[0]?.timeSheetDetailsFormat || "1"
					);
				}

				setCompanyInformation(data);
				if (data.companyStatus !== "NEW") {
					setTrackingCheckbox(data.enableFeatures[0].isFinalClient);
					setCompanyType(data.enableFeatures[0].isFinalClient ? END_CLIENT : INTEGRATION_PARTNER);
				}
				if (data.timeSheetDetailsFormat === "NEW") {
					setTimeSheetDetailsFormat("NEW");
				} else {
					setTimeSheetDetailsFormat("DEFAULT");
				}
			}
		);
	}, [getCompanyInfo]);

	const fetchScreenMappingForTeamMemberInit = useCallback(
		(setLoading?, cb?) => {
			setLoading && setLoading(true);
			setIsGlobalLoading(true);
			makeRequest(
				() => {
					return getScreenRoleMappingForTeamMember({ companyId, email });
				},
				(data: any) => {
					setLoading && setLoading(false);
					setIsGlobalLoading(false);
					if (data.length) {
						setScreenRoleMappingForTeamMember(data);
						cb && cb();
					} else {
						setScreenRoleMappingForTeamMember([]);
					}
				},
				() => {
					setLoading && setLoading(false);
					setIsGlobalLoading(false);
					setScreenRoleMappingForTeamMember([]);
				}
			);
		},
		[getScreenRoleMappingForTeamMember]
	);

	const fetchTeamAssociateWithCompanyInit = useCallback(
		(_companyId?, _email?) => {
			makeRequest(
				() => {
					return getTeamAssociateByEmailWithCompany({
						companyId: _companyId || companyId,
						email: _email || email,
					});
				},
				(data: any) => {
					setTeamAssociate(data[0]);
					setCookie("authRoleCode", data[0].authRoleCode);
					setCookie("teamAssociateId", data[0].teamAssociateId);
				},
				() => {
					setTeamAssociate({});
				}
			);
		},
		[getTeamAssociateByEmailWithCompany]
	);

	const handleUpdateTeamAssociateWithCompany = useCallback(
		(setLoading, payload, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return updateTeamAssociateWithCompany(payload);
				},
				(data: any) => {
					setLoading(false);
					setOpenSnackbarSuccess(true);
					cb && cb();
				},
				() => {
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[updateTeamAssociateWithCompany]
	);

	const handleUpdateTeamAssociateWithoutEffectiveDate = useCallback(
		(setLoading, payload, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return updateTeamAssociateWithoutEffectiveDate(payload);
				},
				(data: any) => {
					setLoading(false);
					setOpenSnackbarSuccess(true);
					cb && cb(data);
				},
				() => {
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[updateTeamAssociateWithoutEffectiveDate]
	);

	const handleUpdateAssignment = useCallback(
		(setLoading, payload, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return updateAssignments(payload);
				},
				() => {
					setLoading(false);
					setOpenSnackbarSuccess(true);
					cb && cb();
				},
				() => {
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[updateAssignments]
	);

	const handleReAssignManager = useCallback(
		(setLoading, managerId, changingManagerId, cb?) => {
			setLoading(true);
			makeRequest(
				() => {
					return assignNewManager({ companyId, managerId, changingManagerId });
				},
				(data: any) => {
					setOpenSnackbarSuccess(true);
					setLoading(false);
					cb && cb();
				},
				() => {
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[assignNewManager]
	);

	const handleAddTeamAssociateWithCompany = useCallback((setLoading, payload, cb?, cb_err?) => {
		setLoading(true);
		makeRequest(
			() => {
				return createTeamMember(payload);
			},
			(data: any) => {
				setLoading(false);
				setOpenSnackbarSuccess(true);
				cb && cb(data);
			},
			() => {
				setLoading(false);
				setOpenSnackbarFail(true);
				cb_err && cb_err();
			}
		);
	}, []);

	const handleAddTeamAssociateForVendor = useCallback((setLoading, payload, cb?, cb_err?) => {
		setLoading(true);
		makeRequest(
			() => {
				return createTeamMemberForVendorAndClient(payload);
			},
			(data: any) => {
				setLoading(false);
				setOpenSnackbarSuccess(true);
				cb && cb(data);
			},
			(err) => {
				setLoading(false);
				setOpenSnackbarFail(true);
				cb_err && cb_err(err);
			}
		);
	}, []);

	const handleAddTeamAssociateForClient = useCallback((setLoading, payload, cb?, cb_err?) => {
		setLoading(true);
		makeRequest(
			() => {
				return createTeamMemberForVendorAndClient(payload);
			},
			(data: any) => {
				setLoading(false);
				setOpenSnackbarSuccess(true);
				cb && cb(data);
			},
			(err) => {
				setLoading(false);
				setOpenSnackbarFail(true);
				cb_err && cb_err(err);
			}
		);
	}, []);

	const handleDeleteTeamAssociateForVendor = useCallback((setLoading, payload, cb?, cb_err?) => {
		setLoading(true);
		makeRequest(
			() => {
				return deleteTeamAssociateForVendor(payload);
			},
			(data: any) => {
				setLoading(false);
				setOpenSnackbarSuccess(true);
				cb && cb(data);
			},
			(err) => {
				setLoading(false);
				setOpenSnackbarFail(true);
				cb_err && cb_err(err);
			}
		);
	}, []);

	const handleDeleteTeamAssociateForClient = useCallback((setLoading, payload, cb?, cb_err?) => {
		setLoading(true);
		makeRequest(
			() => {
				return deleteTeamAssociateForClient(payload);
			},
			(data: any) => {
				setLoading(false);
				setOpenSnackbarSuccess(true);
				cb && cb(data);
			},
			(err) => {
				setLoading(false);
				setOpenSnackbarFail(true);
				cb_err && cb_err(err);
			}
		);
	}, []);

	const handleDeleteTeamAssociateWithCompany = useCallback((setLoading, teamAssociateId, cb?) => {
		setLoading(true);
		makeRequest(
			() => {
				return deleteMember(companyId || "", teamAssociateId);
			},
			(data: any) => {
				setOpenSnackbarSuccess(true);
				setLoading(false);
				cb && cb();
			},
			() => {
				setLoading(false);
				setOpenSnackbarFail(true);
			}
		);
	}, []);

	const fetchApplForClient = useCallback(
		(setLoading, setData, clientId) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAppForClient({ companyId, clientId });
				},
				(data: any) => {
					const flData = data.length ? data : [];
					setData(flData);
					setLoading(false);
				},
				(err: any) => {
					setLoading(false);
				}
			);
		},
		[getAppForClient]
	);

	const fetchApplForCompany = useCallback(
		(setLoading, setData) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAppForCompany({ companyId });
				},
				(data: any) => {
					let flData = data.length ? data : [];
					flData = flData.filter((item) => Boolean(item.clientId) === false);
					setData(flData);
					setLoading(false);
				},
				(err: any) => {
					setLoading(false);
				}
			);
		},
		[getAppForClient]
	);

	const fetchApplForTotalCompany = useCallback(
		(setLoading, setData) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAppForCompany({ companyId });
				},
				(data: any) => {
					const flData = data.length ? data : [];
					setData(flData);
					setLoading(false);
				},
				(err: any) => {
					setLoading(false);
				}
			);
		},
		[getAppForClient]
	);

	const handleGetCompanyInfo = useCallback(
		(setLoading, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return getCompanyInfo({ companyId, snrId });
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[getCompanyInfo]
	);

	// Department
	const handleCreateDepartment = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return createCompanyDepartment(payload);
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setOpenSnackbarSuccess(true);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[createCompanyDepartment]
	);

	const handleUpdateDepartment = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return updateDepartment(payload);
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[updateDepartment]
	);

	const handleDeleteDepartment = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return deleteCompanyDepartment(payload.companyId, payload.companyDeptId);
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[deleteCompanyDepartment]
	);

	// Categories
	const handleGetCategory = useCallback(
		(setLoading, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return getCategories({ companyId });
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
				}
			);
		},
		[getCategories]
	);

	const handleGetArchievedCategory = useCallback(
		(setLoading, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return getArchiveTaskCategory({ companyId });
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
				}
			);
		},
		[getArchiveTaskCategory]
	);

	const handleCreateCategory = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return createCategories(payload);
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setOpenSnackbarSuccess(true);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[createCategories]
	);

	const handleUpdateCategory = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return updateCategories(payload);
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setLoading(false);
					setOpenSnackbarSuccess(true);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[updateCategories]
	);

	const handleDeleteCategory = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return deleteCategories(payload.companyId, payload.applicationCategoryId);
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setLoading(false);
					setOpenSnackbarSuccess(true);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[deleteCategories]
	);

	const handleArchiveCategory = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return archiveCategories(payload);
				},
				(data: any) => {
					callbackResult && callbackResult(data);
					setOpenSnackbarSuccess(true);
					setLoading(false);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[archiveCategories]
	);

	// ======================== Assignments =========================

	const handleGetAssignmentsForTeamAssociate = useCallback(
		(
			setLoading,
			teamAssociateId,
			callbackResult?: (params: any) => void,
			callbackError?: () => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAssignmentsForTeamAssociate({ companyId, teamAssociateId });
				},
				(data) => {
					setLoading(false);
					callbackResult && callbackResult(data);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
				}
			);
		},
		[getAssignmentsForTeamAssociate]
	);

	const handleGetMemberForManager = useCallback(
		(
			setLoading,
			payload: { companyId: string, managerId: string },
			callbackResult?: (params: any) => void,
			callbackError?: () => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return getMemberForManager({
						companyId: payload.companyId || companyId,
						managerId: payload.managerId
					});
				},
				(data) => {
					setLoading(false);
					callbackResult && callbackResult(data);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
				}
			);
		},
		[getMemberForManager]
	);


	const handleGetMemberForReviewer = useCallback(
		(
			setLoading,
			payload: { companyId: string, reviewerId: string },
			callbackResult?: (params: any) => void,
			callbackError?: () => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return getMemberForReviewer({
						companyId: payload.companyId || companyId,
						reviewerId: payload.reviewerId
					});
				},
				(data) => {
					setLoading(false);
					callbackResult && callbackResult(data);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
				}
			);
		},
		[getMemberForReviewer]
	);

	// ======================== Application =========================

	const handleCreateApplication = useCallback(
		(
			setLoading,
			payload,
			callbackResult?: (params?: any) => void,
			callbackError?: (params?: any) => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return createDeptApp(payload);
				},
				(data) => {
					setLoading(false);
					setOpenSnackbarSuccess(true);
					callbackResult && callbackResult(data);
				},
				(error) => {
					callbackError && callbackError(error);
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[createDeptApp]
	);

	const handleUpdateApplication = useCallback(
		(
			setLoading,
			payload,
			callbackResult?: (params?: any) => void,
			callbackError?: (params?: any) => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return updateApp(payload);
				},
				(data) => {
					setLoading(false);
					setOpenSnackbarSuccess(true);
					callbackResult && callbackResult(data);
				},
				(error) => {
					callbackError && callbackError(error);
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[updateApp]
	);

	const handleDeleteApplication = useCallback(
		(
			setLoading,
			payload,
			callbackResult?: (params?: any) => void,
			callbackError?: (params?: any) => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return deleteDeptApp(payload?.companyId, payload?.applicationId);
				},
				(data) => {
					setLoading(false);
					setOpenSnackbarSuccess(true);
					callbackResult && callbackResult(data);
				},
				(error) => {
					callbackError && callbackError(error);
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[deleteDeptApp]
	);
	
	const handleArchiveApplication = useCallback(
		(
			setLoading,
			payload,
			callbackResult?: (params?: any) => void,
			callbackError?: (params?: any) => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return archiveDeptApp(payload);
				},
				(data) => {
					setLoading(false);
					setOpenSnackbarSuccess(true);
					callbackResult && callbackResult(data);
				},
				(error) => {
					callbackError && callbackError(error);
					setLoading(false);
					setOpenSnackbarFail(true);
				}
			);
		},
		[archiveDeptApp]
	);
	

	return {
		fetchClientByVendors,
		fetchApplForClient,
		fetchApplForCompany,
		fetchApplForTotalCompany,
		handleFetchApplications,
		handleFetchArchivedApplications,

		fetchTeamMember,
		fetchMembersForClient,
		fetchTeamMemberWithFilter,
		fetchExternalManagers,
		fetchCompanyInfoInit,
		fetchScreenMappingForTeamMemberInit,
		fetchTeamAssociateWithCompanyInit,
		fetchAssignmentForVendorLocal,
		handleUpdateAssignment,
		handleReAssignManager,
		fetchClientsManagerForCompany,
		handleUpdateTeamAssociateWithCompany,
		fetchExternalMemberForCompany,
		fetchCompanyDepartment,
		fetchMembersForClientWithCompany,

		handleAddTeamAssociateWithCompany,
		handleDeleteTeamAssociateWithCompany,
		fetchMembersForVendorWithCompany,

		handleUpdateTeamAssociateWithoutEffectiveDate,
		handleGetCompanyInfo,
		handleGetMemberForManager,

		handleGetMemberForReviewer,

		// Department
		handleCreateDepartment,
		handleUpdateDepartment,
		handleDeleteDepartment,

		// Categories
		handleGetCategory,
		handleGetArchievedCategory,
		handleCreateCategory,
		handleUpdateCategory,
		handleDeleteCategory,
		handleArchiveCategory,

		//Assignments
		fetchAssignments,
		fetchAssignmentsForClient,
		handleGetAssignmentsForTeamAssociate,

		// Application
		handleCreateApplication,
		handleUpdateApplication,
		handleDeleteApplication,
		handleArchiveApplication,
		handleFetchApplForClient,

		//vendor member
		handleAddTeamAssociateForVendor,

		handleDeleteTeamAssociateForVendor,
		handleDeleteTeamAssociateForClient,


		//client member
		handleAddTeamAssociateForClient
	};
};

export default useCompanyActions;
