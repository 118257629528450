import wretch, { Wretcher } from "wretch";
import type { Post, Get, Put, Delete } from "common/api/types";

interface ActionCreators {
  //Dashboard Referral
  getInvoiceChartForClient: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getTeamAssociateInfoForClient: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;

  getPaymentChartForVendor: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getTeamAssociateInfoForVendor: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getChartDashboardFinanceManager: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;

  //company
  getChartForCompany: Post<Record<string, unknown>, Record<string, unknown>>;
  getTeamAssociateInfoForCompany: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
}

const baseUrl = process.env.REACT_APP_PAYMENT_URL;
const clientInvoiceChartUrl = baseUrl + "/dashboard-client/invoice";
const clientAssociateInfoUrl = baseUrl + "/dashboard-client/associate";
const clientFinanceURL = process.env.REACT_APP_SUB_COMPANY_URL + "/dashboard-client";

const vendorPaymentChartUrl = baseUrl + "/dashboard-vendor/payment";
const vendorAssociateInfoUrl = baseUrl + "/dashboard-vendor/associate";

const companyChartUrl = baseUrl + "/dashboard-company";
const companyAssociateInfoUrl = baseUrl + "/dashboard-company/associate";

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  //Dashboard
  getInvoiceChartForClient: (body) => gateway.url(clientInvoiceChartUrl).post(body).json(),
  getTeamAssociateInfoForClient: (body) => gateway.url(clientAssociateInfoUrl).post(body).json(),
  getPaymentChartForVendor: (body) => gateway.url(vendorPaymentChartUrl).post(body).json(),
  getTeamAssociateInfoForVendor: (body) => gateway.url(vendorAssociateInfoUrl).post(body).json(),
  getChartForCompany: (body) => gateway.url(companyChartUrl).post(body).json(),
  getTeamAssociateInfoForCompany: (body) => gateway.url(companyAssociateInfoUrl).post(body).json(),
  getChartDashboardFinanceManager: (body) => gateway.url(clientFinanceURL).post(body).json(),
});

export const dashboardActionCreators = (): {
  actionCreators: ActionCreators;
  headers: Record<string, string>;
} => {
  const authHeaders: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const defaultHeaders: { [key: string]: string } = {
    "X-Api-Key": process.env.REACT_APP_PAYMENT_KEY as any,
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}` || ``,
  };

  const headers = !localStorage.getItem("accessToken")
    ? authHeaders
    : defaultHeaders;
  const gateway = wretch().headers(headers);
  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
