import { makeStyles } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";
import { Auth } from "aws-amplify";
import makeRequest from "common/api/makeRequest";
import FormItemLabel from "common/components/FormItemLabel";
import NotifyText from "common/components/NotifyText";
import UgotaGiftButton from "common/components/UgotaGiftButton";
import { globalColor } from "common/styles/common";
import { ROUTES } from "components/Shared/pages";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { GridContainerStyled } from "timesheets/styles/common";
import { awsPasswordPolicy } from "timesheets/utils/regex";
import * as Yup from "yup";

const useStyles = makeStyles({
  wrapperSignup: {
    padding: "58px",
    position: "relative",
    borderRadius: "24px",
    background: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  },
});

const SignUpForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useTimeSheetContext();
  const { setSignUpAuth } = context;
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] =
    useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Require!"),
      password: Yup.string()
        .matches(awsPasswordPolicy.regex, awsPasswordPolicy.message)
        .required("Require!"),
      confirmPassword: Yup.string().required("Require!"),
    }),
    onSubmit: async (values) => {
      const { password, confirmPassword } = values;
      if (password !== confirmPassword) {
        setError("The password confirmation does not match");
      } else {
        setError("");
        handleSubmit(values);
      }
    },
  });

  const handleSubmit = useCallback((payload) => {
    setLoading(true);
    const { email, password } = payload;
    makeRequest(
      () => {
        return Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
          },
          autoSignIn: { enabled: true },
        });
      },
      (user: any) => {
        setLoading(false);
        setError("");
        setSignUpAuth({
          emailDes: user.codeDeliveryDetails.Destination,
          username: user.user.getUsername(),
          password,
        });
        history.push(ROUTES.confirmSignUp);
      },
      (e: any) => {
        setLoading(false);
        setSignUpAuth({ emailDes: "", username: "" });
        setError(e?.message || "FAIL");
      }
    );
  }, []);

  return (
    <Box className={classes.wrapperSignup}>
      <FormikProvider value={formik}>
        <Form>
          <Box display="flex" justifyContent={"center"}>
            <img src={TSLogo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography textAlign={"center"} fontWeight="bold" fontSize={"36px"}>
            Create Account
          </Typography>
          <Typography textAlign={"center"} sx={{ color: "rgba(0,0,0,0.5)" }}>
            Please sign up to continue
          </Typography>
          <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={12}>
              <Field name={`email`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Email">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              <Field name={`password`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Password">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      type={isShowPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <VisibilityIcon
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      }}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              <Field name={`confirmPassword`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Confirm Password">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      type={isShowConfirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <VisibilityIcon
                            onClick={() =>
                              setIsShowConfirmPassword(!isShowConfirmPassword)
                            }
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      }}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              {error && <NotifyText text={error} type="error" />}
            </Grid>
            <Stack
              display={"flex"}
              flexDirection="row"
              gap={"10px"}
              justifyContent="center"
              marginTop={"10px"}
              width={"100%"}
            >
              <UgotaGiftButton type="submit" loading={loading}>
                SIGN UP
              </UgotaGiftButton>
            </Stack>
          </GridContainerStyled>
          <Typography marginTop="50px" textAlign={"right"}>
            Already have an account ?
          </Typography>
          <Typography
            color={globalColor.primary}
            fontWeight="bold"
            textAlign={"right"}
            sx={{ cursor: "pointer" }}
            onClick={() => history.push(ROUTES.login)}
          >
            Sign in
          </Typography>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default SignUpForm;
