import wretch, { Wretcher } from "wretch";
import type { Post, Get, Put, Delete } from "common/api/types";
import { getCookie } from "../../common/helpers/cookies";
interface ActionCreators {
  archiveRole: Post<Record<string, unknown>, Record<string, unknown>>;
  archiveDeptApp: Post<Record<string, unknown>, Record<string, unknown>>;
  archiveCategories: Post<Record<string, unknown>, Record<string, unknown>>;
  archiveApplPhase: Post<Record<string, unknown>, Record<string, unknown>>;
  archiveBusCap: Post<Record<string, unknown>, Record<string, unknown>>;
  archiveBusCategory: Post<Record<string, unknown>, Record<string, unknown>>;
  assignmentNewManager: Put<Record<string, unknown>, Record<string, unknown>>;
  assignmentNewRsManager: Put<Record<string, unknown>, Record<string, unknown>>;
}
const archiveUrl = process.env.REACT_APP_COMPANY_SERVICE_MGMT_API_URL;
const assignNewManagerUrl = archiveUrl + "/team-associate/change-manager";
const assignNewRsManagerUrl =
archiveUrl + "/team-associate/change-manager";

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  archiveRole: (body) => gateway.url(archiveUrl + "/archive-role").post(body).json(),
  archiveDeptApp: (body) => gateway.url(archiveUrl + "/archive-application").post(body).json(),
  archiveCategories: (body) => gateway.url(archiveUrl + "/archive-task-category").post(body).json(),
  archiveApplPhase: (body) => gateway.url(archiveUrl + "/archive-applicationphase").post(body).json(),
  archiveBusCap: (body) => gateway.url(archiveUrl + "/archive-business-capibilty").post(body).json(),
  archiveBusCategory: (body) => gateway.url(archiveUrl + "/archive-business-category").post(body).json(),
  assignmentNewManager: (body) => gateway.url(assignNewManagerUrl).put(body).json(),
  assignmentNewRsManager: (body) => gateway.url(assignNewRsManagerUrl).put(body).json(),
});

export const archiveActionCreators = (): {
  actionCreators: ActionCreators;
  headers: Record<string, string>;
} => {
  const authHeaders: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const defaultHeaders: { [key: string]: string } = {
    // 'Session-Id': getCookie('sessionId'),
    "X-Api-Key": process.env.REACT_APP_COMPANY_SERVICE_MGMT_KEY as any,
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("accessToken")}` || ``,
  };

  const headers = !getCookie("accessToken") ? authHeaders : defaultHeaders;
  const w = wretch().catcher(401, (err) => console.log({ err: "401 nè" }));
  const gateway = w.headers(headers);
  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
