import { TextField, Tooltip } from "@mui/material";
import React from "react";
import { removeLegendTextField } from "timesheets/styles/common";
import FormItemLabel from "../FormItemLabel";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

interface ITextFieldInput {
	form?: any;
	name: string;
	label?: string;
	type?: string;
	valueProp?: string;
	required?: boolean;
	placeholder?: string;
	disabled?: boolean;
	hiddenLabel?: boolean;
	multiline?: boolean;
	minRows?: number;
	maxRows?: number;
	onChange?: (payload: any) => void;
	onBlur?: (payload: any) => void;
	InputProps?: any;
	style?: any;
	isIconEdit?: boolean;
	isIconEditValue?: boolean;
	iconDelete?: any;
}

export const useStyles = makeStyles((theme) => ({
	textFieldBlock: {
		display: "block",
	},
	textFieldInput: { position: "relative" },
	icon: {
		position: "absolute",
		top: "50%",
		right: "2%",
		transform: "translateY(-50%)",
		lineHeight: 0,
	},
	iconBlock: {
		width: "30px",
		height: "30px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		top: "5px",
		right: "5px",
	},
	iconAction: {
		cursor: "pointer",
		fontSize: 20,
		color: "#b8b8b8",
		lineHeight: 0,
	},
}));

const TextFieldInput: React.FC<ITextFieldInput> = (props) => {
  const {
		form,
		name,
		label,
		type = "text",
		required = false,
		placeholder,
		disabled = false,
		onChange,
		onBlur,
		hiddenLabel = false,
		InputProps,
		multiline,
		valueProp,
		minRows = "4",
		maxRows = "6",
		style,
		isIconEdit,
		isIconEditValue,
		iconDelete,
	} = props;

  const classes = useStyles();

	const [isEdit, setIsEdit] = React.useState<boolean | undefined>(isIconEditValue);

  return (
		<Box className={classes.textFieldBlock}>
			<FormItemLabel label={label} required={required}>
				<Box className={classes.textFieldInput}>
					<TextField
						size="small"
						fullWidth
						type={type}
						name={name}
						id={name}
						hiddenLabel={hiddenLabel}
						value={(valueProp ? valueProp : form?.values?.[name]) || ""}
						onChange={onChange ? onChange : form?.handleChange}
						onBlur={onBlur ? onBlur : form?.handleBlur}
						placeholder={placeholder}
						error={form?.touched?.[name] && !!form?.errors?.[name]}
						helperText={form?.touched?.[name] && form?.errors?.[name]}
						sx={{ ...removeLegendTextField, ...style }}
						disabled={disabled || (isIconEdit && !isEdit)}
						InputProps={InputProps}
						multiline={multiline}
						minRows={multiline ? minRows : undefined}
						maxRows={multiline ? maxRows : undefined}
					/>
					{isIconEdit && !disabled && (
						<Box className={classes.iconBlock}>
							<Tooltip title={label}>
								<Box className={classes.iconAction} onClick={() => setIsEdit(!isEdit)}>
									{isEdit ? <SaveIcon /> : <EditIcon />}
								</Box>
							</Tooltip>
						</Box>
					)}
					{iconDelete && !disabled && (
						<Box className={classes.iconBlock}>
							<Tooltip title={label}>{iconDelete}</Tooltip>
						</Box>
					)}
				</Box>
			</FormItemLabel>
		</Box>
	);
};

export default TextFieldInput;
