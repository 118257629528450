import useActionCreators from "common/api/useActionCreators";
import { helperToken } from "timesheets/api/helperToken";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import wretch from "wretch";

type HeadersType = {
  token: any;
  xApiKey: string;
  contentType?: string;
  [x: string]: any;
};

const clientIdForCompany = process.env.REACT_APP_COMPANY_CLIENT_ID;
const clientSecretForCompany = process.env.REACT_APP_COMPANY_CLIENT_SECRET;
const tokenUrlForCompany = process.env.REACT_APP_COMPANY_API_URL;

export const gateway = ({
  token,
  xApiKey,
  contentType,
  ...others
}: HeadersType) => {
  const { setIsToken, setTriggerTokenCompany, triggerTokenCompany } =
    useTimeSheetContext();
  const {
    actionCreators: { getToken },
  } = useActionCreators();

  const defaultHeaders: { [key: string]: string } = {
    "X-Api-Key": `${xApiKey}`,
    "Content-Type": contentType || "application/json",
    Authorization: `Bearer ${token}` || ``,
    ...others,
  };

  return wretch()
    .headers(defaultHeaders)
    .catcher(401, async (error, request) => {
      // Renew credentials
      const token = await getToken({
        clientId: clientIdForCompany,
        clientSecret: clientSecretForCompany,
        tokenUrl: tokenUrlForCompany,
      });

      // Replay the original request with new credentials
      return (
        request
          // .auth(token)
          .replay()
          .unauthorized((err) => {
            throw err;
          })
          .json()
      );
    });
};
