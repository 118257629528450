import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { globalColor } from "common/styles/common";

const UgotaGiftButton = styled(LoadingButton)(({ size, variant }) => ({
  borderRadius: "14px",
  ...handleSize(size),
  ...handleVariant(variant),
}));

const handleSize = (variant) => {
  const smallStyle = {
    padding: "0 20px",
  };
  const normalStyle = {
    padding: "10px 50px",
  };
  switch (variant) {
    case "small":
      return smallStyle;
    case "normal":
      return normalStyle;
    default:
      return normalStyle;
  }
};

const handleVariant = (size) => {
  const outlinedStyle = {
    color: globalColor.primary,
    border: `1px solid ${globalColor.borderColor}`,
    background: "#fff",
    "&:hover": {
      color: globalColor.primary,
      border: `1px solid ${globalColor.borderColor}`,
      background: "#fff",
    },
  };

  const containedStyle = {
    color: "#fff",
    border: `1px solid ${globalColor.primary}`,
    background: globalColor.primary,
    "&:hover": {
      color: "#fff",
      border: `1px solid ${globalColor.primary}`,
      background: globalColor.primary,
    },
  };
  switch (size) {
    case "outlined":
      return outlinedStyle;
    case "contained":
      return containedStyle;
    default:
      return containedStyle;
  }
};

export default UgotaGiftButton;
