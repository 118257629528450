import { makeStyles } from "@material-ui/core";
import { RouterSharp } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import makeRequest from "common/api/makeRequest";
import FormItemLabel from "common/components/FormItemLabel";
import UgotaGiftButton from "common/components/UgotaGiftButton";
import { ROUTES } from "components/Shared/pages";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { GridContainerStyled } from "timesheets/styles/common";
import * as Yup from "yup";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";
import { LoadingButton } from "@mui/lab";
import NotifyText from "common/components/NotifyText";
import { applActionCreators } from "timesheets/api/companyActions";
const configStep = {
  step1: {
    email: {
      name: "email",
      label: "Email",
      requiredMessage: "Please provide the email!",
    },
  },
  step2: {
    confirmCode: {
      name: "code",
      label: "Code",
      requiredMessage: "Please provide the code you received!",
    },
    newPassword: {
      name: "newPassword",
      label: "New password",
      requiredMessage: "Please provide the new password!",
    },
    confirmNewPassword: {
      name: "confirmNewPassword",
      label: "Confirm new password",
      requiredMessage: "Please re-enter your password!",
      notMatchMessage: "Passwords are not the same!",
    },
  },
};

const validationSchema = [
  Yup.object().shape({
    [configStep.step1.email.name]: Yup.string().required(
      configStep.step1.email.requiredMessage
    ),
  }),
  Yup.object().shape({
    [configStep.step2.confirmCode.name]: Yup.string().required(
      configStep.step2.confirmCode.requiredMessage
    ),
    [configStep.step2.newPassword.name]: Yup.string().required(
      configStep.step2.newPassword.requiredMessage
    ),
    [configStep.step2.confirmNewPassword.name]: Yup.string()
      .oneOf(
        [Yup.ref(configStep.step2.newPassword.name)],
        configStep.step2.confirmNewPassword.notMatchMessage
      )
      .required(configStep.step2.confirmNewPassword.requiredMessage),
  }),
];

const useStyles = makeStyles({
  wrapperConfirmSignup: {
    padding: "58px",
    position: "relative",
    borderRadius: "24px",
    background: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  },
});

const ForgotPasswordForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useTimeSheetContext();
  const {
    setOpenSnackbarSuccess,
    setSnackbarContentSuccess,
    setOpenSnackbarFail,
    setSnackbarContentFail,
  } = context;
  const { step1, step2 } = configStep;
  const [loading, setLoading] = useState<boolean>(false);
  const [resendLoading, setResetLoading] = useState<boolean>(false);
  const [disableResend, setDisableResend] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [resetTimer, setResetTimer] = useState<number>(60);
  const [error, setError] = useState<string>("");
  let interval: any = null;
  const {
    actionCreators: { checkEmailVerify },
  } = applActionCreators();
  const handleGetCode = useCallback(
    (payload) => {
      setLoading(true);
      const { email } = payload;
      makeRequest(
        () => {
          return Auth.forgotPassword(email);
        },
        (data) => {
          setCurrentStep(currentStep + 1);
          setLoading(false);
          setOpenSnackbarSuccess(true);
          setSnackbarContentSuccess("Please check your email to get your code");
          startTimer();
        },
        (e: any) => {
          setLoading(false);
          setOpenSnackbarFail(true);
          setSnackbarContentFail(e?.message || "FAIL");
        }
      );
    },
    [currentStep]
  );

  const handleResendCode = useCallback(
    (payload) => {
      setResetLoading(true);
      const { email } = payload;
      makeRequest(
        () => {
          return Auth.forgotPassword(email);
        },
        (data) => {
          setResetLoading(false);
          setOpenSnackbarSuccess(true);
          setSnackbarContentSuccess("Please check your email to get your code");
          startTimer();
        },
        (e: any) => {
          setResetLoading(false);
          setOpenSnackbarFail(true);
          setSnackbarContentFail(e?.message || "FAIL");
        }
      );
    },
    [currentStep]
  );

  const handleCheckEmailVerify = useCallback(
    (payload, callback) => {
      setLoading(true);
      makeRequest(
        () => {
          return checkEmailVerify(payload);
        },
        (data) => {
          setLoading(false);
          if (data.UserStatus === "FORCE_CHANGE_PASSWORD") {
            return setError(
              "Please check your mail and use temporary password to continue !"
            );
          }
          if (
            data.UserStatus === "CONFIRMED" &&
            data.email_verified === "false"
          ) {
            return setError("Your email is not verified !!");
          } else {
            setError("");
            callback();
          }
        },
        (e: any) => {
          setLoading(false);
          setOpenSnackbarFail(true);
          setSnackbarContentFail("FAIL");
        }
      );
    },
    [currentStep]
  );

  const handleChangePassword = useCallback((payload) => {
    setLoading(true);
    const { email, code, newPassword } = payload;
    makeRequest(
      () => {
        return Auth.forgotPasswordSubmit(email, code, newPassword);
      },
      (data) => {
        setLoading(false);
        history.push(ROUTES.login);
        setOpenSnackbarSuccess(true);
        setSnackbarContentSuccess("Change password successfully");
      },
      (e: any) => {
        setLoading(false);
        setOpenSnackbarFail(true);
        setSnackbarContentFail(e?.message || "FAIL");
      }
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      [step1.email.name]: "",
      [step2.confirmCode.name]: "",
      [step2.newPassword.name]: "",
      [step2.confirmNewPassword.name]: "",
    },
    validationSchema: validationSchema[currentStep],
    onSubmit: async (values) => {
      if (currentStep) {
        handleChangePassword(values);
      } else {
        handleCheckEmailVerify(values, () => handleGetCode(values));
      }
    },
  });

  useEffect(() => {
    if (resetTimer !== 0) {
      setDisableResend(true);
    } else {
      setDisableResend(false);
      clearTimer();
    }
  }, [resetTimer]);

  const startTimer = () => {
    const countDownTime = new Date(new Date().getTime() + 60000);
    interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = countDownTime.getTime() - now;
      if (difference > 0) {
        const sec = Math.floor((difference % (1000 * 60)) / 1000);
        setResetTimer(sec);
      }
    }, 1000);
  };

  const clearTimer = () => {
    clearInterval(interval);
  };

  function addZero(num, size) {
    let val = parseInt(num) + "";
    while (val.length < size) val = "0" + val;
    return val;
  }

  return (
    <Box className={classes.wrapperConfirmSignup}>
      <FormikProvider value={formik}>
        <Form>
          <Box display="flex" justifyContent={"center"}>
            <img src={TSLogo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography textAlign={"center"} fontWeight="bold" fontSize={"36px"}>
            Forgot password
          </Typography>
          <Typography textAlign={"center"} sx={{ color: "rgba(0,0,0,0.5)" }}>
            {currentStep
              ? "Please create new password"
              : "Please use email to find your account"}
          </Typography>
          {currentStep === 0 && (
            <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Field name={step1.email.name}>
                  {({ field, meta }) => (
                    <FormItemLabel label={step1.email.label}>
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                      ></TextField>
                    </FormItemLabel>
                  )}
                </Field>
              </Grid>
            </GridContainerStyled>
          )}

          {currentStep === 1 && (
            <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Field name={step2.confirmCode.name}>
                  {({ field, meta }) => (
                    <FormItemLabel label={step2.confirmCode.label}>
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                      ></TextField>
                    </FormItemLabel>
                  )}
                </Field>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <LoadingButton
                    loading={resendLoading}
                    disabled={resendLoading || disableResend}
                    type="button"
                    sx={{
                      color: "#0000aa",
                    }}
                    onClick={() => {
                      handleResendCode({
                        email: formik.values.email,
                      });
                    }}
                  >
                    Resend Code
                    {resetTimer > 0 ? `(00:${addZero(resetTimer, 2)})` : ""}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Field name={step2.newPassword.name}>
                  {({ field, meta }) => (
                    <FormItemLabel label={step2.newPassword.label}>
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                        type={isShowPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <VisibilityIcon
                              onClick={() => setIsShowPassword(!isShowPassword)}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          ),
                        }}
                      ></TextField>
                    </FormItemLabel>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                <Field name={step2.confirmNewPassword.name}>
                  {({ field, meta }) => (
                    <FormItemLabel label={step2.confirmNewPassword.label}>
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                        type={isShowPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <VisibilityIcon
                              onClick={() => setIsShowPassword(!isShowPassword)}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          ),
                        }}
                      ></TextField>
                    </FormItemLabel>
                  )}
                </Field>
              </Grid>
            </GridContainerStyled>
          )}
          {error && <NotifyText text={error} type="error" />}
          <Stack
            display={"flex"}
            flexDirection="row"
            gap={"10px"}
            justifyContent="center"
            marginTop={"20px"}
            width={"100%"}
          >
            <UgotaGiftButton type="submit" loading={loading}>
              {currentStep ? "SUBMIT" : "GET CODE"}
            </UgotaGiftButton>
          </Stack>
          <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              marginTop: "50px",
              textAlign: "right",
            }}
            onClick={() => history.goBack()}
          >
            Back
          </Typography>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default ForgotPasswordForm;
