import { Box, Modal } from "@mui/material";
import CustomLoader from "common/components/Loader";
import React, { Suspense, useEffect, useState } from "react";
import CompanyInfoModal from "timesheets/components/CompanyInfo/CompanyModal";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { COMPANY_STATUS_NEW } from "timesheets/utils/constant";

const sxModal = [
  {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 950,
    bgcolor: "white",
    borderRadius: "10px",
    maxHeight: "100vh !important",
    overflowY: "auto !important",
    overflowX: "hidden",
  },
];

const CheckNewCompany: React.FC = ({ children }: any) => {

  const context = useTimeSheetContext();
  const { companyInformation } = context;

  // manage modal
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);


  useEffect(() => {
    if (companyInformation?.timeSheetInfo) {
      context.setCompanyDistributionInfo(
        companyInformation.timeSheetInfo[0]?.distributionInfo || {}
      );
    }
    if (companyInformation?.companyStatus === COMPANY_STATUS_NEW) {
      handleOpenModal();
    }
  }, [companyInformation]);

  // prevent memory leak
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <Suspense fallback={<CustomLoader />}>
      {companyInformation?.companyStatus &&
        companyInformation?.companyStatus !== COMPANY_STATUS_NEW &&
        children}
      <Modal
        open={openModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleCloseModal();
          }
        }}
        disableEscapeKeyDown
      >
        <Box sx={sxModal}>
          <CompanyInfoModal closeModal={handleCloseModal} />
        </Box>
      </Modal>
    </Suspense>
  );
};

export default CheckNewCompany;
