import React from "react";
import { ACTIONS_BASIC, COMPANY_TABS, COUNTRY_CONFIG_TAB, screenCode, TAB_ACTIONS } from "timesheets/utils/constant";

// --- CLIENT ---
const ClientPages = React.lazy(() => import("timesheets/pages/Clients"));
const ClientCreate = React.lazy(() => import("timesheets/components/Clients/ClientCreate"));
const ClientDetails = React.lazy(() => import("timesheets/components/Clients/ClientDetails"));
const ClientTransferPage = React.lazy(() => import("timesheets/pages/Clients/Transfer"));
const ProjectDetails = React.lazy(() => import("timesheets/components/ProjectManagement/ProjectDetails"));
const TeamMember = React.lazy(() => import("timesheets/pages/Members"));
const AddNewTeamMember = React.lazy(() => import("timesheets/pages/Members/AddNewTeamMember"));
const TeamMemberDetails = React.lazy(() => import("timesheets/pages/Members/MemberDetailsForm"));

// --- VENDOR ---
const Vendors = React.lazy(() => import("timesheets/pages/Vendors"));
const VendorCreate = React.lazy(() => import("timesheets/components/Vendors/VendorCreate"));
const VendorDetails = React.lazy(() => import("timesheets/components/Vendors/VendorDetails"));

// --- COMPANY ---
const CompanyInfoPage = React.lazy(() => import("timesheets/pages/CompanyInfo"));
const CompanyTransferPage = React.lazy(
	() => import("timesheets/pages/CompanyInfo/Transfer")
);

// --- PAYMENT ---
const PaymentPage = React.lazy(() => import("timesheets/pages/Payment"));
// --------------

// TimeSheetApprovel
const TSApproval = React.lazy(() => import("timesheets/pages/TimeSheetApproval"));
// TimeSheetResource
const TimeSheetResourcePage = React.lazy(() => import("timesheets/pages/TimeSheetResource"));

const TimeSheetResourceCreatePage = React.lazy(
	() => import("timesheets/pages/TimeSheetResource/Create")
);

const TimeSheetResourceDuplicatePage = React.lazy(
	() => import("timesheets/pages/TimeSheetResource/Duplicate")
);

const TimeSheetResourcePageDetail = React.lazy(
	() => import("timesheets/components/TimeSheetResource/TimeSheetResourceDetails")
);

const TimeSheetResourcePageDetailDuplicate = React.lazy(
	() => import("timesheets/components/TimeSheetResource/TimeSheetResourceDetailsDuplicate")
);
const TimeSheetDuplicateDetailsPage = React.lazy(
	() => import("timesheets/pages/timeSheet/Duplicate")
);

const TimeSheet = React.lazy(() => import("timesheets/pages/timeSheet"));
const TimeSheetCreatePage = React.lazy(() => import("timesheets/pages/timeSheet/Create"));
const CreateTeamMemberSub = React.lazy(
	() => import("timesheets/components/TeamMember/CreateTeamMember")
);

const TimeSheetDetail = React.lazy(() => import("timesheets/pages/TimeSheetDetail"));
const ClientTeamMember = React.lazy(() => import("timesheets/pages/ClientTeamMember"));

const AddAppl = React.lazy(() => import("timesheets/components/Applications/AddAppInfoForm"));
const ApplDetails = React.lazy(() => import("timesheets/components/Applications/AppDetailsForm"));
// ApplsScreen
const CreateApplsScreen = React.lazy(
	() => import("timesheets/pages/ApplsScreen/CreateApplsScreen")
);
const ApplsScreenDetails = React.lazy(
	() => import("timesheets/pages/ApplsScreen/ApplsScreenDetails")
);
// AuthenticationRoles
const AuthenticationRolesDetails = React.lazy(
	() => import("timesheets/pages/AuthenticationRoles/Details")
);
// ScreenRoleMapping
const ScreenRoleMapping = React.lazy(() => import("timesheets/pages/ScreenRoleMapping"));
const CreateScreenRoleMapping = React.lazy(
	() => import("timesheets/pages/ScreenRoleMapping/CreateScreenRoleMapping")
);
const ScreenRoleMappingDetails = React.lazy(
	() => import("timesheets/pages/ScreenRoleMapping/ScreenRoleMappingDetails")
);
// ScreenFieldRoleMapping
const ScreenFieldRoleMapping = React.lazy(() => import("timesheets/pages/ScreenFieldRoleMapping"));
const CreateScreenFieldRoleMapping = React.lazy(
	() => import("timesheets/pages/ScreenFieldRoleMapping/CreateScreenFieldRoleMapping")
);
const ScreenFieldRoleMappingDetails = React.lazy(
	() => import("timesheets/pages/ScreenFieldRoleMapping/ScreenFieldRoleMappingDetails")
);
// Assignments
const AssignmentsPage = React.lazy(() => import("timesheets/pages/Assignments"));
const AssignmentDetails = React.lazy(() => import("timesheets/components/Assignments/AssignmentDetails"));

const AddTemplate = React.lazy(() => import("timesheets/pages/Communications/AddTemplate"));
const PaymentCreatePage = React.lazy(() => import("timesheets/pages/Payment/Create"));
const PaymentTransferPage = React.lazy(() => import("timesheets/pages/Payment/Transfer"));

import Login from "timesheets/pages/Auth/LoginForm";
import SignUp from "timesheets/pages/Auth/SignUpForm";
import ConfirmSignUp from "timesheets/pages/Auth/ConfirmSignUpForm";
import ForgotPassword from "timesheets/pages/Auth/ForgotPasswordForm";
import ChangePassword from "timesheets/pages/Auth/ChangePasswordForm";
import CompletedNewPass from "timesheets/pages/Auth/CompleteNewPasswordForm";
import Dashboard from "timesheets/pages/Dashboard";
import VerifyEmailForm from "timesheets/pages/Auth/VerifyEmail";


import DashboardClientPage from "timesheets/pages/DashboardClient";
import DashboardVendorPage from "timesheets/pages/DashboardVendor";
// VendorHourlyReportPage
const VendorHourlyReportPage = React.lazy(() => import("timesheets/pages/VendorHourlyReport"));
// Categories
// const Categories = React.lazy(() => import("timesheets/components/Categories"));
// const AddCategories = React.lazy(() => import("timesheets/components/Categories/AddCategories"));
// const CategoriesDetails = React.lazy(
// 	() => import("timesheets/components/Categories")
// );

// Country Config
const CountryConfig = React.lazy(() => import("timesheets/pages/CountryConfig"));
// Rbac
const Rbac = React.lazy(() => import("timesheets/pages/Rbac"));
// Pto
const Pto = React.lazy(() => import("timesheets/pages/Pto"));
const PtoDetails = React.lazy(() => import("timesheets/pages/Pto/PtoDetails"));

// Finance Client
const ClientRates = React.lazy(() => import("timesheets/pages/ClientRates"));
const ClientRatesDetails = React.lazy(() => import("timesheets/pages/ClientRates/Details"));

// Finance Vendor
const VendorRates = React.lazy(() => import("timesheets/pages/VendorRates"));
const VendorRatesDetails = React.lazy(() => import("timesheets/pages/VendorRates/Details"));

// InvoicesPage
const InvoicesPage = React.lazy(() => import("timesheets/pages/Invoices"));
const InvoicesCreatePage = React.lazy(() => import("timesheets/pages/Invoices/Create"));
const InvoicesTransferPage = React.lazy(() => import("timesheets/pages/Invoices/Transfer"));
// const InvoicesViewPage = React.lazy(() => import("timesheets/pages/Invoices/Details"));

//Employee Salary Page
const EmpSalariesPage = React.lazy(() => import("timesheets/pages/EmployeeSalary"));
const EditEmpSalaryDetails = React.lazy(() => import("timesheets/pages/EmployeeSalary/EmployeeSalaryDetailsPage"));

// CompanyResource
const CompanyResourcePage = React.lazy(() => import("timesheets/pages/CompanyResource"));
const CompanyResourceTransferPage = React.lazy(() => import("timesheets/pages/CompanyResource/Transfer"));
// Communications
const Communications = React.lazy(() => import("timesheets/pages/Communications"));

// recruitment
const OpenPositionsPage = React.lazy(
	() => import("timesheets/pages/Recruitment/OpenPositionsPage")
);
const OpenPositionsCreatePage = React.lazy(
	() => import("timesheets/pages/Recruitment/OpenPositionsPage/Create")
);
const OpenPositionsUpdatePage = React.lazy(
	() => import("timesheets/pages/Recruitment/OpenPositionsPage/Update")
);
const OpenPositionsViewPage = React.lazy(
	() => import("timesheets/pages/Recruitment/OpenPositionsPage/View")
);

const ProspectiveClientsPage = React.lazy(
	() => import("timesheets/pages/Recruitment/ProspectiveClientsPage")
);
const ProspectiveAssociatesPage = React.lazy(
	() => import("timesheets/pages/Recruitment/ProspectiveAssociatesPage")
);
const ProspectiveVendorsPage = React.lazy(
	() => import("timesheets/pages/Recruitment/ProspectiveVendorsPage")
);
const MailboxPage = React.lazy(() => import("timesheets/pages/Recruitment/MailboxPage"));

const LeavePage = React.lazy(
	() => import("timesheets/pages/Leave/LeavePage")
);

// --------------------- ATTENDANCE ---------------------------//

const AttendanceMemberPage = React.lazy(() => import("timesheets/pages/Attendance/Member"));
const AttendanceManagerPage = React.lazy(() => import("timesheets/pages/Attendance/Manager"));

// Report
const Report = React.lazy(() => import("timesheets/pages/TimeSheetReport"));
const AttendanceReportPage = React.lazy(() => import("timesheets/pages/Attendance/Report"));

// Candidate Profile

const TeamAssociate = React.lazy(() => import("timesheets/pages/TeamAssociate"));
//const c360view = React.lazy(() => import('c360view/pages/AddRrcCmpnyPage'));

export type PageSegmentDetails = {
	trackLoad?: boolean;
	trackLoadName?: string;
};

enum Tabs {
	AboutYou,
	Test,
}

export type Page = {
	name?: string;
	path?: string;
	key?: string;
	component?: React.ComponentType;
	tabIndex?: Tabs | false; //Note: False if no tab is associated - this is specified by @material-ui/core/Tabs.value
	segment?: PageSegmentDetails;
	isAuth?: boolean;
};

export type Pages = Page[];

export const ROUTES = {
	// country configuration
	countryConfig: "country-configuration",
	// memberDetails
	memberDetails: "member-details",
	// communications
	communications: "communications",
	addTemplate: "add-template",
	dashboard: "dashboard",
	dashboardClient: "dashboard-client",
	dashboardVendor: "dashboard-vendor",
	//recruitment
	openPositions: "open-positions",
	addOpenPositions: "add-open-positions",
	viewOpenPositions: "view-open-positions",
	editOpenPositions: "edit-open-positions",

	prospectiveClients: "prospective-clients",
	prospectiveAssociates: "prospective-associates",
	prospectiveVendors: "prospective-vendors",
	mailbox: "mailbox",

	// report
	report: "report",
	attendanceReport: "attendance-report",
	// rbac
	rbac: "rbac",
	// pto
	pto: "personal-timeoff",
	viewPto: "view-personal-timeoff",
	addPto: "add-personal-timeoff",
	editPto: "edit-personal-timeoff",
	// companyResource
	companyResource: "company-resource",
	// assignments
	assignments: "assignments",
	addAssignments: "add-assignments",
	editAssignments: "edit-assignments",
	viewAssignments: "view-assignments",
	// finance-referral
	financeReferral: "finance-referral",
	addFinanceReferral: "add-finance-referral",
	editFinanceReferral: "edit-finance-referral",
	viewFinanceReferral: "view-finance-referral",

	//employee salaries
	employeeSalaries: "employee-salaries",
	editEmployeeSalary: "edit-employee-salary",
	viewEmployeeSalary: "view-employee-salary",
	// finance-Vendor
	vendorRates: "vendor-rates",
	addVendorRates: "add-vendor-rates",
	editVendorRates: "edit-vendor-rates",
	viewVendorRates: "view-vendor-rates",
	// client-rates
	clientRates: "client-rates",
	addClientRates: "add-client-rates",
	editClientRates: "edit-client-rates",
	viewClientRates: "view-client-rates",

	// invoices
	invoices: "invoices",
	addInvoices: "invoices/add",
	editInvoices: "invoices/edit",
	viewInvoices: "invoices/view",

	// payment
	payment: "payment",
	addPayment: "payment/add",
	addPaymentExtra: "company/upload",
	editPayment: "payment/edit",
	viewPayment: "payment/view",
	// projectMgmt
	projectMgmt: "project-management",
	addProjectMgmt: "add-project-management",
	viewProjectMgmt: "view-project-management",
	editProjectMgmt: "edit-project-management",
	// client team member
	clientTeammember: "client-team-member",
	editClientTeammember: "edit-client-team-member",
	viewClientTeammember: "view-client-team-member",

	// teamMember
	teamMember: "team-member",
	addNewTeamMember: "add-new-team-member",
	editTeamMember: "edit-team-member",
	viewTeamMember: "view-team-member",

	// admin
	tsApproval: "timesheet-approval",
	// timesheet
	timesheet: "timesheet",
	timesheetCreate: "add-timesheet",
	timesheetEdit: "edit-timesheet",
	// timesheet-details
	timeSheetDetails: "timesheet-details",
	//teammember-client
	createTeamMemberClient: "create-team-member-client",
	editTeamMemberClient: "edit-team-member-client",
	viewTeamMemberClient: "view-team-member-client",
	//teammember-vendor
	createTeamMemberVendor: "create-team-member-vendor",
	editTeamMemberVendor: "edit-team-member-vendor",
	viewTeamMemberVendor: "view-team-member-vendor",

	// client
	client: "client",
	clientDetails: "client-details",

	// vendor
	vendor: "vendor",
	addVendor: "add-vendor",
	editVendor: "edit-vendor",
	viewVendor: "view-vendor",
	vendorDetails: "vendor-details",

	// vendor payment
	vendorHourlyReport: "vendor-hourly-report",

	// company
	company: "company",
	// department
	department: "department",
	addDepartment: "add-department",
	editDepartment: "edit-department",
	viewDepartment: "view-department",
	// applications
	applications: "applications",
	addAppl: "add-application",
	editAppl: "edit-application",
	viewAppl: "view-application",
	// roles
	roles: "roles",
	addRole: "add-roles",
	viewRole: "view-roles",
	editRole: "edit-roles",
	// ApplsScreen
	applsScreen: "applications-screen",
	addApplsScreen: "add-applications-screen",
	viewApplsScreen: "view-applications-screen",
	editApplsScreen: "edit-applications-screen",
	// AuthenticationRoles
	authenticationRoles: "authentication-roles",
	addAuthenticationRoles: "add-authentication-roles",
	viewAuthenticationRoles: "view-authentication-roles",
	editAuthenticationRoles: "edit-authentication-roles",
	// ScreenRoleMapping
	screenRoleMapping: "screen-role-mapping",
	addScreenRoleMapping: "add-screen-role-mapping",
	viewScreenRoleMapping: "view-screen-role-mapping",
	editScreenRoleMapping: "edit-screen-role-mapping",
	// ScreenFieldRoleMapping
	screenFieldRoleMapping: "screen-field-role-mapping",
	addScreenFieldRoleMapping: "add-screen-field-role-mapping",
	viewScreenFieldRoleMapping: "view-screen-field-role-mapping",
	editScreenFieldRoleMapping: "edit-screen-field-role-mapping",
	// Categories
	categories: "task-categories",
	// addCategories: "add-task-categories",
	// editCategories: "edit-task-categories",
	// viewCategories: "view-task-categories",

	// timesheet resource
	timeSheetResource: "timesheet-resource",
	addTimeSheetResource: "add-timesheet-resource",
	editTimeSheetResource: "edit-timesheet-resource",
	editTimeSheetResourceDuplicate: "edit-timesheet-resource-duplicate",
	editTimeSheetDuplicate: "edit-timesheet-duplicate",
	viewTimeSheetResource: "view-timesheet-resource",
	duplicateTimeSheetResource: "duplicate-timesheet-resource",
	//auth
	login: "/login",
	signUp: "/signup",
	confirmSignUp: "/confirm-signup",
	verifyEmail: "verify-email",
	forgotPassword: "/forgot-password",
	changePassword: "/change-password",
	completedNewPass: "/complete-new-password",
	accountRecovery: "/account-recovery",

	//associates
	associates: "associates",

	//leave
	demoLeave: "leave-demo",

	//attendance
	attendanceMember: "attendance-member",
	attendanceManager: "attendance-manager",
};

export const pages: any[] = [
	//COUNTRY CONFIG-------------------------------------
	{
		name: screenCode.countryConfig,
		path: `/${ROUTES.countryConfig}`,
		component: CountryConfig,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.countryConfig,
		path: `/${ROUTES.countryConfig}/:paramsTab`,
		component: CountryConfig,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.countryConfig,
		path: `/${ROUTES.countryConfig}/${COUNTRY_CONFIG_TAB.BASIC_INFORMATION}/:tabAction`,
		component: CountryConfig,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.countryConfig,
		path: `/${ROUTES.countryConfig}/${COUNTRY_CONFIG_TAB.EMPLOYEE_TYPES}/:tabAction`,
		component: CountryConfig,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.countryConfig,
		path: `/${ROUTES.countryConfig}/:paramsTab/:tabAction`,
		component: CompanyResourceTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.countryConfig,
		path: `/${ROUTES.countryConfig}/:paramsTab/:tabAction/:tabId`,
		component: CompanyResourceTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	//CLIENT-------------------------------------
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}`,
		component: ClientPages,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.ADD}`,
		component: ClientCreate,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.VIEW}/:clientId`,
		component: ClientDetails,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.VIEW}/:clientId/:clientTab`,
		component: ClientDetails,
		action: TAB_ACTIONS.VIEW,
	},
	// EDIT-------
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.EDIT}/:clientId`,
		component: ClientDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab`,
		component: ClientDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.ADD}`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.VIEW}`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.VIEW}/:tabId`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.EDIT}`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.client}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.EDIT}/:tabId`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	//VENDOR------------------------------------------------
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.vendor}`,
		component: Vendors,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.vendor}/${ACTIONS_BASIC.ADD}`,
		component: VendorCreate,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.vendor}/${ACTIONS_BASIC.EDIT}/:vendorId`,
		component: VendorDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.vendor}/${ACTIONS_BASIC.EDIT}/:vendorId/:paramsTab`,
		component: VendorDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.vendor}/${ACTIONS_BASIC.EDIT}/:vendorId/payment/add`,
		component: PaymentCreatePage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.vendor}/${ACTIONS_BASIC.EDIT}/:vendorId/payment/:tabAction`,
		component: PaymentTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.vendor}/${ACTIONS_BASIC.VIEW}/:vendorId`,
		component: VendorDetails,
		action: TAB_ACTIONS.VIEW,
	},
	// COMPANY------------------------------------------
	{
		name: screenCode.companyCode,
		path: `/`,
		component: CompanyInfoPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}`,
		component: CompanyInfoPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab`,
		component: CompanyInfoPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab/:paramsAction`,
		component: CompanyTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab/:paramsAction/:paramsId`,
		// component: CompanyTransfer,
		action: TAB_ACTIONS.LIST,
	},
	// ---------------------PAYMENT---------------------------
	{
		name: screenCode.payment,
		path: `/${ROUTES.payment}`,
		component: PaymentPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.payment,
		path: `/${ROUTES.addPayment}`,
		component: PaymentCreatePage,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.payment,
		path: `/${ROUTES.editPayment}`,
		component: PaymentTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.payment,
		path: `/${ROUTES.viewPayment}`,
		component: PaymentTransferPage,
		action: TAB_ACTIONS.VIEW,
	},
	// ------------------------------------------------
	{
		name: screenCode.timeSheetCode,
		path: `/${ROUTES.timeSheetDetails}/:timeSheetId`,
		component: TimeSheetDetail,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.dashBoard,
		path: `/${ROUTES.dashboard}`,
		component: Dashboard,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.dashboardVendor,
		path: `/${ROUTES.dashboardVendor}`,
		component: DashboardVendorPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.dashboardClient,
		path: `/${ROUTES.dashboardClient}`,
		component: DashboardClientPage,
		action: TAB_ACTIONS.LIST,
	},

	//rbac
	{
		name: screenCode.rbac,
		path: `/${ROUTES.rbac}`,
		component: Rbac,
		action: TAB_ACTIONS.LIST,
	},
	//pto
	{
		name: screenCode.pto,
		path: `/${ROUTES.pto}`,
		component: Pto,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.pto,
		path: `/${ROUTES.addPto}`,
		component: PtoDetails,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.pto,
		path: `/${ROUTES.editPto}`,
		component: PtoDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.pto,
		path: `/${ROUTES.viewPto}`,
		component: PtoDetails,
		action: TAB_ACTIONS.VIEW,
	},
	//timesheet resource
	{
		name: screenCode.timeSheetResource,
		path: `/${ROUTES.timeSheetResource}`,
		component: TimeSheetResourcePage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.timeSheetResource,
		path: `/${ROUTES.timeSheetResource}`,
		component: TimeSheetResourcePage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.timeSheetResource,
		path: `/${ROUTES.timeSheetResource}/${ACTIONS_BASIC.ADD}`,
		component: TimeSheetResourceCreatePage,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.timeSheetResource,
		path: `/${ROUTES.timeSheetResource}/${ACTIONS_BASIC.DUPLICATE}/:timeSheetId`,
		component: TimeSheetResourceDuplicatePage,
		action: TAB_ACTIONS.DUPLICATE,
	},
	{
		name: screenCode.timeSheetResource,
		path: `/${ROUTES.timeSheetResource}/${ACTIONS_BASIC.EDIT}/:timeSheetId`,
		component: TimeSheetResourcePageDetail,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.timeSheetResource,
		path: `/${ROUTES.editTimeSheetResourceDuplicate}/:timeSheetId`,
		component: TimeSheetResourcePageDetailDuplicate,
		action: TAB_ACTIONS.EDIT_DUPLICATE,
	},
	{
		name: screenCode.timeSheetCode,
		path: `/${ROUTES.editTimeSheetDuplicate}/:timeSheetId`,
		component: TimeSheetDuplicateDetailsPage,
		action: TAB_ACTIONS.EDIT_DUPLICATE,
	},
	{
		name: screenCode.timeSheetResource,
		path: `/${ROUTES.timeSheetResource}/${ACTIONS_BASIC.VIEW}/:timeSheetId`,
		component: TimeSheetResourcePageDetail,
		action: TAB_ACTIONS.VIEW,
	},

	//EMPLOYEE SALARIES--------------------------------
	{
		name: screenCode.employeeSalaries,
		path: `/${ROUTES.employeeSalaries}`,
		component: EmpSalariesPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.employeeSalaries,
		path: `/${ROUTES.viewEmployeeSalary}`,
		component: EditEmpSalaryDetails,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.employeeSalaries,
		path: `/${ROUTES.editEmployeeSalary}`,
		component: EditEmpSalaryDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.employeeSalaries,
		path: `/${ROUTES.editEmployeeSalary}/:marginAction`,
		component: EditEmpSalaryDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.employeeSalaries,
		path: `/${ROUTES.editEmployeeSalary}/:marginAction/:paramsId`,
		component: EditEmpSalaryDetails,
		action: TAB_ACTIONS.EDIT,
	},

	//INVOICES
	{
		name: screenCode.invoices,
		path: `/${ROUTES.invoices}`,
		component: InvoicesPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.invoices,
		path: `/${ROUTES.addInvoices}`,
		component: InvoicesCreatePage,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.invoices,
		path: `/${ROUTES.viewInvoices}`,
		component: InvoicesTransferPage,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.invoices,
		path: `/${ROUTES.editInvoices}`,
		component: InvoicesTransferPage,
		action: TAB_ACTIONS.EDIT,
	},

	//Vendor Rates
	{
		name: screenCode.vendorRates,
		path: `/${ROUTES.vendorRates}`,
		component: VendorRates,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.vendorRates,
		path: `/${ROUTES.editVendorRates}`,
		component: VendorRatesDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.vendorRates,
		path: `/${ROUTES.editVendorRates}/:marginAction`,
		component: VendorRatesDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.vendorRates,
		path: `/${ROUTES.editVendorRates}/:marginAction/:paramsId`,
		component: VendorRatesDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.vendorRates,
		path: `/${ROUTES.viewVendorRates}`,
		component: VendorRatesDetails,
		action: TAB_ACTIONS.VIEW,
	},
	//Client Rates
	{
		name: screenCode.clientRates,
		path: `/${ROUTES.clientRates}`,
		component: ClientRates,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.clientRates,
		path: `/${ROUTES.editClientRates}`,
		component: ClientRatesDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientRates,
		path: `/${ROUTES.viewClientRates}`,
		component: ClientRatesDetails,
		action: TAB_ACTIONS.VIEW,
	},
	//communications
	{
		name: screenCode.communications,
		path: `/${ROUTES.communications}`,
		component: Communications,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.communications,
		path: `/${ROUTES.addTemplate}`,
		component: AddTemplate,
		action: TAB_ACTIONS.ADD,
	},
	//recruitment
	{
		name: screenCode.openPositions,
		path: `/${ROUTES.openPositions}`,
		component: OpenPositionsPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.openPositions,
		path: `/${ROUTES.addOpenPositions}`,
		component: OpenPositionsCreatePage,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.openPositions,
		path: `/${ROUTES.editOpenPositions}/:paramsId`,
		component: OpenPositionsUpdatePage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.openPositions,
		path: `/${ROUTES.viewOpenPositions}/:paramsId`,
		component: OpenPositionsViewPage,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.prospectiveClients,
		path: `/${ROUTES.prospectiveClients}`,
		component: ProspectiveClientsPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.prospectiveAssociates,
		path: `/${ROUTES.prospectiveAssociates}`,
		component: ProspectiveAssociatesPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.prospectiveVendors,
		path: `/${ROUTES.prospectiveVendors}`,
		component: ProspectiveVendorsPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.mailbox,
		path: `/${ROUTES.mailbox}`,
		component: MailboxPage,
		action: TAB_ACTIONS.LIST,
	},
	//leave
	{
		name: screenCode.openPositions,
		path: `/${ROUTES.demoLeave}`,
		component: LeavePage,
		action: TAB_ACTIONS.LIST,
	},
	//communications
	{
		name: screenCode.report,
		path: `/${ROUTES.report}`,
		component: Report,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.attendanceReport,
		path: `/${ROUTES.attendanceReport}`,
		component: AttendanceReportPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.attendanceReport,
		path: `/${ROUTES.attendanceReport}/:paramsTab`,
		component: AttendanceReportPage,
		action: TAB_ACTIONS.LIST,
	},

	//resource
	{
		name: screenCode.companyResource,
		path: `/${ROUTES.companyResource}`,
		component: CompanyResourcePage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyResource,
		path: `/${ROUTES.companyResource}/:paramsTab`,
		component: CompanyResourcePage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyResource,
		path: `/${ROUTES.companyResource}/:paramsTab/:tabAction`,
		component: CompanyResourceTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyResource,
		path: `/${ROUTES.companyResource}/:paramsTab/:tabAction/:tabId`,
		component: CompanyResourceTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	//member-details
	{
		name: screenCode.memberDetails,
		path: `/${ROUTES.memberDetails}`,
		component: TeamMemberDetails,
		action: TAB_ACTIONS.LIST,
	},
	// Assignments
	{
		name: screenCode.assignments,
		path: `/${ROUTES.assignments}`,
		component: AssignmentsPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.assignments,
		path: `/${ROUTES.addAssignments}`,
		component: AssignmentDetails,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.assignments,
		path: `/${ROUTES.editAssignments}`,
		component: AssignmentDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.assignments,
		path: `/${ROUTES.viewAssignments}`,
		component: AssignmentDetails,
		action: TAB_ACTIONS.VIEW,
	},

	// application screen // rbac
	{
		name: screenCode.rbac,
		path: `/${ROUTES.addApplsScreen}`,
		component: CreateApplsScreen,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.viewApplsScreen}`,
		component: ApplsScreenDetails,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.editApplsScreen}`,
		component: ApplsScreenDetails,
		action: TAB_ACTIONS.EDIT,
	},
	//authenticationRoles // rbac
	{
		name: screenCode.rbac,
		path: `/${ROUTES.addAuthenticationRoles}`,
		component: AuthenticationRolesDetails,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.viewAuthenticationRoles}`,
		component: AuthenticationRolesDetails,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.editAuthenticationRoles}`,
		component: AuthenticationRolesDetails,
		action: TAB_ACTIONS.EDIT,
	},
	// ScreenRoleMapping //rbac
	{
		name: screenCode.rbac,
		path: `/${ROUTES.addScreenRoleMapping}`,
		component: CreateScreenRoleMapping,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.addScreenRoleMapping}/:screenCode`,
		component: CreateScreenRoleMapping,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.viewScreenRoleMapping}`,
		component: ScreenRoleMappingDetails,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.editScreenRoleMapping}`,
		component: ScreenRoleMappingDetails,
		action: TAB_ACTIONS.EDIT,
	},
	// ScreenFieldRoleMapping //rbac
	{
		name: screenCode.rbac,
		path: `/${ROUTES.addScreenFieldRoleMapping}`,
		component: CreateScreenFieldRoleMapping,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.viewScreenFieldRoleMapping}`,
		component: ScreenFieldRoleMappingDetails,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.rbac,
		path: `/${ROUTES.editScreenFieldRoleMapping}`,
		component: ScreenFieldRoleMappingDetails,
		action: TAB_ACTIONS.EDIT,
	},

	//PROJECT
	{
		name: screenCode.companyResource,
		path: `/${ROUTES.projectMgmt}/add`,
		component: ProjectDetails,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.companyResource,
		path: `/${ROUTES.projectMgmt}/:paramAction/:paramId`,
		component: ProjectDetails,
		action: TAB_ACTIONS.LIST,
	},
	//CLIENT TEAM MEMBER
	{
		name: screenCode.clientTeamMember,
		path: `/${ROUTES.clientTeammember}`,
		component: ClientTeamMember,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.clientTeamMember,
		path: `/${ROUTES.viewClientTeammember}`,
		component: AssignmentDetails,
		action: TAB_ACTIONS.VIEW,
	},

	//TEAM MEMBER
	{
		name: screenCode.teamMemberCode,
		path: `/${ROUTES.teamMember}`,
		component: TeamMember,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.teamMemberCode,
		path: `/${ROUTES.teamMember}/:departmentName/:employeeType/:memberType`,
		component: TeamMember,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.teamMemberCode,
		path: `/${ROUTES.teamMember}/${ACTIONS_BASIC.ADD}`,
		component: AddNewTeamMember,
		action: TAB_ACTIONS.ADD,
	},
	{
		name: screenCode.teamMemberCode,
		path: `/${ROUTES.teamMember}/${ACTIONS_BASIC.EDIT}`,
		component: TeamMemberDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.teamMemberCode,
		path: `/${ROUTES.teamMember}/${ACTIONS_BASIC.VIEW}`,
		component: TeamMemberDetails,
		action: TAB_ACTIONS.VIEW,
	},

	//TS APPROVAL
	{
		name: screenCode.tsApprovalCode,
		path: `/${ROUTES.tsApproval}`,
		component: TSApproval,
		action: TAB_ACTIONS.LIST,
	},

	//TIMESHEET
	{
		name: screenCode.timeSheetCode,
		path: `/${ROUTES.timesheet}`,
		component: TimeSheet,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.timeSheetCode,
		path: `/${ROUTES.timesheetCreate}`,
		component: TimeSheetCreatePage,
		action: TAB_ACTIONS.ADD,
	},
	//TEAMMEMBER CLIENT
	{
		name: screenCode.clientCode,
		path: `/${ROUTES.createTeamMemberClient}`,
		component: CreateTeamMemberSub,
		action: TAB_ACTIONS.ADD,
	},
	//TEAMMEMBER VENDOR
	{
		name: screenCode.vendorCode,
		path: `/${ROUTES.createTeamMemberVendor}`,
		component: CreateTeamMemberSub,
		action: TAB_ACTIONS.ADD,
	},

	//CLIENT DETAILS FOR CLIENT ADMIN
	{
		name: screenCode.clientDetails,
		path: `/${ROUTES.clientDetails}`,
		component: ClientDetails,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.clientDetails,
		path: `/${ROUTES.clientDetails}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab`,
		component: ClientDetails,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientDetails,
		path: `/${ROUTES.clientDetails}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.ADD}`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientDetails,
		path: `/${ROUTES.clientDetails}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.EDIT}`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientDetails,
		path: `/${ROUTES.clientDetails}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.VIEW}`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientDetails,
		path: `/${ROUTES.clientDetails}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.EDIT}/:tabId`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.EDIT,
	},
	{
		name: screenCode.clientDetails,
		path: `/${ROUTES.clientDetails}/${ACTIONS_BASIC.EDIT}/:clientId/:clientTab/${ACTIONS_BASIC.VIEW}/:tabId`,
		component: ClientTransferPage,
		action: TAB_ACTIONS.VIEW,
	},
	{
		name: screenCode.vendorDetails,
		path: `/${ROUTES.vendorDetails}`,
		component: VendorDetails,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.vendorDetails,
		path: `/${ROUTES.vendorDetails}/:paramsTab`,
		component: VendorDetails,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.vendorDetails,
		path: `/${ROUTES.vendorDetails}/${ROUTES.payment}/add`,
		component: PaymentCreatePage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.vendorDetails,
		path: `/${ROUTES.vendorDetails}/${ROUTES.payment}/:tabAction`,
		component: PaymentTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	// -------------------------------------------------------------

	//CATEGORIES
	// {
	// 	name: screenCode.companyResource,
	// 	path: `/${ROUTES.categories}/add`,
	// 	component: AddCategories,
	// 	action: TAB_ACTIONS.LIST,
	// },
	// {
	// 	name: screenCode.companyResource,
	// 	path: `/${ROUTES.categories}/edit/:paramId`,
	// 	component: CategoriesDetails,
	// 	action: TAB_ACTIONS.EDIT,
	// },
	// {
	// 	name: screenCode.companyResource,
	// 	path: `/${ROUTES.categories}/view/:paramId`,
	// 	component: CategoriesDetails,
	// 	action: TAB_ACTIONS.VIEW,
	// },

	// COMPANY
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}`,
		component: CompanyInfoPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab`,
		component: CompanyInfoPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab/:paramsAction`,
		component: CompanyTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab/:paramsAction/:paramsId`,
		// component: CompanyTransfer,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.timeSheetCode,
		path: `/${ROUTES.timeSheetDetails}/:timeSheetId`,
		component: TimeSheetDetail,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.tsApprovalCode,
		path: `/${ROUTES.timeSheetDetails}/:timeSheetId`,
		component: TimeSheetDetail,
		action: TAB_ACTIONS.LIST,
	},

	// COMPANY
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}`,
		component: CompanyInfoPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab`,
		component: CompanyInfoPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab/:paramsAction`,
		component: CompanyTransferPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.companyCode,
		path: `/${ROUTES.company}/:paramsTab/:paramsAction/:paramsId`,
		// component: CompanyTransfer,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.tsApprovalCode,
		path: `/${ROUTES.timeSheetDetails}/:timeSheetId`,
		component: TimeSheetDetail,
		action: TAB_ACTIONS.LIST,
	},

	//CATEGORIES
	// {
	// 	name: screenCode.companyResource,
	// 	path: `/${ROUTES.categories}/add`,
	// 	component: AddCategories,
	// 	action: TAB_ACTIONS.LIST,
	// },
	// {
	// 	name: screenCode.companyResource,
	// 	path: `/${ROUTES.categories}/:paramAction/:paramId`,
	// 	component: CategoriesDetails,
	// 	action: TAB_ACTIONS.LIST,
	// },
	{
		name: screenCode.confirmEmail,
		path: `/${ROUTES.verifyEmail}`,
		component: VerifyEmailForm,
		action: TAB_ACTIONS.LIST,
	},
	// --------------------- ATTENDANCE ---------------------------
	{
		name: screenCode.attendanceMember,
		path: `/${ROUTES.attendanceMember}`,
		component: AttendanceMemberPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.attendanceManager,
		path: `/${ROUTES.attendanceManager}`,
		component: AttendanceManagerPage,
		action: TAB_ACTIONS.LIST,
	},
	{
		name: screenCode.attendanceManager,
		path: `/${ROUTES.attendanceManager}/:paramsTab`,
		component: AttendanceManagerPage,
		action: TAB_ACTIONS.LIST,
	},
];

export const authPages: Page[] = [
	{
		name: screenCode.login,
		path: ROUTES.login,
		component: Login,
		isAuth: false,
	},
	{
		name: screenCode.signUp,
		path: ROUTES.signUp,
		component: SignUp,
		isAuth: false,
	},
	{
		name: screenCode.confirmSignUp,
		path: ROUTES.confirmSignUp,
		component: ConfirmSignUp,
		isAuth: false,
	},
	{
		name: screenCode.forgotPassword,
		path: ROUTES.forgotPassword,
		component: ForgotPassword,
		isAuth: false,
	},
	{
		name: screenCode.changePassword,
		path: ROUTES.changePassword,
		component: ChangePassword,
		isAuth: false,
	},
	{
		name: screenCode.completedNewPass,
		path: ROUTES.completedNewPass,
		component: CompletedNewPass,
		isAuth: false,
	},
];

export type PageName = typeof pages[number]["name"];
export type PageKey = typeof pages[number]["key"];
export type PagePath = typeof pages[number]["path"];

export const getPageByName = (name: PageName): Page =>
	pages?.find((obj) => obj.name === name) || {};
export const getPageByPath = (path: string): Page =>
	pages?.find((obj: { path: string }) => obj.path === path) || {};
export const getPathByName = (name: PageName): PagePath | "" =>
	pages?.find((obj) => obj.name === name)?.path || "";
export const getNameByPath = (path: string): PageName | "" =>
	pages?.find((obj) => obj.path === path)?.name || "";
export const getKeyByName = (name: PageName): PageKey | "" =>
	pages?.find((obj) => obj.name === name)?.key || "";
export const getKeyByPath = (path: string): PageKey | "" =>
	pages?.find((obj) => obj.key === path)?.key || "";
export const getNameByKey = (key: PageKey): PageName | "" =>
	pages?.find((obj) => obj.key === key)?.name || "";
export const getTabIndexByPath = (path: string): Tabs | false =>
	pages?.find((obj) => obj.path === path)?.tabIndex ?? false;
export const getTabIndexByName = (name: PageName): Tabs | false =>
	pages?.find((obj) => obj.name === name)?.tabIndex ?? false;

export default pages;
