import { createContext, useContext, useEffect, useMemo, useState } from "react";
export const HolidayContext = createContext<any>({});


interface IHolidayContextProvider {
  holiday: any,
  setHoliday: (value: any) => any,
  holidays: any,
  setHolidays: (value: any) => any,
  isEmptyHoliday: any,
  setIsEmptyHoliday: (value: any) => any,
  workingDays: any,
  setWorkingDays: (value: any) => any,
  isLoading: boolean,
  setIsLoading: (value: boolean) => boolean,
}

export const HolidayContextProvider = ({ children }: any) => {
  //holiday
  const [holiday, setHoliday] = useState<any>({});
  const [holidays, setHolidays] = useState<any>([]);
  const [isEmptyHoliday, setIsEmptyHoliday] = useState<any>(true);

  const [workingDays, setWorkingDays] = useState<any>([]);

  const memoizedValue = useMemo(
		() => ({
			holiday,
			setHoliday,
			holidays,
			setHolidays,
			isEmptyHoliday,
			setIsEmptyHoliday,
			workingDays,
			setWorkingDays,
		}),
		[holiday, holidays, isEmptyHoliday, workingDays]
	);
  // prevent memory leak
  // const [didMount, setDidMount] = useState(false);
  // useEffect(() => {
  //   setDidMount(true);
  //   return () => setDidMount(false);
  // }, []);

  // if (!didMount) {
  //   return null;
  // }

  return (
    <HolidayContext.Provider value={memoizedValue}>
      {children}
    </HolidayContext.Provider>
  );
};

export const useHolidayContext = (): IHolidayContextProvider => useContext(HolidayContext);
