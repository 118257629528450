import { CircularProgress, MenuItem } from "@mui/material";
import makeRequest from "common/api/makeRequest";
import { useCallback } from "react";
import { applActionCreators } from "timesheets/api/companyActions";
import { roleActionCreators } from "timesheets/api/roleActions";
import { roleMgmtActionCreators } from "timesheets/api/roleMgmtAction";
import { timeSheetActionCreators } from "timesheets/api/timeSheetActions";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { checkAuthRole } from "timesheets/utils";
import { AUTH_ROLE_CODE, DELETE_SUCCESS, NO_RECORDS_FOUND, SNR_APPS } from "timesheets/utils/constant";

export const MemberUtils = () => {
  const { REPORTING_MGR, RESOURCE_MGR, CLIENT_REPORTING_MGR } = AUTH_ROLE_CODE;
  const context = useTimeSheetContext();
  const {
		members,
		companyDepartmentInformation,
		vendors,
		clients,
		authenticationRoles,
		roles,
		appInfo,
		projectCode,
		clientManagers,
		membersForClient,
		teamAssociate: { companyId },
	} = context;

  const snrId = SNR_APPS;

  const {
    actionCreators: {
      getCompanyDepartment,
      getMember,
      getAppForCompany,
      getExternalClientManagersForCompany,
    },
  } = applActionCreators();

  const {
    actionCreators: { getVendors, getClients, getProjectCode },
  } = timeSheetActionCreators();

  const {
		actionCreators: {
			getAuthenticationRoles,
			createAuthenticationRoles,
			updateAuthenticationRoles,
			deleteAuthenticationRoles,
		},
	} = roleMgmtActionCreators();

  const {
    actionCreators: { getRoles },
  } = roleActionCreators();

  const fetchTeamMember = useCallback(
		(setMembersLoading, payload?) => {
			setMembersLoading(true);
			makeRequest(
				() => {
					return getMember({ companyId: payload.companyId || companyId });
				},
				(data: any) => {
					if (data.status === NO_RECORDS_FOUND) {
						context.setMembers([]);
					} else {
						context.setMembers(data);
					}
					setMembersLoading(false);
				},
				(err) => {
					setMembersLoading(false);
				}
			);
		},
		[getMember]
	);

	const renderReportingManager = (membersloading: boolean) => {
		if (membersloading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }}>
					<CircularProgress />
				</MenuItem>
			);
		} else {
			const reportingManagerList = members.filter(
				(item) =>
					checkAuthRole(REPORTING_MGR, item?.authRoleList) || item.authRoleCode === REPORTING_MGR
			);
			if (!reportingManagerList.length) {
				return <MenuItem>Reporting managers are not exist !</MenuItem>;
			} else {
				return reportingManagerList.map((item, index) => {
					return (
						<MenuItem
							key={index}
							value={`{"id":"${item.teamAssociateId}","name":"${
								item.firstName ? `${item.firstName} ${item.lastName}` : item.email
							}"}`}
						>
							{item.firstName} {item.lastName} - {item.email}
						</MenuItem>
					);
				});
			}
		}
	};

	const renderResourceManager = (loading: boolean) => {
		if (loading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }}>
					<CircularProgress />
				</MenuItem>
			);
		} else {
			const resourceManagerList = members.filter(
				(item: { authRoleList: any; authRoleCode: string }) =>
					checkAuthRole(RESOURCE_MGR, item?.authRoleList) || item?.authRoleCode === RESOURCE_MGR
			);
			if (!resourceManagerList?.length) {
				return <MenuItem>Resource managers are not exist !</MenuItem>;
			} else {
				return resourceManagerList?.map((item, index) => {
					return (
						<MenuItem key={index} value={item.teamAssociateId}>
							{item.firstName} {item.lastName} - {item.email}
						</MenuItem>
					);
				});
			}
		}
	};

	const renderTeamMemberForAssignment = (membersloading: boolean) => {
		if (membersloading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }} value={""}>
					<CircularProgress />
				</MenuItem>
			);
		} else {
			if (!members.length) {
				return <MenuItem value={""}>Members are not exist !</MenuItem>;
			} else {
				return members.map((item, index) => {
					return (
						<MenuItem key={index} value={item?.teamAssociateId}>
							{item.firstName} {item.lastName} - {item.email}
						</MenuItem>
					);
				});
			}
		}
	};

	const renderTeamMemberInvoiceLine = (membersloading: boolean) => {
		if (membersloading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }} value={""}>
					<CircularProgress />
				</MenuItem>
			);
		} else {
			if (!membersForClient.length) {
				return <MenuItem value={""}>Members are not exist !</MenuItem>;
			} else {
				return membersForClient.map((item, index) => {
					return (
						<MenuItem key={index} value={item?.teamAssociateId}>
							{item.firstName} {item.lastName} - {item.email}
						</MenuItem>
					);
				});
			}
		}
	};

	const fetchVendors = useCallback(
		(setVendorsLoading) => {
			setVendorsLoading(true);
			makeRequest(
				() => {
					return getVendors({ companyId });
				},
				(data) => {
					if (data.status === NO_RECORDS_FOUND) {
						context.setVendors([]);
					} else {
						context.setVendors(data);
					}
					setVendorsLoading(false);
				},
				() => {
					setVendorsLoading(false);
				}
			);
		},
		[getVendors]
	);

	const renderVendors = (vendorsLoading) => {
		if (vendorsLoading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }}>
					<CircularProgress />
				</MenuItem>
			);
		} else {
			if (!vendors.length) {
				return <MenuItem>Vendors are not exist !</MenuItem>;
			} else {
				return vendors.map((item, index) => {
					return (
						<MenuItem value={`{"id":"${item.vendorId}","name":"${item.vendorName}"}`} key={index}>
							{item.vendorName}
						</MenuItem>
					);
				});
			}
		}
	};

	const fetchCompanyDepartment = useCallback(
		(setDeptLoading) => {
			setDeptLoading(true);
			makeRequest(
				() => {
					return getCompanyDepartment({
						companyId,
					});
				},
				(data: any) => {
					setDeptLoading(false);
					if (data.length) return context.setCompanyDepartmentInformation(data);
				},
				(err: any) => {
					setDeptLoading(false);
				}
			);
		},
		[getCompanyDepartment]
	);

	const renderCompanyDepartment = (deptLoading: boolean) => {
		if (deptLoading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }}>
					<CircularProgress />
				</MenuItem>
			);
		}

		if (!companyDepartmentInformation.length) {
			return <MenuItem>Departments are not exist !</MenuItem>;
		}

		return companyDepartmentInformation.map(
			(item: { companyDeptId: string; departmentName: string }, index: number) => {
				return (
					<MenuItem
						value={`{"id":"${item.companyDeptId}","name":"${item.departmentName}"}`}
						key={index}
					>
						{item.departmentName}
					</MenuItem>
				);
			}
		);
	};

	const fetchClients = useCallback(
		(setClientsLoading?) => {
			setClientsLoading && setClientsLoading(true);
			makeRequest(
				() => {
					return getClients({ companyId });
				},
				(data: any) => {
					if (data.status === NO_RECORDS_FOUND) {
						context.setClients([]);
					} else {
						const clientIsCompany = data?.find((client) => client.isVisible === false);
						context.setClients(data);
						context.setClientsIsCompany(clientIsCompany);
					}
					setClientsLoading && setClientsLoading(false);
				},
				() => {
					setClientsLoading && setClientsLoading(false);
				}
			);
		},
		[getClients]
	);

	const renderClients = (clientsLoading) => {
		if (clientsLoading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }} key="">
					<CircularProgress />
				</MenuItem>
			);
		} else {
			if (!clients.length) {
				return <MenuItem key="">Clients are not exist !</MenuItem>;
			} else {
				return clients.map((item, index) => {
					return (
						<MenuItem value={item?.clientId} key={index}>
							{item.clientName}
						</MenuItem>
					);
				});
			}
		}
	};

	const fetchClientReportingManager = useCallback(
		(setLoading: any, clientId: string, isSelfCompany: any) => {
			setLoading(true);
			makeRequest(
				() => {
					return getMember({ companyId });
				},
				(data: any) => {
					let result: any;
					if (isSelfCompany === "true" || isSelfCompany === true) {
						result = data?.length
							? data?.filter(
									(item) =>
										item?.authRoleCode === REPORTING_MGR ||
										checkAuthRole(REPORTING_MGR, item?.authRoleList)
							  )
							: [];
					} else {
						result = data?.length
							? data?.filter(
									(item) =>
										item?.clientId === clientId &&
										(item?.authRoleCode === CLIENT_REPORTING_MGR ||
											checkAuthRole(CLIENT_REPORTING_MGR, item?.authRoleList))
							  )
							: [];
					}
					if (data?.status === NO_RECORDS_FOUND) {
						context.setClientManagers([]);
					} else {
						context.setClientManagers(result);
					}
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		},
		[getExternalClientManagersForCompany]
	);

	const fetchAuthRole = useCallback(
		(setAuthRoleLoading) => {
			setAuthRoleLoading(true);
			makeRequest(
				() => {
					return getAuthenticationRoles({ snrId, companyId });
				},
				(data) => {
					if (data.status === NO_RECORDS_FOUND) {
						context.setAuthenticationRoles([]);
					} else {
						context.setAuthenticationRoles(data);
					}
					setAuthRoleLoading(false);
				},
				() => {
					setAuthRoleLoading(false);
				}
			);
		},
		[getAuthenticationRoles]
	);

	const handleCreateAuthenticationRoles = useCallback(
		(setLoading, payload, callbackResult?, callbackError?) => {
			setLoading(true);
			makeRequest(
				() => {
					return createAuthenticationRoles(payload);
				},
				(data) => {
					setLoading(false);
					context.setOpenSnackbarSuccess(true);
					callbackResult && callbackResult(data);
				},
				(error) => {
					setLoading(false);
					context.setOpenSnackbarFail(true);
					callbackError && callbackError(error);
				}
			);
		},
		[createAuthenticationRoles]
	);


	const handleUpdateAuthenticationRoles = useCallback(
		(setLoading, payload, callbackResult?, callbackError?) => {
			setLoading(true);
			makeRequest(
				() => {
					return updateAuthenticationRoles(payload);
				},
				(data) => {
					setLoading(false);
					context.setOpenSnackbarSuccess(true);
					callbackResult && callbackResult(data);
				},
				(error) => {
					setLoading(false);
					context.setOpenSnackbarFail(true);
					callbackError && callbackError(error);
				}
			);
		},
		[updateAuthenticationRoles]
	);

	const handleDeleteAuthRole = useCallback(
		(setLoading, data) => {
			setLoading(true);
			makeRequest(
				() => {
					const _companyId = companyId || "";
					return deleteAuthenticationRoles(_companyId, data.authRoleId);
				},
				(data: any) => {
					setLoading(false);
					if (data.body === DELETE_SUCCESS) {
						context.setOpenSnackbarSuccess(true);
					} else {
						context.setOpenSnackbarFail(true);
					}
					fetchAuthRole(setLoading);
				},
				(err: any) => {
					if (err) {
						setLoading(false);
						context.setOpenSnackbarFail(true);
						fetchAuthRole(setLoading);
					}
				}
			);
		},
		[deleteAuthenticationRoles]
	);

	const renderAuthRole = (authenticationRolesLoading) => {
		if (authenticationRolesLoading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }} key="">
					<CircularProgress />
				</MenuItem>
			);
		} else {
			if (!authenticationRoles.length) {
				return <MenuItem key="">Authentication roles are not exist !</MenuItem>;
			} else {
				return authenticationRoles.map((item, index) => {
					return (
						<MenuItem value={`{"name":"${item.roleName}","code":"${item.roleCode}"}`} key={index}>
							{item.roleName}
						</MenuItem>
					);
				});
			}
		}
	};

	const fetchRoles = useCallback(
		(setApplRoleLoading) => {
			setApplRoleLoading(true);
			makeRequest(
				() => {
					return getRoles({ snrId, companyId });
				},
				(data) => {
					if (data.status === NO_RECORDS_FOUND) {
						context.setRoles([]);
					} else {
						context.setRoles(data);
					}
					setApplRoleLoading(false);
				},
				() => {
					setApplRoleLoading(false);
				}
			);
		},
		[getRoles]
	);

	const fetchApplications = useCallback(
		(setLoading) => {
			setLoading(true);
			makeRequest(
				() => {
					return getAppForCompany({ snrId, companyId });
				},
				(data) => {
					if (data.status === NO_RECORDS_FOUND) {
						context.setAppInfo([]);
					} else {
						context.setAppInfo(data);
					}
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		},
		[getAppForCompany]
	);

	const renderApplications = (loading) => {
		if (loading) {
			return (
				<MenuItem sx={{ justifyContent: "center" }} key="">
					<CircularProgress />
				</MenuItem>
			);
		} else {
			if (!appInfo.length) {
				return <MenuItem key="">Application are not exist !</MenuItem>;
			} else {
				return appInfo.map((item, index) => {
					return (
						<MenuItem
							value={`{"id":"${item.applicationId}","name":"${item.applicationName}"}`}
							key={index}
						>
							{item.applicationName}
						</MenuItem>
					);
				});
			}
		}
	};

	const fetchProjects = useCallback(
		(setLoading) => {
			setLoading(true);
			makeRequest(
				() => {
					return getProjectCode({ snrId, companyId });
				},
				(data) => {
					if (data.status === NO_RECORDS_FOUND) {
						context.setProjectCode([]);
					} else {
						context.setProjectCode(data);
					}
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			);
		},
		[getProjectCode]
	);

  const renderProjects = (loading) => {
    if (loading) {
      return (
        <MenuItem sx={{ justifyContent: "center" }} key="">
          <CircularProgress />
        </MenuItem>
      );
    } else {
      if (!projectCode.length) {
        return <MenuItem key="">Projects are not exist !</MenuItem>;
      } else {
        return projectCode.map((item, index) => {
          return (
            <MenuItem
              value={`{"id":"${item.projectCodeId}","name":"${item.projectName}"}`}
              key={index}
            >
              {item.projectName}
            </MenuItem>
          );
        });
      }
    }
  };

  return {
    fetchTeamMember,
    fetchVendors,
    fetchCompanyDepartment,
    renderReportingManager,
    renderCompanyDepartment,
    renderVendors,
    fetchClients,
    renderClients,
    fetchClientReportingManager,
    fetchAuthRole,
		handleCreateAuthenticationRoles,
		handleUpdateAuthenticationRoles,
    handleDeleteAuthRole,
    renderAuthRole,
    fetchRoles,
    fetchApplications,
    renderApplications,
    fetchProjects,
    renderProjects,
    renderTeamMemberForAssignment,
    renderResourceManager,
    renderTeamMemberInvoiceLine,
  };
};
