import { GridColDef } from "@mui/x-data-grid-pro";
import moment from "moment";

export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const NO_RECORDS_FOUND = "NO_RECORDS_FOUND";
export const UNITED_STATE_CODE = "US";
export const RECORD_ALREADY_EXIST = "RECORD_ALREADY_EXIST";
export const SNR_APPS = "SNR_APPS";

export const DATE_FORMAT = "YYYY-MM-DD";

export const PASSWORD_EXPIRED_NOTIF = "Temporary password has expired";

export const ASSIGNMENT_STATUS = {
  SAVED: "SAVED",
  ACTIVATED: "ACTIVATED",
  CLOSED: "CLOSED"
}

export const ASSIGNMENT_STATUS_LIST = [
  { label: "Saved", value: "SAVED" },
  { label: "Activated", value: "ACTIVATED" },
  { label: "Closed", value: "CLOSED" },
];

export const screenCode = {
  demoLeave: "DEMO_LEAVE",
  countryConfig: "COUNTRY_CONFIG",
  confirmEmail: "CONFIRM_EMAIL",
  teamMemberCode: "TEAM_MEMBER",
  clientTeamMember: "CLIENT_TEAM_MEMBER",
  tsApprovalCode: "TS_APPROVAL",
  timeSheetCode: "TIMESHEETS",
  clientCode: "CLIENTS",
  vendorCode: "VENDORS",
  vendorDetails: "VENDOR_DETAILS",
  companyCode: "COMPANY_MGMT",
  assignments: "ASSIGNMENT",
  dashBoard: "DASHBOARD",
  dashboardClient: "DASHBOARD_CLIENT",
  dashboardVendor: "DASHBOARD_VENDOR",
  memberDetails: "MEMBER_DETAILS",
  rbac: "RBAC",
  companyResource: "COMPANY_RESOURCE",
  communications: "COMMUNICATIONS",
  recruitment: "RECRUITMENT",
  report: "REPORT",
  attendanceReport: "ATTENDANCE_REPORT",
  pto: "PTO",
  employeeSalaries: "EMPLOYEE_SALARIES",
  invoices: "INVOICES",
  vendorRates: "VENDOR_FINANCE",
  clientRates: "CLIENT_FINANCE",
  margins: "MARGINS",
  clientDetails: "CLIENT_DETAILS",
  payment: "PAYMENT",

  timeSheetResource: "TIMESHEETS_RESOURCE",

  login: "LOGIN",
  signUp: "SIGN_UP",
  confirmSignUp: "CONFIRM_SIGN_UP",
  forgotPassword: "FORGOT_PASSWORD",
  changePassword: "CHANGE_PASSWORD",
  completedNewPass: "COMPLETE_NEW_PASS",
  accountRecovery: "ACCOUNT_RECOVERY",

  // recruitment
  openPositions: "OPEN_POSITIONS",
  prospectiveClients: "PROSPECTIVE_CLIENTS",
  prospectiveAssociates: "PROSPECTIVE_ASSOCIATES",
  prospectiveVendors: "PROSPECTIVE_VENDORS",
  mailbox: "MAILBOX",

  // Attendance
  attendanceMember: "ATTENDANCE_MEMBER",
  attendanceManager: "ATTENDANCE_MANAGER",
};

export const ROLES = {
  TEAM_MEMBER: "Team Member",
  REPORTING_MGR: "Reporting Manager",
  COMPANY_ADMIN: "Company Admin",
  RESOURCE_MGR: "Resource Manager",
  CLIENT_REPORTING_MGR: "Client Reporting Manager",
  CLIENT_FINANCE_MGR: "Client Finance Manager",
  VENDOR_REPORTING_MGR: "Vendor Reporting Manager",
  VENDOR_FINANCE_MGR: "Vendor Finance Manager",
};

export const VENDOR_TYPE = {
  ASSOCIATE_VENDOR: "ASSOCIATE_VENDOR",
  REFERRAL_VENDOR: "REFERRAL_VENDOR",
};

export const rolesData = [
  { roleCode: "TEAM_MEMBER", content: "Team Member" },
  { roleCode: "REPORTING_MGR", content: "Reporting Manager" },
  { roleCode: "COMPANY_ADMIN", content: "Company Admin" },
  { roleCode: "RESOURCE_MGR", content: "Resource Manager" },
];

export const AUTH_ROLE_CODE = {
  REPORTING_MGR: "REPORTING_MGR",
  TEAM_MEMBER: "TEAM_MEMBER",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  RESOURCE_MGR: "RESOURCE_MGR",
  FINANCE_ACCOUNT_MGR: "FINANCE_ACCOUNT_MGR",
  CLIENT_REPORTING_MGR: "CLIENT_REPORTING_MGR",
  CLIENT_FINANCE_MGR: "CLIENT_FINANCE_MGR",
  CLIENT_ADMIN: "CLIENT_ADMIN",
  CLIENT_REVIEWER: "CLIENT_REVIEWER",
  VENDOR_REPORTING_MGR: "VENDOR_REPORTING_MGR",
  VENDOR_FINANCE_MGR: "VENDOR_FINANCE_MGR",
  CLIENT_OTHERS: "CLIENT_OTHERS",
  VENDOR_ADMIN: "VENDOR_ADMIN",
};

export const clientAuthRole = [
  {
    roleName: "Client Reporting Manager",
    roleCode: AUTH_ROLE_CODE.CLIENT_REPORTING_MGR,
    isClientManager: true,
  },
  {
    roleName: "Client Finance Manager",
    roleCode: AUTH_ROLE_CODE.CLIENT_FINANCE_MGR,
    isClientManager: true,
  },
  {
    roleName: "Client Admin",
    roleCode: AUTH_ROLE_CODE.CLIENT_ADMIN,
    isClientManager: true,
  },
  {
    roleName: "Client Reviewer",
    roleCode: AUTH_ROLE_CODE.CLIENT_REVIEWER,
    isClientManager: true,
  },
  // {
  //   roleName: "Others",
  //   roleCode: AUTH_ROLE_CODE.CLIENT_OTHERS,
  //   isClientManager: false,
  // },
];

export const vendorAuthRole = [
  {
    roleName: "Vendor Reporting Manager",
    roleCode: AUTH_ROLE_CODE.VENDOR_REPORTING_MGR,
  },
  {
    roleName: "Vendor Finance Manager",
    roleCode: AUTH_ROLE_CODE.VENDOR_FINANCE_MGR,
  },
  {
    roleName: "Vendor Admin",
    roleCode: AUTH_ROLE_CODE.VENDOR_ADMIN,
  },
];

export const AUTH_ROLE_NAME = {
  REPORTING_MGR_NAME: "Reporting Manager",
  TEAM_MEMBER_NAME: "Team Member",
  COMPANY_ADMIN_NAME: "Company Admin",
  CLIENT_REPORTING_MGR_NAME: "Client Reporting Manager",
};

export const COMPANY_TABS = {
  BASIC_INFORMATION: "basic_information",
  TIME_SHEETS_INFORMATION: "time_sheets_information",
  CUSTOM_HOLIDAYS: "custom_holidays",
  WORKING_DAYS: "working_days",
  DEPARTMENTS: "departments",
  DELETE_COMPANY: "delete_company",
  ASSOCIATES: "associates",
  UPLOAD: "upload"
};

export const COUNTRY_CONFIG_TAB = {
  BASIC_INFORMATION: "basic-information",
  EMPLOYEE_TYPES: "employee-types",
  CUSTOM_HOLIDAYS: "custom-holidays",
  WORKING_DAYS: "working-days",
};


export const COMPANY_RESOURCE_TABS = {
  BASIC_INFO: "basic_info",
  TEAM_MEMBER: "team_member",
  PROJECT_MGMT: "project_mgmt",
  APPL_ROLES: "appl_roles",
  TASK_CATES: "task_cates",
  APPLICATION: "application",
  APPLICATION_PHASE: "application_phase",
  BUSINESS_CAPABILITY: "business_capability",
  BUSINESS_CAPABILITY_CATEGORY: "business_capability_category",
  EMPLOYEE_ITEMS: "employee_items",
  SALARY: "salary",
  CURRENCIES: "currencies",
  INVOICES: "invoices",
  COMPENSATION: "compensation",
  COMPENSATION_DETAILS: "compensation_details",
};

export const RBAC_TABS = {
  APPL_SCREEN: "APPL_SCREEN",
  AUTH_ROLES: "AUTH_ROLES",
  SCREEN_ROLE_MAPPING: "SCREEN_ROLE_MAPPING",
  SCREEN_FIELD_ROLE_MAPPING: "SCREEN_FIELD_ROLE_MAPPING",
};

export const PTO_TABS = {
  REQUEST: "REQUEST",
  REVIEW: "REVIEW",
};
export const MARGIN_TYPE = {
  ONE_TIME: "ONE_TIME",
  HOURLY: "HOURLY",
};

export const MARGIN_PARTTERN = {
  FIXED_VALUE: "FIXED_VALUE",
  PERCENTAGE: "PERCENTAGE",
};

//member type
export const W2 = "W2";
export const _1099 = "1099";
export const C2C = "C2C";

// company status

export const COMPANY_STATUS_NEW = "NEW";
export const COMPANY_STATUS_REGISTERED = "REGISTERED";

//time sheet status

export const TS_STATUS = {
  REJECTED: "REJECTED",
  DRAFT: "DRAFT",
  APPROVED: "APPROVED",
  SUBMITTED: "SUBMITTED",
  REVIEWED: "REVIEWED"
};

// list cookie
export const listCookie = [
  "applsRoleName",
  "authRoleName",
  "authRoleCode",
  "companyId",
  "companyStatus",
  "firstName",
  "lastName",
  "managerId",
  "managerName",
  "snrId",
  "teamMemberCreationDate",
  "teamAssociateId",
  "timeSheetDetailsFormat",
  "timeSheetFrequency",
  "typeModeTeamMember",
  "username",
  "currentWorkSpace",
  "email",
];

//COMPANY_TYPE
export const COMPANY_TYPE = {
  END_CLIENT: "END_CLIENT",
  INTEGRATION_PARTNER: "INTEGRATION_PARTNER",
};

//CATEGORY_TYPE
export const PROJECT_CATEGORY_TYPE = {
  BILLABLE: "BILLABLE",
  WAIT: "WAIT",
  NON_BILLABLE: "NON_BILLABLE",
};

export const ACTIONS_BASIC = {
  ADD: "add",
  VIEW: "view",
  EDIT: "edit",
  DELETE: "delete",
  DUPLICATE: "duplicate",
  ARCHIVE: "archive",
  RESTORE: "restore",
  PENDING: "pending",
  RE_ACTIVE: "re active"
};

export const TAB_ACTIONS = {
  LIST: "LIST",
  ADD: "ADD",
  DUPLICATE: "DUPLICATE",
  EDIT: "EDIT",
  EDIT_DUPLICATE: "EDIT_DUPLICATE",
  VIEW: "VIEW",
  DELETE: "DELETE",
  ASSIGN: "ASSIGN",
  DOWNLOAD: "DOWNLOAD",
  APPROVE: "APPROVE",
  REJECT: "REJECT",
  SAVE: "SAVE",
  ARCHIVE: "ARCHIVE",
  SEND_REMINDER: "SEND_REMINDER",
  REVIEW: "REVIEW",
  ADD_REVIEWER: "ADD_REVIEWER",
  EDIT_AND_RESUBMIT: "EDIT_AND_RESUBMIT",
  EDIT_AND_SAVE: "EDIT_AND_SAVE",
  EDIT_AND_APPROVE: "EDIT_AND_APPROVE",
  EDIT_AND_REJECT: "EDIT_AND_REJECT",
  PAID: "PAID",
  ADD_NEW_LINE: "ADD_NEW_LINE",
  SUBMIT_FOR_APPROVAL: "SUBMIT_FOR_APPROVAL",
  SEND_TO_CUSTOMER: "SEND_TO_CUSTOMER",
};


export const SCREEN_ACTION_DEFAULT_FL_CODE = [
  {
    screenCode: screenCode.teamMemberCode,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
      { actionName: "Assign", actionCode: TAB_ACTIONS.ASSIGN },
    ],
  },
  {
    screenCode: screenCode.tsApprovalCode,
    defaultAction: [
      { actionName: "Approve", actionCode: TAB_ACTIONS.APPROVE },
      { actionName: "Reject", actionCode: TAB_ACTIONS.REJECT },
      { actionName: "Send Reminder", actionCode: TAB_ACTIONS.SEND_REMINDER },
      { actionName: "Review", actionCode: TAB_ACTIONS.REVIEW },
    ],
  },
  {
    screenCode: screenCode.clientCode,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
    ],
  },
  {
    screenCode: screenCode.vendorCode,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
    ],
  },
  {
    screenCode: screenCode.companyCode,
    defaultAction: [
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
    ],
  },
  {
    screenCode: screenCode.companyResource,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
      { actionName: "Archive", actionCode: TAB_ACTIONS.ARCHIVE },
    ],
  },
  {
    screenCode: screenCode.assignments,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
      { actionName: "Archive", actionCode: TAB_ACTIONS.ARCHIVE },
      { actionName: "Assign", actionCode: TAB_ACTIONS.ASSIGN },
    ],
  },
  // {
  //   screenCode: screenCode.applicationsCode,
  //   defaultAction: [
  //     { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
  //     { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
  //     { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
  //     { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
  //     { actionName: "Archive", actionCode: TAB_ACTIONS.ARCHIVE },
  //   ],
  // },
  {
    screenCode: screenCode.pto,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
    ],
  },
  {
    screenCode: screenCode.timeSheetResource,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "Duplicate", actionCode: TAB_ACTIONS.DUPLICATE },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "EditDuplicate", actionCode: TAB_ACTIONS.EDIT_DUPLICATE },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
      {
        actionName: "Edit and Resubmit",
        actionCode: TAB_ACTIONS.EDIT_AND_RESUBMIT,
      },
      { actionName: "Edit and Save", actionCode: TAB_ACTIONS.EDIT_AND_SAVE },
      {
        actionName: "Edit and Approve",
        actionCode: TAB_ACTIONS.EDIT_AND_APPROVE,
      },
      {
        actionName: "Edit and Reject",
        actionCode: TAB_ACTIONS.EDIT_AND_REJECT,
      },
    ],
  },
  {
    screenCode: screenCode.clientTeamMember,
    defaultAction: [{ actionName: "Add Reviewer", actionCode: TAB_ACTIONS.ADD_REVIEWER }],
  },
  {
    screenCode: screenCode.invoices,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
      { actionName: "Archive", actionCode: TAB_ACTIONS.ARCHIVE },
      {
        actionName: "Add new invoice line",
        actionCode: TAB_ACTIONS.ADD_NEW_LINE,
      },
      { actionName: "Save", actionCode: TAB_ACTIONS.SAVE },
      {
        actionName: "Submit for Approval",
        actionCode: TAB_ACTIONS.SUBMIT_FOR_APPROVAL,
      },
      { actionName: "Approve", actionCode: TAB_ACTIONS.APPROVE },
      { actionName: "Reject", actionCode: TAB_ACTIONS.REJECT },
      {
        actionName: "Send to customer",
        actionCode: TAB_ACTIONS.SEND_TO_CUSTOMER,
      },
      { actionName: "Paid", actionCode: TAB_ACTIONS.PAID },
    ],
  },
  {
    screenCode: screenCode.payment,
    defaultAction: [
      { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
      { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
      { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
      { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
    ],
  },
  {
    screenCode: screenCode.report,
    defaultAction: [{ actionName: "Download", actionCode: TAB_ACTIONS.DOWNLOAD }],
  },
];

export const SCREEN_ACTION_DEFAULT = [
  { actionName: "Add", actionCode: TAB_ACTIONS.ADD },
  { actionName: "View", actionCode: TAB_ACTIONS.VIEW },
  { actionName: "Edit", actionCode: TAB_ACTIONS.EDIT },
  { actionName: "Delete", actionCode: TAB_ACTIONS.DELETE },
];

export const TIMESHEET_CMMNS = {
  EVENT_CODE: {
    TIMESHEETS: "TIMESHEETS",
  },
  EVENT_CATEGORY: {
    REMINDER: "REMINDER",
  },
};

export const PROJECT_BILLABLE_TYPE = {
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  HOURLY: "HOURLY",
  FIX_BID: "FIX_BID",
};

export const PROJECT_TYPE = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
};

export const objectItemColumn: GridColDef = {
  field: "",
  align: "left",
  headerAlign: "left",
  minWidth: 180,
  flex: 1,
};

export const objectActionColumn: GridColDef = {
  field: "action",
  headerName: "Action",
  minWidth: 90,
  align: "center",
  flex: 0.5,
  headerAlign: "center",
  disableColumnMenu: true,
  sortable: false,
};

export const commentInfoList = {
  APPROVED: { label: "APPROVED", color: "primary" },
  REJECTED: { label: "REJECTED", color: "error" },
  SAVE: { label: "SAVE", color: "secondary" },
  SUBMITTED_FOR_APPROVAL: { label: "SUBMITTED_FOR_APPROVAL", color: "secondary" },
  SEND_TO_CUSTOMER: { label: "SEND_TO_CUSTOMER", color: "secondary" },
  PAID: { label: "PAID", color: "success" },
};

export const handleIsStatus = (data: any) => {
  const arrayCheck = ["APPROVED", "REJECTED", "SAVE", "SUBMITTED_FOR_APPROVAL", "SEND_TO_CUSTOMER", "PAID"] || [];

  return arrayCheck.includes(data);
};

export const handleIsStatusRequired = (data: any) => {
  const arrayCheck = ["REJECTED", "PAID"] || [];

  return arrayCheck.includes(data);
};

export const UNIT_TIME = [
  { value: "HOURLY", label: "Hourly" },
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "BI_WEEKLY", label: "Bi Weekly" },
  { value: "MONTHLY", label: "Monthly" },
  { value: "YEARLY", label: "Yearly" },
];

export const UNIT_FREQUENCY = [
  { value: "WEEKLY", label: "Weekly" },
  { value: "BI_WEEKLY", label: "Bi Weekly" },
  { value: "MONTHLY", label: "Monthly" }
];

export const DaysBetweenDates = (startDate: any, endDate: any) => {
  const date: any = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
  }
  return date;
}