import Cookies from "universal-cookie";
import moment from "moment";

export const getCookie = (name: string): string | undefined => {
	const cookies = new Cookies();
	return cookies.get(name);
};

export const setCookie = (name: string, value: string | undefined, time: any = 0): void => {
	const cookies = new Cookies();
	cookies.set(name, value, {
		// expires: new Date(
		// 	moment()
		// 		.add(time > 0 ? time : 1, "m")
		// 		.format()
		// ),
		sameSite: "strict",
		secure: false,
	});
};

export const removeCookie = (name: string): void => {
	const cookies = new Cookies();
	cookies.remove(name, { sameSite: "strict" });
};
