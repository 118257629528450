export const awsPasswordPolicy = {
    regex:
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
    message:
      "A password minimum length of at least eight characters, an uppercase, a special characters and a number ! ",
  };
  
export const regexEmail = {
	regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
	message: "Invalid email",
};
  