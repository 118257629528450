import { makeStyles } from "@material-ui/core";
import { Box, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";

const useStyles = makeStyles({
  authLayoutwrapper: {
    minHeight: "100vh",
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
  circle1Auth: {
    position: "absolute",
    zIndex: 2,
    top: "-120px",
    left: "-71px",
    width: "706px",
    height: "706px",
    background: "#e9f3fc",
    borderRadius: "50%",
    mixBlendMode: "multiply",
  },
  circle2Auth: {
    position: "absolute",
    zIndex: 3,
    top: "62px",
    left: "524px",
    width: "322px",
    height: "322px",
    background: "#f0fff9",
    borderRadius: "50%",
    mixBlendMode: "multiply",
  },
  circle3Auth: {
    position: "absolute",
    zIndex: 2,
    right: "-84px",
    bottom: "-139px",
    height: "655px",
    width: "655px",
    background: "#FFE6E6",
    borderRadius: "50%",
    mixBlendMode: "multiply",
  },
  authLayoutContent: {
    position: "relative",
    zIndex: 3,
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    marginBottom: "20px",
  },
  logoAuth: {
    fontSize: "37px",
    fontWeight: "bold",
    cursor: "pointer",
    position: "absolute",
    zIndex: 4,
    top: "50px",
    left: "50px",
  },
  logo1Auth: {
    fontFamily: "'KoHo', sans-serif !important",
    color: "#29446C",
  },
  logo2Auth: {
    fontFamily: "'KoHo', sans-serif !important",
    color: "#FF7A00",
  },
});

export const AuthLayout = ({ children }) => {
  const classes = useStyles();
  const context = useTimeSheetContext();
  const history = useHistory();
  const { isAuth } = context;

  // useEffect(() => {
  //   isAuth && history.push(ROUTES.company);
  // }, [isAuth]);

  return (
    <Box className={classes.authLayoutwrapper}>
      <div className={classes.circle1Auth}>
        
      </div>
      <div className={classes.circle2Auth}></div>
      <div className={classes.circle3Auth}></div>
      <div
        className={classes.logoAuth}
        onClick={() =>
          (window.location.href =
            process.env.REACT_APP_A360VIEW_MKT_WEB_URL || "")
        }
      >
        <span className={classes.logo1Auth}>ASSOCIATE</span>
        <span className={classes.logo2Auth}> 360 VIEW</span>
      </div>
      <div className={classes.authLayoutContent}>
        <Container sx={{ height: "100%" }}>
          <Box marginTop="100px">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  maxWidth={{
                    xs: "100%",
                    md: "500px",
                  }}
                >
                  <Typography
                    fontSize={{
                      xs: "1.5em",
                      md: "3em",
                    }}
                    textAlign={{
                      xs: "center",
                      md: "start",
                    }}
                    fontWeight={"600"}
                  >
                    A Secured HRIS Platform
                  </Typography>
                  <Typography
                    fontSize={{
                      xs: "1em",
                      md: "2em",
                    }}
                    textAlign={{
                      xs: "center",
                      md: "start",
                    }}
                  >
                    - FOR ASSOCIATES
                  </Typography>
                  <Typography
                    fontSize={{
                      xs: "1em",
                      md: "2em",
                    }}
                    textAlign={{
                      xs: "center",
                      md: "start",
                    }}
                  >
                    - FOR HR TEAMS
                  </Typography>
                  <Typography
                    fontSize={{
                      xs: "1em",
                      md: "2em",
                    }}
                    textAlign={{
                      xs: "center",
                      md: "start",
                    }}
                  >
                    - FOR FINANCE TEAMS
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box maxWidth={"525px"} margin="0 auto">
                  {children}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </Box>
  );
};
