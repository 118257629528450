import React from 'react';

export interface CachedImagePropTypes {
  src?: any;
  alt?: string;
  children?: any;
  width?: any;
  height?: any;

  [rest: string]: any;
}

export const CachedImage: React.FC<CachedImagePropTypes> = (props) => {
  const { src, alt = "photo", children, width = "300", height = "300", rest } = props;

  const [isUnavailable, setUnavailable] = React.useState(false);

  if (!src || isUnavailable) {
    return (
      children || (
        <div
          style={{
            lineHeight: "0",
            margin: "0 auto",
            backgroundColor: "rgb(242, 244, 245)",
            borderRadius: "16px",
            maxWidth: "100%",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            width={width}
            height={height}
            style={{
              maxWidth: "100%",
              width: "100%",
              height: "100%",
            }}
            {...rest}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 232 232"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="232" height="232" rx="12" fill="#F2F4F5" />
              <path
                d="M123.564 109.966C123.425 109.688 123.214 109.451 122.954 109.282C122.693 109.112 122.391 109.015 122.08 109C121.769 108.973 121.456 109.036 121.179 109.181C120.901 109.326 120.671 109.548 120.516 109.819L112.931 121.95L107.489 116.511C107.303 116.332 107.081 116.193 106.838 116.105C106.595 116.017 106.336 115.981 106.078 116C105.82 116.026 105.571 116.109 105.349 116.242C105.127 116.376 104.937 116.558 104.794 116.774L97.7939 127.274C97.6166 127.537 97.5145 127.844 97.4983 128.161C97.4822 128.479 97.5528 128.794 97.7024 129.075C97.8521 129.355 98.0751 129.589 98.3477 129.752C98.6204 129.915 98.9322 130.001 99.2499 130H130.75C131.048 130 131.342 129.924 131.602 129.778C131.863 129.633 132.082 129.423 132.239 129.17C132.396 128.916 132.485 128.626 132.498 128.328C132.511 128.03 132.448 127.733 132.314 127.466L123.564 109.966Z"
                fill="#E5E5E5"
              />
              <path
                d="M136 88H94C92.1435 88 90.363 88.7375 89.0503 90.0503C87.7375 91.363 87 93.1435 87 95V137C87 138.857 87.7375 140.637 89.0503 141.95C90.363 143.263 92.1435 144 94 144H136C137.857 144 139.637 143.263 140.95 141.95C142.263 140.637 143 138.857 143 137V95C143 93.1435 142.263 91.363 140.95 90.0503C139.637 88.7375 137.857 88 136 88V88ZM94 137V95H136V137H94Z"
                fill="#E5E5E5"
              />
              <path
                d="M104.5 109C106.433 109 108 107.433 108 105.5C108 103.567 106.433 102 104.5 102C102.567 102 101 103.567 101 105.5C101 107.433 102.567 109 104.5 109Z"
                fill="#E5E5E5"
              />
            </svg>
          </div>
        </div>
      )
    );
  }

  return (
    <img
      width={width}
      height={height}
      src={src}
      alt={alt}
      onError={() => setUnavailable(true)}
      sizes="inherit"
      style={{
        maxWidth: "100%",
        width: "100%",
        height: "auto",
      }}
      {...rest}
    />
  );
};
