import wretch, { Wretcher } from "wretch";
import type { Post, Get, Put, Delete } from "common/api/types";
import { getCookie } from "../../common/helpers/cookies";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
interface ActionCreators {
  //PROJECT CODE
  getProjectCode: Get<Record<string, unknown>, Record<string, unknown>>;
  getProjectCodesForClient: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getArchiveProjectCode: Get<Record<string, unknown>, Record<string, unknown>>;
  updateProjectCode: Put<Record<string, unknown>, Record<string, unknown>>;
  archiveProjectCode: Put<Record<string, unknown>, Record<string, unknown>>;
  createProjectCode: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteProjectCode: Delete<Record<string, unknown>, Record<string, unknown>>;
  //PTO
  getPtoForCompany: Get<Record<string, unknown>, Record<string, unknown>>;
  getPtoForTeamAssociate: Get<Record<string, unknown>, Record<string, unknown>>;
  getPtoForManager: Get<Record<string, unknown>, Record<string, unknown>>;

  updatePto: Put<Record<string, unknown>, Record<string, unknown>>;
  createPto: Post<Record<string, unknown>, Record<string, unknown>>;
  deletePto: Delete<Record<string, unknown>, Record<string, unknown>>;

  //TEAM MEMBER - PROJECT CODE
  getProjectCodeForTeamMember: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  assignProjectCodeForTeamMember: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  unAssignProjectCodeForTeamMember: Delete<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  //TIMESHEET
  getTimeSheet: Get<Record<string, unknown>, Record<string, unknown>>;
  getTimeSheetCoreDetails: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getTimeSheetForApproval: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  createTimeSheet: Post<Record<string, unknown>, Record<string, unknown>>;
  updateTimeSheet: Put<Record<string, unknown>, Record<string, unknown>>;
  deleteTimeSheet: Delete<Record<string, unknown>, Record<string, unknown>>;

  //TIMESHEET STATUS
  getTimeSheetStatusForManager: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;

  updateTimeSheetStatus: Get<Record<string, unknown>, Record<string, unknown>>;
  sendTsReminders: Post<Record<string, unknown>, Record<string, unknown>>;

  //TIMESHEET DETAIL
  getTimeSheetDetail: Get<Record<string, unknown>, Record<string, unknown>>;
  getTimeSheetsToReport: Post<Record<string, unknown>, Record<string, unknown>>;
  createTimeSheetDetails: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getTimeSheetDetailsDuplicate: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateTimeSheetDetails: Put<Record<string, unknown>, Record<string, unknown>>;
  //CLIENTS
  getClients: Get<Record<string, unknown>, Record<string, unknown>>;
  getClientDetails: Get<Record<string, unknown>, Record<string, unknown>>;
  updateClient: Put<Record<string, unknown>, Record<string, unknown>>;
  createClient: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteClient: Delete<Record<string, unknown>, Record<string, unknown>>;
  archiveClient: Post<Record<string, unknown>, Record<string, unknown>>;
  //VENDORS
  getVendors: Get<Record<string, unknown>, Record<string, unknown>>;
  getVendorDetails: Get<Record<string, unknown>, Record<string, unknown>>;
  updateVendor: Put<Record<string, unknown>, Record<string, unknown>>;
  createVendor: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteVendor: Delete<Record<string, unknown>, Record<string, unknown>>;
  archiveVendor: Post<Record<string, unknown>, Record<string, unknown>>;
  //ROLES
  getRoles: Get<Record<string, unknown>, Record<string, unknown>>;
  updateRole: Put<Record<string, unknown>, Record<string, unknown>>;
  createRole: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteRole: Delete<Record<string, unknown>, Record<string, unknown>>;

  //TEAM ASSO X CLIENT
  getTeamAssociateForClients: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateTeamAssociateForClient: Put<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  createTeamAssociateForClient: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  deleteTeamAssociateForClient: Delete<
    Record<string, unknown>,
    Record<string, unknown>
  >;

  //TEAM ASSO X VENDORS
  getTeamAssociateForVendors: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateTeamAssociateForVendor: Put<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  createTeamAssociateForVendor: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  deleteTeamAssociateForVendor: Delete<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  // timeSheetForClients
  getTimeSheetForClient: Get<Record<string, unknown>, Record<string, unknown>>;
  getMemberForCompanyByManager: Get<Record<string, unknown>, Record<string, unknown>>;
  
}

//PROJECT CODE
const projectCodeUrl = process.env.REACT_APP_TIMESHEET_URL + "/projectCode";
const archiveProjectCodeUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/archiveProjectCode";

//PTO
const ptoUrl = process.env.REACT_APP_TIMESHEET_URL + "/pto";
const ptoCompanyUrl = process.env.REACT_APP_TIMESHEET_URL + "/pto-company";
const ptoTeamAssoUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/pto-team-associate";
const ptoManagerUrl = process.env.REACT_APP_TIMESHEET_URL + "/pto-manager";
const ptoDeleteUrl = process.env.REACT_APP_TIMESHEET_URL + "/pto-delete";

//TEAM MEMBER - PROJECT CODE
const projectCodeForTeamMembersUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/teamMemberProjectCodes";
const projectCodeForTeamMemberUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/teamMemberProjectCode";

//TIMESHEET
const timeSheetsUrl = process.env.REACT_APP_TIMESHEET_URL + "/timesheets";
const timeSheetsApprovalUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/get-timesheet-approval";
const timeSheetUrl = process.env.REACT_APP_TIMESHEET_URL + "/timesheet";
const deleteTimeSheetUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/delete-timesheet";
// TimeSheetForClients
const timeSheetForClientUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/timesheets-client";
const timeSheetCoreDetailUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/timesheets-core-details";
const timeSheetCoreDetailDuplicateUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/timesheets-details-duplicate";

//TIME SHEET DETAILS
const timeSheetDetailUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/timesheet-details";
const timeSheetReportUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/timesheet-report";

//TIMESHEET STATUS
const timeSheetsStatusForManagerUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/timesheet-status-manager";
const timeSheetsStatusUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/timesheet-status";

//CLIENTS
const clientUrl = process.env.REACT_APP_TIMESHEET_URL + "/comp-client";
const clientArchiveUrl = process.env.REACT_APP_TIMESHEET_URL + "/archive-client";
//VENDORS
const vendorUrl = process.env.REACT_APP_TIMESHEET_URL + "/comp-vendor";
const vendorArchiveUrl = process.env.REACT_APP_TIMESHEET_URL + "/archive-vendor";
//ROLE
const roleUrl = process.env.REACT_APP_TIMESHEET_URL + "/applRole";
const projectCodesForClientUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/getProjectCodesForClient";

//TEAM ASSO X CLIENT
const teamAssoXClientUrl =
  process.env.REACT_APP_TIMESHEET_URL + "/client-associates";

//TEAM ASSO X VENDOR
const teamAssoXVendorUrl = process.env.REACT_APP_TIMESHEET_URL + "/vendor-associates";
const teamAssociatesForCompanyByManager = process.env.REACT_APP_TIMESHEET_URL + "/teamAssociatesForCompanyByManager";

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  getMemberForCompanyByManager:(queryParams = {}) => gateway .url(`${teamAssociatesForCompanyByManager}/${queryParams.companyId}/${queryParams.managerId}`).get().json(),
  //PROJECT CODE
  getProjectCode: (queryParams = {}) =>
    gateway.url(`${projectCodeUrl}/${queryParams.companyId}`).get().json(),
  getArchiveProjectCode: (queryParams = {}) =>
    gateway
      .url(`${archiveProjectCodeUrl}/${queryParams.companyId}`)
      .get()
      .json(),
  getProjectCodesForClient: (queryParams = {}) =>
    gateway
      .url(
        `${projectCodesForClientUrl}/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
  createProjectCode: (body) => gateway.url(projectCodeUrl).post(body).json(),
  updateProjectCode: (body) => gateway.url(projectCodeUrl).put(body).json(),
  archiveProjectCode: (body) =>
    gateway.url(archiveProjectCodeUrl).put(body).json(),
  deleteProjectCode: (companyId, queryParams = {}) =>
    gateway
      .url(`${projectCodeUrl}-delete/${companyId}/${queryParams.projectCodeId}`)
      .delete()
      .json(),

  //PTO
  getPtoForCompany: (queryParams = {}) =>
    gateway.url(`${ptoCompanyUrl}/${queryParams.companyId}`).get().json(),
  getPtoForTeamAssociate: (queryParams = {}) =>
    gateway
      .url(
        `${ptoTeamAssoUrl}/${queryParams.companyId}/${queryParams.teamAssociateId}`
      )
      .get()
      .json(),
  getPtoForManager: (queryParams = {}) =>
    gateway
      .url(`${ptoManagerUrl}/${queryParams.companyId}/${queryParams.managerId}`)
      .get()
      .json(),

  createPto: (body) => gateway.url(ptoUrl).post(body).json(),
  updatePto: (body) => gateway.url(ptoUrl).put(body).json(),
  deletePto: (companyId, ptoId) =>
    gateway.url(`${ptoDeleteUrl}/${companyId}/${ptoId}`).delete().json(),

  //TEAM MEMBER - PROJECT CODE
  getProjectCodeForTeamMember: (queryParams = {}) =>
    gateway
      .url(
        `${projectCodeForTeamMembersUrl}/${queryParams.companyId}/${queryParams.teamMemberId}`
      )
      .get()
      .json(),
  assignProjectCodeForTeamMember: (body) =>
    gateway.url(projectCodeForTeamMemberUrl).post(body).json(),
  unAssignProjectCodeForTeamMember: (teamMemberId, queryParams = {}) =>
    gateway
      .url(
        `${projectCodeForTeamMemberUrl}-delete/${teamMemberId}/${queryParams.teamMemberProjectCodeId}`
      )
      .delete()
      .json(),
  //TIME SHEET
  getTimeSheet: (queryParams = {}) =>
    gateway
      .url(
        `${timeSheetsUrl}/${queryParams.companyId}/${queryParams.teamAssociateId}`
      )
      .get()
      .json(),
  getTimeSheetForApproval: (queryParams = {}) =>
    gateway
      .url(
        `${timeSheetsApprovalUrl}/${queryParams.companyId}/${queryParams.managerId}`
      )
      .get()
      .json(),
  getTimeSheetCoreDetails: (queryParams = {}) =>
    gateway
      .url(
        `${timeSheetCoreDetailUrl}/${queryParams.companyId}/${queryParams.timeSheetId}`
      )
      .get()
      .json(),

  getTimeSheetDetailsDuplicate: (queryParams = {}) =>
    gateway.url( `${timeSheetCoreDetailDuplicateUrl}/${queryParams.companyId}/${queryParams.timeSheetId}` ).get().json(),
  createTimeSheet: (body) => gateway.url(timeSheetUrl).post(body).json(),
  updateTimeSheet: (body) => gateway.url(`${timeSheetUrl}`).put(body).json(),
  deleteTimeSheet: (companyId, timeSheetId) =>
    gateway
      .url(`${deleteTimeSheetUrl}/${companyId}/${timeSheetId}`)
      .delete()
      .json(),
  sendTsReminders: (body) =>
    gateway.url(`${timeSheetsStatusUrl}/send-reminder`).post(body).json(),
  //TIME SHEET STATUS
  getTimeSheetStatusForManager: (queryParams = {}) =>
    gateway
      .url(
        `${timeSheetsStatusForManagerUrl}/${queryParams.companyId}/${queryParams.managerId}`
      )
      .get()
      .json(),
  updateTimeSheetStatus: (body) =>
    gateway.url(`${timeSheetsStatusUrl}`).put(body).json(),

  // TIME SHEET DETAILS
  getTimeSheetDetail: (queryParams = {}) =>
    gateway
      .url(`${timeSheetDetailUrl}/${queryParams.timeSheetId}`)
      .get()
      .json(),
  getTimeSheetsToReport: (body) =>
    gateway.url(`${timeSheetReportUrl}`).post(body).json(),
  createTimeSheetDetails: (body) =>
    gateway.url(`${timeSheetDetailUrl}`).post(body).json(),
  updateTimeSheetDetails: (body) =>
    gateway.url(`${timeSheetDetailUrl}`).put(body).json(),

  //CLIENT
  getClients: (queryParams = {}) =>
    gateway
      .url(`${clientUrl}/${queryParams.companyId}`)
      .get()
      .json(),
  getClientDetails: (queryParams = {}) =>
    gateway
      .url(
        `${clientUrl}-details/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
  updateClient: (body) => gateway.url(clientUrl).put(body).json(),
  createClient: (body) => gateway.url(clientUrl).post(body).json(),
  deleteClient: (companyId, clientId) =>
    gateway.url(`${clientUrl}-delete/${companyId}/${clientId}`).delete().json(),
  archiveClient: (body) => gateway.url(clientArchiveUrl).post(body).json(),
  //VENDORS
  getVendors: (queryParams = {}) =>
    gateway.url(`${vendorUrl}/${queryParams.companyId}`).get().json(),
  getVendorDetails: (queryParams = {}) =>
    gateway
      .url(
        `${vendorUrl}-details/${queryParams.companyId}/${queryParams.vendorId}`
      )
      .get()
      .json(),
  updateVendor: (body) => gateway.url(vendorUrl).put(body).json(),
  createVendor: (body) => gateway.url(vendorUrl).post(body).json(),
  deleteVendor: (companyId, vendorId) =>
    gateway.url(`${vendorUrl}-delete/${companyId}/${vendorId}`).delete().json(),
  archiveVendor: (body) => gateway.url(vendorArchiveUrl).post(body).json(),
  //ROLES
  getRoles: (queryParams = {}) =>
    gateway.url(`${roleUrl}/${queryParams.companyId}`).get().json(),
  updateRole: (body) => gateway.url(roleUrl).put(body).json(),
  createRole: (body) => gateway.url(roleUrl).post(body).json(),
  deleteRole: (companyId, roleId) =>
    gateway.url(`${roleUrl}-delete/${companyId}/${roleId}`).delete().json(),
  //TEAM ASSO X CLIENT
  getTeamAssociateForClients: (queryParams = {}) =>
    gateway
      .url(
        `${teamAssoXClientUrl}/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
  updateTeamAssociateForClient: (body) =>
    gateway.url(teamAssoXClientUrl).put(body).json(),
  createTeamAssociateForClient: (body) =>
    gateway.url(teamAssoXClientUrl).post(body).json(),
  deleteTeamAssociateForClient: (companyId, roleId) =>
    gateway
      .url(`${teamAssoXClientUrl}-delete/${companyId}/${roleId}`)
      .delete()
      .json(),

  //TEAM ASSO X VENDORS
  getTeamAssociateForVendors: (queryParams = {}) =>
    gateway
      .url(
        `${teamAssoXVendorUrl}/${queryParams.companyId}/${queryParams.vendorId}`
      )
      .get()
      .json(),
  updateTeamAssociateForVendor: (body) =>
    gateway.url(teamAssoXVendorUrl).put(body).json(),
  createTeamAssociateForVendor: (body) =>
    gateway.url(teamAssoXVendorUrl).post(body).json(),
  deleteTeamAssociateForVendor: (companyId, roleId) =>
    gateway
      .url(`${teamAssoXVendorUrl}-delete/${companyId}/${roleId}`)
      .delete()
      .json(),
  // timeSheetForClients
  getTimeSheetForClient: (queryParams = {}) =>
    gateway
      .url(
        `${timeSheetForClientUrl}/${queryParams.companyId}/${queryParams.clientId}`
      )
      .get()
      .json(),
});

export const timeSheetActionCreators = (): {
  actionCreators: ActionCreators;
  headers: Record<string, string>;
} => {
  // const context = useTimeSheetContext();
  // const { associateToken } = context;
  const authHeaders: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const defaultHeaders: { [key: string]: string } = {
    "X-Api-Key": `${process.env.REACT_APP_X_API_KEY_TIMESHEET}`,
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("accessToken")}` || ``,
    // "associateToken": associateToken,
  };

  const headers = !getCookie("accessToken") ? authHeaders : defaultHeaders;
  const gateway = wretch().headers(headers);
  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
