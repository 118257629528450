import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import makeRequest from "common/api/makeRequest";
import FormItemLabel from "common/components/FormItemLabel";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { dashboardActionCreators } from "timesheets/api/dashboardActions";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { GridContainerStyled } from "timesheets/styles/common";
import { getMonthlyData, getYearlyData } from "timesheets/utils";
import { DATE_FORMAT } from "timesheets/utils/constant";
interface ICompAssociateInfoDashboard {
  type: "VENDOR" | "CLIENT" | "COMPANY";
  currentId?: string;
  isCompanyView?: boolean;
}

const AssociateInfoDashboard: React.FC<ICompAssociateInfoDashboard> = ({
  type,
  currentId,
  isCompanyView = false,
}) => {
  const context = useTimeSheetContext();
  const { teamAssociate } = context;
  const [dashboardData, setDashboardData] = useState<any>({});
  const [startDate, setStartDate] = useState<any>("");
  const [datesData, setDatesData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterView, setFilterView] = useState<"MONTHLY" | "YEARLY">("YEARLY");

  const companyId = teamAssociate?.companyId;

  const {
    actionCreators: {
      getTeamAssociateInfoForClient,
      getTeamAssociateInfoForVendor,
      getTeamAssociateInfoForCompany
    },
  } = dashboardActionCreators();

  const handleGetTeamAssociateInfoForClient = React.useCallback(
    (clientId, startDate = undefined, endDate = undefined) => {
      setLoading(true);
      makeRequest(
        () => {
          return getTeamAssociateInfoForClient({
            companyId,
            clientId,
            startDate,
            endDate,
          });
        },
        (data: any) => {
          setDashboardData(data);
          setLoading(false);
        },
        () => setLoading(false)
      );
    },
    [getTeamAssociateInfoForClient]
  );

  const handleGetTeamAssociateInfoForVendor = React.useCallback(
		(vendorId, startDate = undefined, endDate = undefined) => {
			setLoading(true);
			makeRequest(
				() => {
					return getTeamAssociateInfoForVendor({
						companyId,
						vendorId,
						startDate,
						endDate,
					});
				},
				(data: any) => {
					setDashboardData(data);
					setLoading(false);
				},
				() => setLoading(false)
			);
		},
		[getTeamAssociateInfoForVendor]
	);

	const handleGetTeamAssociateInfoForCompany = React.useCallback(
		(startDate = undefined, endDate = undefined) => {
			setLoading(true);
			makeRequest(
				() => {
					return getTeamAssociateInfoForCompany({
						companyId,
						startDate,
						endDate,
					});
				},
				(data: any) => {
					setDashboardData(data);
					setLoading(false);
				},
				() => setLoading(false)
			);
		},
		[getTeamAssociateInfoForCompany]
	);

  useEffect(() => {
    let endDate;
    switch (filterView) {
      case "MONTHLY":
        endDate = moment(startDate).endOf("month").format(DATE_FORMAT);
        break;

      case "YEARLY":
        endDate = moment(startDate).endOf("year").format(DATE_FORMAT);
        break;

      default:
        break;
    }

    if (
      (startDate && teamAssociate?.clientId && type === "CLIENT") ||
      (startDate && currentId && type === "CLIENT")
    ) {
      const clientId =
        isCompanyView && currentId ? currentId : teamAssociate?.clientId;
      handleGetTeamAssociateInfoForClient(clientId, startDate, endDate);
    }

    if (
      (startDate && teamAssociate?.vendorId && type === "VENDOR") ||
      (startDate && currentId && type === "VENDOR")
    ) {
      const vendorId =
        isCompanyView && currentId ? currentId : teamAssociate?.vendorId;
      handleGetTeamAssociateInfoForVendor(vendorId, startDate, endDate);
    }

    if (
      (startDate && type === "COMPANY")
    ) {
      handleGetTeamAssociateInfoForCompany(startDate, endDate);
    }
  }, [startDate, currentId, isCompanyView, type]);


  const handleCurrentReportView = () => {
    const momentObject = {
      startDate: "",
    };
    switch (filterView) {
      case "MONTHLY":
        momentObject.startDate = moment().startOf("month").format(DATE_FORMAT);
        break;
      case "YEARLY":
        momentObject.startDate = moment().startOf("year").format(DATE_FORMAT);
        break;
      default:
        break;
    }
    return momentObject;
  };

  useEffect(() => {
    const startDate = handleCurrentReportView().startDate;
    setStartDate(startDate);
    switch (filterView) {
      case "MONTHLY":
        setDatesData(getMonthlyData(10, true));
        break;
      case "YEARLY":
        setDatesData(getYearlyData(2, true));
        break;
      default:
        break;
    }
  }, [filterView]);

  return (
    <Box sx={{ width: "100%", position: "relative", minHeight: "600px" }}>
      <Box
        sx={{
          position: "absolute",
          top: 4,
          left: 2,
          borderBottom: "1px solid #eaeaea",
          pb: 1,
          width: "50%",
          fontWeight: "thin",
        }}
      >
        Associate Info
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Typography>
          Total Associate :
          <strong style={{ fontSize: "2rem", marginLeft: "10px" }}>
            {dashboardData.countOfTotalAssociate || 0}
          </strong>
        </Typography>
      </Box>
      <Box display={"flex"} gap="15px">
        <FormItemLabel label="Set View">
          <TextField
            select
            onChange={(e: any) => {
              setFilterView(e.target.value);
            }}
            value={filterView}
            size="small"
          >
            <MenuItem value="MONTHLY">Monthly</MenuItem>
            <MenuItem value="YEARLY">Yearly</MenuItem>
          </TextField>
        </FormItemLabel>
        <FormItemLabel
          label={`Choose ${filterView === "MONTHLY" ? "month" : "year"} `}
        >
          <TextField
            size="small"
            select
            value={startDate}
            onChange={(e) => {
              return setStartDate(moment(e.target.value).format(DATE_FORMAT));
            }}
            sx={{ minWidth: "100px" }}
          >
            {datesData.map((item, index) => {
              if (filterView === "MONTHLY") {
                return (
                  <MenuItem key={index} value={item.startDate}>
                    {moment(item.startDate).format("MMMM - YYYY")}
                  </MenuItem>
                );
              } else if (filterView === "YEARLY") {
                return (
                  <MenuItem key={index} value={item.startDate}>
                    {moment(item.startDate).format("YYYY")}
                  </MenuItem>
                );
              }
            })}
          </TextField>
        </FormItemLabel>
        <Box sx={{ display: "flex", alignItems: "end" }}>
          {loading && <CircularProgress />}
        </Box>
      </Box>

      <GridContainerStyled
        container
        columnSpacing={2}
        rowSpacing={4}
        justifyContent="center"
        alignItems={"center"}
      >
        {type === "COMPANY" && (
          <>
            <Grid
              item
              xs={4}
              borderBottom="1px solid #e2e2e2"
              sx={{ pb: 2 }}
              color="#FF7B54"
              textAlign={"center"}
            >
              <CountUp
                end={dashboardData.countsOfW2}
                style={{
                  fontSize: "2.2rem",
                  fontWeight: "bold",
                }}
              />
              <Box> W2 </Box>
            </Grid>
            <Grid
              item
              xs={4}
              borderBottom="1px solid #e2e2e2"
              sx={{ pb: 2 }}
              color="#FFB26B"
              textAlign={"center"}
            >
              <CountUp
                end={dashboardData.countsOfContractor}
                style={{
                  fontSize: "2.2rem",
                  fontWeight: "bold",
                }}
              />
              <Box> Contractors </Box>
            </Grid>
            <Grid
              item
              xs={4}
              borderBottom="1px solid #e2e2e2"
              sx={{ pb: 2 }}
              color="#3C6255"
              textAlign={"center"}
            >
              <CountUp
                end={dashboardData.countsOfC2c}
                style={{
                  fontSize: "2.2rem",
                  fontWeight: "bold",
                }}
              />
              <Box> C2C </Box>
            </Grid>
          </>
        )}
        {type !== "VENDOR" && (
          <>
            <Grid
              item
              xs={4}
              borderBottom="1px solid #e2e2e2"
              sx={{ pb: 2 }}
              color="#939B62"
              textAlign={"center"}
            >
              <CountUp
                end={dashboardData.countsOfOnSite}
                style={{
                  fontSize: "2.2rem",
                  fontWeight: "bold",
                }}
              />
              <Box> On-site </Box>
            </Grid>
            <Grid
              item
              xs={4}
              borderBottom="1px solid #e2e2e2"
              sx={{ pb: 2 }}
              color="#58287F"
              textAlign={"center"}
            >
              <CountUp
                end={dashboardData.countsOfOffShore}
                style={{
                  fontSize: "2.2rem",
                  fontWeight: "bold",
                }}
              />
              <Box> Remote </Box>
            </Grid>
          </>
        )}
      </GridContainerStyled>
    </Box>
  );
};

export default AssociateInfoDashboard;
