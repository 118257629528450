import {
  ArrowDropDown as ArrowDropDownIcon,
  Logout,
  SupervisedUserCircleRounded,
  SyncAlt as SyncAltIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";

import userImage from "assets/images/valid_user.png";
import { getCookie, removeCookie } from "common/helpers/cookies";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { listCookie, TAB_ACTIONS } from "timesheets/utils/constant";

import { useStyles } from "./index.styles";
import { IoMdConstruct } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../components/Shared/pages";
interface ICompHeader {
  handleLogout?: any;
}

const Header: React.FC<ICompHeader> = ({ handleLogout }) => {
  const classes = useStyles();
  const [memberData, setMemberData] = useState<any>([]);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const context = useTimeSheetContext();
  const { companyInformation, teamAssociate, authInfo, clients } = context;
  const [isMultiCompany, setIsMulticompany] = useState<boolean>(false);

  useEffect(() => {
    setMemberData(teamAssociate);
  }, [teamAssociate, companyInformation]);

  useEffect(() => {
    if (authInfo?.hasMultiCompany) {
      setIsMulticompany(true);
    } else {
      setIsMulticompany(false);
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [client, setClient] = React.useState<any>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (clients && teamAssociate) {
      setClient(clients.find((el) => el.clientId === teamAssociate.clientId));
    }
  }, [teamAssociate, clients]);

  // signout || logout
  // const handleLogout = async (callback?) => {
  //     handleClose();
  //     setLoading(true);
  //     await Auth.signOut();
  //     setLoading(false);
  //     setIsAuth(false);
  //     setAuthInfo({});
  //     context.setCompanyInformation({});
  //     context.setTeamAssociate({});
  //     // remove all cookie
  //     listCookie.forEach((value: string) => removeCookie(value));
  //     callback && callback ()
  // };

  // prevent memory leak
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <Box className={classes.blockHeader}>
      <Box className={classes.userBox}>
        <Tooltip title="Account settings">
          <>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                <img
                  style={{ maxWidth: "100%" }}
                  src={userImage}
                  alt="User Image"
                />
              </Avatar>
            </IconButton>
            {memberData?.firstName && memberData?.lastName && (
              <Box
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                {memberData?.firstName + " " + memberData?.lastName}
                <ArrowDropDownIcon />
              </Box>
            )}
          </>
        </Tooltip>
      </Box>
      <Menu
        className={classes.userMenu}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleClose}
          sx={{
            padding: "0.5rem 1rem !important",
            minWidth: "250px",
          }}
        >
          <Avatar />
          <Box>
            <Box>
              {memberData?.firstName &&
                memberData?.lastName &&
                memberData?.firstName + " " + memberData?.lastName}
            </Box>
            <Box>
              <small style={{ paddingTop: "0.25rem !important" }}>
                {memberData?.email}
              </small>
            </Box>
          </Box>
          <Box></Box>
        </MenuItem>
        <Divider />
        <Box>
          {isMultiCompany && (
            <MenuItem
              onClick={() => {
                removeCookie("currentWorkSpace");
                context.setWsStep("CHOOSE_WS");
              }}
            >
              <ListItemIcon>
                <SyncAltIcon fontSize="small" />
              </ListItemIcon>
              Change Company
            </MenuItem>
          )}

          {/* {teamAssociate?.authRoleCode === "CLIENT_ADMIN" && (
            <MenuItem
              onClick={() => {
                // context.setClientSelected(clients);
                history.push(
                  `/${ROUTES.client}/${TAB_ACTIONS.EDIT}/${client?.clientId}`
                );
              }}
            >
              <ListItemIcon>
                <IoMdConstruct fontSize="small" />
              </ListItemIcon>
              Client Details
            </MenuItem>
          )} */}

          {teamAssociate?.authRoleList?.length > 1 && (
            <MenuItem>
              <ListItemIcon>
                <SupervisedUserCircleRounded fontSize="small" />
              </ListItemIcon>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold" }}>
                  {teamAssociate.authRoleName}
                </div>
                <button
                  style={{
                    backgroundColor: "#f15c30",
                    padding: "2px 4px",
                    borderRadius: 4,
                    color: "white",
                    fontSize: "small",
                  }}
                  onClick={() => {
                    context.setRoleStep("CHOOSE_ROLE");
                  }}
                >
                  Switch Role
                </button>
              </Box>
            </MenuItem>
          )}

          <MenuItem onClick={() => handleLogout(setLoading, handleClose)}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default Header;
