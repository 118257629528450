import { Country, State } from "country-state-city";
import { UNITED_STATE_CODE } from "timesheets/utils/constant";

const mapDataForSelect = (arrayData: { isoCode: string; name: string; }[]) => {
  return arrayData.map((country: { isoCode: string; name: string }) => ({
    value: country.isoCode,
    label: country.name,
    rest: country,
  }));
};

const useLocation = () => {
  const getCountryBase = () => {
    return Country.getAllCountries();
  };
  const getCountry = () => {
    const getCountryData = Country.getAllCountries();
    return mapDataForSelect(getCountryData);
  };

  const getState = (countryCode: string) => {
    const getStateData = State.getStatesOfCountry(countryCode || UNITED_STATE_CODE);
    return mapDataForSelect(getStateData);
  };

  return {
    getCountryBase,
    getCountry,
    getState,
  };
};
export default useLocation;
