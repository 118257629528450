import { Grid, Typography } from "@mui/material";
import SelectCustomForm from "common/components/Form/SelectCustomForm";
import TextFieldInput from "common/components/Form/TextFieldInput";
import TextFieldSelectMulti from "common/components/Form/TextFieldSelectMulti";
import useLocation from "common/hooks/useLocation";
import React from "react";
import useCurrencyActions from "timesheets/api/actions/currencyCalls";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { GridContainerStyled } from "../../../styles/common";

interface ICompanyBasic {
  formik: any;
}

const CompanyBasic: React.FC<ICompanyBasic> = ({ formik }) => {
  const { getCountryBase, getCountry, getState } = useLocation();
 
  const allCountriesBase = getCountryBase();
  const {
    currencyList,
    companyInformation
	} = useTimeSheetContext();
 
  const allCountries = getCountry();
  const [allStates, setAllStates] = React.useState<any>([]);

  React.useEffect(() => {
    if (formik.values.countryCode) {
      setAllStates(getState(formik.values.countryCode));
    }
  }, [formik.values.countryCode]);

  return (
		<>
			{/* Fist name and Last name */}
			<GridContainerStyled container spacing={2}>
				<Grid item xs={12} md={2}>
					<Typography>Name</Typography>
				</Grid>
				<Grid item xs={12} md={5}>
					<TextFieldInput form={formik} name="firstName" placeholder="Fist Name" />
				</Grid>
				<Grid item xs={12} md={5}>
					<TextFieldInput form={formik} name="lastName" placeholder="Last Name" />
				</Grid>
			</GridContainerStyled>
			{/* Company name and Company code */}
			<GridContainerStyled container spacing={2}>
				<Grid item xs={12} sm={2}>
					<Typography>Company Name</Typography>
				</Grid>
				<Grid item xs={12} sm={5}>
					<TextFieldInput form={formik} name="companyName" placeholder="Company Name" />
				</Grid>
				<Grid item xs={12} sm={5}>
					<TextFieldInput form={formik} name="companyCode" placeholder="Company Code" />
				</Grid>
			</GridContainerStyled>
			{/* Company Address */}
			<GridContainerStyled container spacing={2}>
				<Grid item xs={12} sm={2}>
					<Typography>Company Address</Typography>
				</Grid>
				<Grid item xs={12} sm={5}>
					<TextFieldInput form={formik} name="address1" placeholder="Address Line 1" />
				</Grid>
				<Grid item xs={12} sm={5}>
					<TextFieldInput form={formik} name="address2" placeholder="Address Line 2" />
				</Grid>
			</GridContainerStyled>

			{/* Country and Zip code*/}
			<GridContainerStyled container spacing={2}>
				<Grid item xs={12} sm={2}></Grid>
				<Grid item xs={12} sm={5}>
					<SelectCustomForm
						dataList={allCountries}
						form={formik}
						name="countryCode"
						placeholder="Country"
						onChange={() => formik.setFieldValue("stateCode", "")}
					/>
				</Grid>
				<Grid item xs={12} sm={5}>
					<TextFieldInput form={formik} name="zipCode" placeholder="Zip Code" />
				</Grid>
			</GridContainerStyled>
			{/* State and City*/}
			<GridContainerStyled container spacing={2}>
				<Grid item xs={12} sm={2}></Grid>
				<Grid item xs={12} sm={5}>
					<SelectCustomForm
						dataList={allStates}
						form={formik}
						name="stateCode"
						placeholder="State"
					/>
				</Grid>
				<Grid item xs={12} sm={5}>
					<TextFieldInput form={formik} name="cityName" placeholder="City" />
				</Grid>
			</GridContainerStyled>

			{/* <GridContainerStyled container spacing={2}>
				<Grid item xs={12} sm={2}>
					<Typography>Locations</Typography>
				</Grid>
				<Grid item xs={12} sm={5}>
					<TextFieldSelectMulti
						form={formik}
						placeholder="Locations"
						name="locations"
						options={allCountriesBase}
						getOptionLabel={(option: any) => option.name}
						isOptionEqualToValue={(option, value) => option.isoCode === value.isoCode}
						onChange={(e, value) =>
							formik.setFieldValue(
								"locations",
								value?.map(({ name, isoCode }) => ({ name, isoCode }))
							)
						}
					/>
				</Grid>
			</GridContainerStyled>
			{companyInformation?.companyStatus !== "NEW" && (
				<GridContainerStyled container spacing={2}>
					<Grid item xs={12} sm={2}>
						<Typography>Currencies</Typography>
					</Grid>
					<Grid item xs={12} sm={5}>
						<TextFieldSelectMulti
							form={formik}
							placeholder="Currencies"
							name="currencies"
							options={currencyList?.length > 0 ? currencyList : []}
							getOptionLabel={(option: any) => option.currencyName}
							isOptionEqualToValue={(option, value) => option.currencyId === value.currencyId}
							onChange={(e, value) =>
								formik.setFieldValue(
									"currencies",
									value?.map(({ currencyName, currencyId }) => ({ currencyName, currencyId }))
								)
							}
						/>
					</Grid>
				</GridContainerStyled>
			)} */}
		</>
	);
};

export default CompanyBasic;


