import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, FormControl, FormHelperText, Tooltip } from "@mui/material";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import FormItemLabel from "../FormItemLabel";
import { makeStyles } from "@material-ui/core";

interface ISelectCustomForm {
	dataList: any;
	form: any;
	sx?: any;
	name: string;
	label?: string;
	required?: boolean;
	disabled?: boolean;
	loading?: boolean;
	placeholder?: string;
	clearable?: boolean;
	searchable?: boolean;
	multiple?: boolean;
	hideDropDown?: boolean;
	iconPlus?: boolean;
	blurInputOnSelect?: boolean;
	onChange?: (event: any) => void;
	onInputChange?: (event: any) => void;
	onClickPlus?: (payload: any) => void;
}

const useStyles = makeStyles((theme) => {
	return {
		block: {
			width: "100%",
		},
		icon: {
			width: "40px",
			height: "40px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		select: { flex: 1, maxWidth: "100% !important" },
	};
});

const SelectCustomForm: FC<ISelectCustomForm> = (props) => {
	const {
		form,
		dataList = [],
		name,
		label,
		required,
		onChange,
		onInputChange,
		onClickPlus,
		iconPlus = true,
		disabled,
		sx,
		placeholder = "Select...",
		clearable = true,
		searchable = true,
		multiple = false,
		hideDropDown = false,
		loading,
		blurInputOnSelect = false,
	} = props;

	const { isLoading } = useTimeSheetContext();

	const [valueTemp, setValueTemp] = useState<any>("");
	const [isOpenMenu, setIsOpenMenu] = useState<boolean | undefined>(false);

	const checkRequired = !!form?.errors?.[name] && !!form?.touched?.[name] && !form?.values?.[name];
	const classes = useStyles();

	const customStyles: StylesConfig = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: "white",
			minHeight: "40px",
			cursor: "pointer",
			borderColor: checkRequired ? "#d32f2f!important" : "rgba(0, 0, 0, 0.23)",
			":hover": { borderColor: "#000" },
			boxShadow: state.isFocused
				? checkRequired
					? "0 0 0 1px #d32f2f!important"
					: "0 0 0 1px #1976d2"
				: "0 0 0 1px #0000!important",
		}),
		option: (styles) => ({ ...styles }),
		input: (styles) => ({ ...styles }),
		placeholder: (styles) => ({ ...styles }),
		singleValue: (styles) => ({ ...styles }),
		valueContainer: (styles) => ({ ...styles, padding: "3px 14px" }),
		menu: (styles) => ({ ...styles, cursor: "pointer", zIndex: 10 }),
		multiValue: (styles) => ({ ...styles, cursor: "pointer" }),
		dropdownIndicator: (styles) => ({
			...styles,
			color: checkRequired ? "#d32f2f!important" : "rgba(0, 0, 0, 0.23)",
		}),
		indicatorSeparator: (styles) => ({
			...styles,
			backgroundColor: checkRequired ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
		}),
	};

	const handleOnChange = (event: any) => {
		const valueSelect = multiple ? event : event?.value;
		form.setFieldValue(name, valueSelect);

		onChange && onChange(event);
	};

	const handleOnInputChange = (event: string) => {
		onInputChange && onInputChange(event);

		if (!hideDropDown) return;

		if (event) return setIsOpenMenu(true);
		setIsOpenMenu(false);
	};

	//show select onchange choose
	useEffect(() => {
		if (!dataList?.length) return;
		if (!form?.values?.[name]) return setValueTemp("");

		// if(form?.values?.[name]) {
		//   delete  form?.errors?.[name];
		//   form?.setErrors(form?.errors)
		// }

		const selectedOption = multiple
			? form?.values?.[name]
			: dataList.find((option: { value: string }) => option.value === form?.values?.[name]);
		const value = selectedOption ?? (multiple ? [] : undefined);

		setValueTemp(value);
	}, [form?.values?.[name], dataList]);

	useEffect(() => {
		if (!hideDropDown) return setIsOpenMenu(undefined);
	}, [hideDropDown]);

	return (
		<FormControl
			className={classes.block}
			sx={sx}
			size="small"
			disabled={disabled}
			error={checkRequired}
			required={required}
		>
			<FormItemLabel label={label} required={required}>
				<Box display={"flex"} alignItems={"flex-start"}>
					<Box className={classes.select}>
						<Select
							className={classNames({
								["basic-single"]: !multiple,
								["basic-multi-select"]: multiple,
							})}
							onBlur={() => form?.setFieldTouched(name, true)}
							classNamePrefix="select"
							value={valueTemp}
							isDisabled={disabled}
							isLoading={loading || isLoading}
							isClearable={!required ?? clearable}
							isSearchable={searchable}
							name={name}
							isMulti={multiple}
							options={dataList || []}
							placeholder={placeholder}
							onChange={handleOnChange}
							onInputChange={handleOnInputChange}
							styles={customStyles}
							required={required}
							closeMenuOnSelect={!multiple}
							menuIsOpen={isOpenMenu}
							blurInputOnSelect={blurInputOnSelect}
						/>
						{checkRequired && <FormHelperText>{form?.errors?.[name]}</FormHelperText>}
					</Box>
					{onClickPlus && !disabled && iconPlus && (
						<Box className={classes.icon}>
							<Tooltip title={label}>
								<AddCircleOutlineIcon
									sx={{
										cursor: "pointer",
										fontSize: 30,
										color: "#b8b8b8",
									}}
									onClick={onClickPlus}
								/>
							</Tooltip>
						</Box>
					)}
				</Box>
			</FormItemLabel>
		</FormControl>
	);
};

export default SelectCustomForm;
