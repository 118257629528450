import { Box, Grid } from "@mui/material";
import * as React from "react";
import { useTimeSheetContext } from "../../context/TimeSheetContext";
import {
  AccordionStyled,
  AccordionTitleStyled,
  GridContainerStyled,
  TitleBold,
} from "../../styles/common";

import AssociateInfoDashboard from "../AssociateInfoDashboard";
import Chart from "../DashboardChart";

const DashboardVendor: React.FC = () => {
  const { setIsLoading, setBreadcrumbs } = useTimeSheetContext();

  React.useEffect(() => {
    setBreadcrumbs([{ label: "Dashboard Vendor", active: true }]);
    return () => setBreadcrumbs([]);
  }, []);

  const cardSx = {
    padding: "0 1rem",
  };
  const cardSxItem = {
    boxShadow: "5px 1px 15px #e2e2e2",
    borderRadius: 4,
    minHeight: 500,
    padding: "2rem",
    position: "relative",
  };

  return (
    <Box>
      <AccordionStyled>
        <AccordionTitleStyled>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TitleBold>Dashboard Vendor</TitleBold>
          </Box>
        </AccordionTitleStyled>
      </AccordionStyled>

      <Grid container sx={{ display: "flex" }}>
        <GridContainerStyled container spacing={4}>
          <Grid item sx={cardSx} xs={12} lg={6}>
            <Box sx={cardSxItem}>
              <Chart type="VENDOR" />
            </Box>
          </Grid>
          <Grid item sx={cardSx} xs={12} lg={6}>
            <Box sx={cardSxItem}>
              <AssociateInfoDashboard type="VENDOR" />
            </Box>
          </Grid>
        </GridContainerStyled>
      </Grid>
    </Box>
  );
};

export default DashboardVendor;
