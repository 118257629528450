import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { DATE_FORMAT } from "timesheets/utils/constant";
import { helperToken } from "../api/helperToken";
import { FORMAT_DATE } from "timesheets/components/Attendance/constant";
import { getCurrentDay } from "timesheets/utils";
export const TimeSheetContext = createContext<any>({});

interface ITimeSheetContext {
	downloadTimeSheet: any;
	setDownloadTimeSheet: (value: any) => any;
	uploadTimeSheet: any;
	setUploadTimeSheet: (value: any) => any;
	paymentList: any;
	setPaymentList: (value: any) => any;
	paymentDetails: any;
	setPaymentDetails: (value: any) => any;
	paymentDetailsLineList: any;
	setPaymentDetailsLineList: (value: any) => any;
	selectedPaymentLine: any;
	setSelectedPaymentLine: (value: any) => any;
	selectedInvoiceLine: any;
	setSelectedInvoiceLine: (value: any) => any;
	emailChecked: boolean;
	setEmailChecked: (value: boolean) => boolean;
	invoicesDetails: any;
	setInvoicesDetails: (value: any) => any;
	invoicesDetailsList: any;
	setInvoicesDetailsList: (value: any) => any;
	emailVerified: any;
	setEmailVerified: (value: any) => any;
	dashboardData: any;
	setDashboardData: (value: any) => any;
	currentDashboard: any;
	setCurrentDashboard: (value: any) => any;
	openSnackbarSuccess: boolean;
	setOpenSnackbarSuccess: (value: boolean) => boolean;
	openSnackbarFail: boolean;
	setOpenSnackbarFail: (value: boolean) => boolean;
	trackingCheckbox: any;
	setTrackingCheckbox: (value: any) => any;
	companyType: string;
	setCompanyType: (value: string) => string;
	wsStep: string;
	setWsStep: (value: string) => string;
	roleStep: string;
	setRoleStep: (value: string) => string;
	currentRole: any;
	setCurrentRole: (value: any) => any;
	timeSheets: any;
	setTimeSheets: (value: any) => any;
	timeSheetsForApproval: any;
	setTimeSheetsForApproval: (value: any) => any;
	timeSheetSelected: any;
	setTimeSheetSelected: (value: any) => any;
	timeSheetDetail: any;
	setTimeSheetDetail: (value: any) => any;
	companyInformation: any;
	setCompanyInformation: (value: any) => any;
	companyDepartmentInformation: any;
	setCompanyDepartmentInformation: (value: any) => any;
	companyDepartmentSelected: any;
	setCompanyDepartmentSelected: (value: any) => any;
	appInfo: any;
	setAppInfo: (value: any) => any;
	appSelected: any;
	setAppSelected: (value: any) => any;
	members: any;
	setMembers: (value: any) => any;
	clientMembers: any;
	setClientMembers: (value: any) => any;
	vendorMembers: any;
	setVendorMembers: (value: any) => any;
	externalManagers: any;
	setExternalManagers: (value: any) => any;
	archieveTeamMembers: any;
	setArchieveTeamMembers: (value: any) => any;
	memberSelected: any;
	setMemberSelected: (value: any) => any;
	memberDataToAsso: any;
	setMemberDataToAsso: (value: any) => any;
	assoSelected: any;
	setAssoSelected: (value: any) => any;
	clients: any;
	setClients: (value: any) => any;
	clientIsCompany: any;
	setClientsIsCompany: (value: any) => any;
	clientSelected: any;
	setClientSelected: (value: any) => any;
	clientManagers: any;
	setClientManagers: (value: any) => any;
	vendors: any;
	setVendors: (value: any) => any;
	vendorSelected: any;
	setVendorSelected: (value: any) => any;
	projectCode: any;
	setProjectCode: (value: any) => any;
	projectCodeSelected: any;
	setProjectCodeSelected: (value: any) => any;
	roles: any;
	setRoles: (value: any) => any;
	roleSelected: any;
	setRoleSelected: (value: any) => any;
	companyDistributionInfo: any;
	setCompanyDistributionInfo: (value: any) => any;
	clientDistributionInfo: any;
	setClientDistributionInfo: (value: any) => any;
	tsDetail: any;
	setTsDetail: (value: any) => any;
	applsScreen: any;
	setApplsScreen: (value: any) => any;
	applsScreenSelected: any;
	setApplsScreenSelected: (value: any) => any;
	screenRoleMappingForTeamMember: any;
	setScreenRoleMappingForTeamMember: (value: any) => any;
	screenRoleMapping: any;
	setScreenRoleMapping: (value: any) => any;
	screenRoleMappingSelected: any;
	setScreenRoleMappingSelected: (value: any) => any;
	screenFieldRoleMapping: any;
	setScreenFieldRoleMapping: (value: any) => any;
	screenFieldRoleMappingSelected: any;
	setScreenFieldRoleMappingSelected: (value: any) => any;
	authenticationRoles: any;
	setAuthenticationRoles: (value: any) => any;
	authenticationRolesSelected: any;
	setAuthenticationRolesSelected: (value: any) => any;
	isMobile: boolean;
	setIsMobile: (value: boolean) => boolean;
	isToggle: boolean;
	setIsToggle: (value: boolean) => boolean;
	isToken: boolean;
	setIsToken: (value: boolean) => boolean;
	timeSheetDetailsFormat: any;
	setTimeSheetDetailsFormat: (value: any) => any;
	isLoading: boolean;
	setIsLoading: (value: boolean) => boolean;
	categories: any;
	setCategories: (value: any) => any;
	categorySelected: any;
	setCategorySelected: (value: any) => any;
	assignments: any;
	setAssignments: (value: any) => any;
	assignmentSelected: any;
	setAssignmentSelected: (value: any) => any;
	snackbarContentFail: string;
	setSnackbarContentFail: (value: string) => string;
	snackbarContentSuccess: string;
	setSnackbarContentSuccess: (value: string) => string;
	triggerTokenTimeSheet: boolean;
	setTriggerTokenTimeSheet: (value: boolean) => boolean;
	triggerTokenCompany: boolean;
	setTriggerTokenCompany: (value: boolean) => boolean;
	timeSheetStatus: any;
	setTimeSheetStatus: (value: any) => any;
	snackbarWarning: any;
	setSnackbarWarning: (value: any) => any;
	teamAssociate: any;
	setTeamAssociate: (value: any) => any;
	signOut: any;
	setSignOut: (value: any) => any;
	teamAssociateProfile: any;
	setTeamAssociateProfile: (value: any) => any;
	screenPermission: any;
	setScreenPermission: (value: any) => any;
	timeSheetReport: any;
	setTimeSheetReport: (value: any) => any;
	timeSheetFile: any;
	setTimeSheetFile: (value: any) => any;
	selectedFileTimeSheet: any;
	setSelectedFileTimeSheet: (value: any) => any;
	fileChooseTemp: any;
	setFileChooseTemp: (value: any) => any;
	ptoForTeamAssociate: any;
	setPtoForTeamAssociate: (value: any) => any;
	ptoForManager: any;
	setPtoForManager: (value: any) => any;
	ptoSelected: any;
	setPtoSelected: (value: any) => any;
	ptoAccepted: any;
	setPtoAccepted: (value: any) => any;
	assignmentsForTeamAssociate: any;
	setAssignmentForTeamAssociate: (value: any) => any;
	projectCodeByAssignments: any;
	setProjectCodeByAssignments: (value: any) => any;
	assignmentDetail: any;
	setAssignmentDetail: (value: any) => any;
	signUpAuth: any;
	setSignUpAuth: (value: any) => any;
	authInfo: any;
	setAuthInfo: (value: any) => any;
	otpInfo: any;
	setOtpInfo: (value: any) => any;
	isCheckOtp: boolean;
	setIsCheckOtp: (value: boolean) => boolean;
	isAuth: any;
	setIsAuth: (value: any) => any;
	isGlobalLoading: boolean;
	setIsGlobalLoading: (value: boolean) => boolean;
	financeVendor: any;
	setFinanceVendor: (value: any) => any;
	financeVendorForVendor: any;
	setFinanceVendorForVendor: (value: any) => any;
	vendorRateSelected: any;
	setVendorRateSelected: (value: any) => any;
	financeVendorForTeamAssociate: any;
	setFinanceVendorForTeamAssociate: (value: any) => any;
	completeNewPass: any;
	setCompleteNewPass: (value: any) => any;
	financeClient: any;
	setFinanceClient: (value: any) => any;
	financeClientForClient: any;
	setFinanceClientForClient: (value: any) => any;
	financeClientSelected: any;
	setFinanceClientSelected: (value: any) => any;
	financeClientForTeamAssociate: any;
	setFinanceClientForTeamAssociate: (value: any) => any;
	tokenCommunications: string;
	setTokenCommunications: (value: string) => string;
	teamAssociateForClient: any;
	setTeamAssociateForClient: (value: any) => any;
	openPositionsList: any;
	setOpenPositionsList: (value: any) => any;
	assignmentsForClient: any;
	setAssignmentsForClient: (value: any) => any;
	teamMemberFilter: string;
	setTeamMemberFilter: (value: string) => string;
	assignmentRateDetails: any;
	setAssignmentRateDetails: (value: any) => any;
	externalMembers: any;
	setExternalMembers: (value: any) => any;
	membersForClient: any;
	setMembersForClient: (value: any) => any;
	invoicesList: any;
	setInvoicesList: (value: any) => any;
	clientRateSelected: any;
	setClientRateSelected: (value: any) => any;
	clientRatesForClient: any;
	setClientRatesForClient: (value: any) => any;
	vendorRateForVendor: any;
	setVendorRateForVendor: (value: any) => any;
	breadcrumbs: any;
	setBreadcrumbs: (value: any) => any;
	membersForVendor: any;
	setMembersForVendor: (value: any) => any;
	invoiceSelected: any;
	setInvoiceSelected: (value: any) => any;
	paymentSelected: any;
	setPaymentSelected: (value: any) => any;
	timeSheetFilterParams: any;
	setTimeSheetFilterParams: (value: any) => any;
	appPhaseList: any;
	setAppPhaseList: (value: any) => any;
	appPhaseSelected: any;
	setAppPhaseSelected: (value: any) => any;
	businessCapabilityList: any;
	setBusinessCapabilityList: (value: any) => any;
	businessCapabilitySelected: any;
	setBusinessCapabilitySelected: (value: any) => any;
	businessCapCategoryList: any;
	setBusinessCapCategoryList: (value: any) => any;
	businessCapCategorySelected: any;
	setBusinessCapCategorySelected: (value: any) => any;
	snackbarContentArraySuccess: any;
	setSnackbarContentArraySuccess: (value: any) => any;
	snackbarContentArrayFail: any;
	setSnackbarContentArrayFail: (value: any) => any;
	employeeSalaryData: any;
	setEmployeeSalaryData: (value: any) => any;
	employeeComponentData: any;
	setEmployeeComponentData: (value: any) => any;
	employeeSelected: any;
	setEmployeeSelected: (value: any) => any;
	empMarginSelected: any;
	setEmpMarginSelected: (value: any) => any;
	employeeSalaryMarginData: any;
	setEmployeeSalaryMarginData: (value: any) => any;
	salarySelected: any;
	setSalarySelected: (value: any) => any;
	vendorRateMarginSelected: any;
	setVendorRateMarginSelected: (value: any) => any;
	singleVendorRateMarginSelected: any;
	setSingleVendorRateMarginSelected: (value: any) => any;
	currencyList: any;
	setCurrencyList: (value: any) => any;
	currencySelected: any;
	setCurrencySelected: (value: any) => any;
	flagLeftSideBar: boolean;
	setFlagLeftSideBar: (value: boolean) => boolean;
	salaryList: any;
	setSalaryList: (value: any) => any;
	attendanceList: any;
	setAttendanceList: (value: any) => any;
	managerTeamAttendanceData: any;
	setManagerTeamAttendanceData: (value: any) => any;
	paymentLineSelected: any;
	setPaymentLineSelected: (value: any) => any;
	reportAttendanceList: any;
	setReportAttendanceList: (value: any) => any;
	reportAttendanceFilter: any;
	setReportAttendanceFilter: (value: any) => any;
	clientRateByAssignment: any;
	setClientRateByAssignment: (value: any) => any;
	countryList: any;
	setCountryList: (value: any) => any;
	countrySelected: any;
	setCountrySelected: (value: any) => any;
	compensationList: any;
	setCompensationList: (value: any) => any;
	countryCompensationList: any;
	setCountryCompensationList: (value: any) => any;
	compensationDetailsList: any;
	setCompensationDetailsList: (value: any) => any;
}

export const TimeSheetContextProvider = ({ children }: any) => {
	const { tokenCompany, _tokenCommunications } = helperToken();
	const [tokenCommunications, setTokenCommunications] = useState<any>("");
	const [wsStep, setWsStep] = useState<"CHOOSE_WS" | "WS">("CHOOSE_WS");
	const [roleStep, setRoleStep] = useState<"CHOOSE_ROLE" | "ROLE">("ROLE");
	const [currentRole, setCurrentRole] = useState<any>(null);
	const [trackingCheckbox, setTrackingCheckbox] = useState<boolean>(false);
	const [emailChecked, setEmailChecked] = useState<boolean>(false);
	const [companyType, setCompanyType] = useState<"END_CLIENT" | "INTEGRATION_PARTNER">();
	const [timeSheets, setTimeSheets] = useState<any[]>([]);
	const [timeSheetsForApproval, setTimeSheetsForApproval] = useState<any[]>([]);
	const [timeSheetSelected, setTimeSheetSelected] = useState<any>({});
	const [timeSheetDetail, setTimeSheetDetail] = useState<any>({});
	const [companyInformation, setCompanyInformation] = useState<any>({});
	const [companyDistributionInfo, setCompanyDistributionInfo] = useState<any>({});
	const [clientDistributionInfo, setClientDistributionInfo] = useState<any>({});
	const [companyDepartmentInformation, setCompanyDepartmentInformation] = useState<any>([]);
	const [companyDepartmentSelected, setCompanyDepartmentSelected] = useState<any>({});
	const [appInfo, setAppInfo] = useState<any>([]);
	const [appSelected, setAppSelected] = useState<any>({});
	const [members, setMembers] = useState<any>([]);
	const [clientMembers, setClientMembers] = useState<any>([]);
	const [vendorMembers, setVendorMembers] = useState<any>([]);
	const [externalManagers, setExternalManagers] = useState<any>([]);
	const [externalMembers, setExternalMembers] = useState<any>([]);
	const [memberSelected, setMemberSelected] = useState<any>({});
	const [memberDataToAsso, setMemberDataToAsso] = useState<any>({});
	const [teamAssociate, setTeamAssociate] = useState<any>({});
	const [assoSelected, setAssoSelected] = useState<any>({});
	const [clients, setClients] = useState<any>([]);
	const [clientIsCompany, setClientsIsCompany] = useState<any>({});
	const [clientSelected, setClientSelected] = useState<any>({});
	const [clientManagers, setClientManagers] = useState<any>([]);
	const [vendors, setVendors] = useState<any>([]);
	const [vendorSelected, setVendorSelected] = useState<any>({});
	const [projectCode, setProjectCode] = useState<any[]>([]);
	const [projectCodeSelected, setProjectCodeSelected] = useState<any>({});
	const [roles, setRoles] = useState<any[]>([]);
	const [roleSelected, setRoleSelected] = useState<any>({});
	const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState<boolean>(false);
	const [openSnackbarFail, setOpenSnackbarFail] = useState<boolean>(false);
	const [tsDetail, setTsDetail] = useState<any>([]);
	const [applsScreen, setApplsScreen] = useState<any[]>([]);
	const [applsScreenSelected, setApplsScreenSelected] = useState<any>({});
	const [screenRoleMappingForTeamMember, setScreenRoleMappingForTeamMember] = useState<any[]>([]);
	const [screenRoleMapping, setScreenRoleMapping] = useState<any[]>([]);
	const [screenRoleMappingSelected, setScreenRoleMappingSelected] = useState<any>({});
	const [screenFieldRoleMapping, setScreenFieldRoleMapping] = useState<any[]>([]);
	const [screenFieldRoleMappingSelected, setScreenFieldRoleMappingSelected] = useState<any>({});
	const [authenticationRoles, setAuthenticationRoles] = useState<any[]>([]);
	const [authenticationRolesSelected, setAuthenticationRolesSelected] = useState<any>({});
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [isToggle, setIsToggle] = useState<boolean>(false);
	const [isToken, setIsToken] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [snackbarContentFail, setSnackbarContentFail] = useState<string>("");
	const [snackbarContentSuccess, setSnackbarContentSuccess] = useState<string>("");
	const [snackbarContentArraySuccess, setSnackbarContentArraySuccess] = useState<any>([]);
	const [snackbarContentArrayFail, setSnackbarContentArrayFail] = useState<any>([]);
	const [timeSheetDetailsFormat, setTimeSheetDetailsFormat] = useState<"NEW" | "DEFAULT">("DEFAULT");
	const [categories, setCategories] = useState<any[]>([]);
	const [categorySelected, setCategorySelected] = useState<any>({});
	const [assignments, setAssignments] = useState<any[]>([]);
	const [assignmentSelected, setAssignmentSelected] = useState<any>({});
	const [assignmentsForTeamAssociate, setAssignmentForTeamAssociate] = useState<any[]>([]);
	const [assignmentsForClient, setAssignmentsForClient] = useState<any[]>([]);
	const [archieveTeamMembers, setArchieveTeamMembers] = useState<any[]>([]);
	const [timeSheetStatus, setTimeSheetStatus] = useState<any[]>([]);
	const [triggerTokenTimeSheet, setTriggerTokenTimeSheet] = useState<boolean>(false);
	const [triggerTokenCompany, setTriggerTokenCompany] = useState<boolean>(false);
	const [snackbarWarning, setSnackbarWarning] = useState<any>({
		status: false,
		content: "",
	});
	const [signOut, setSignOut] = useState<any>();
	const [teamAssociateProfile, setTeamAssociateProfile] = useState<any>({});
	const [timeSheetFile, setTimeSheetFile] = useState<any>({});
	const [fileChooseTemp, setFileChooseTemp] = useState<any>({});
	const [selectedFileTimeSheet, setSelectedFileTimeSheet] = useState<any>();
	const [screenPermission, setScreenPermission] = useState<any>([]);
	const [timeSheetReport, setTimeSheetReport] = useState<any>([]);
	const [ptoForTeamAssociate, setPtoForTeamAssociate] = useState<any>([]);
	const [ptoForManager, setPtoForManager] = useState<any>([]);
	const [ptoSelected, setPtoSelected] = useState<any>({});

	const [financeVendor, setFinanceVendor] = useState<any>([]);
	const [financeVendorForVendor, setFinanceVendorForVendor] = useState<any>([]);
	const [vendorRateSelected, setVendorRateSelected] = useState<any>({});
	const [clientRateSelected, setClientRateSelected] = useState<any>({});
	const [clientRatesForClient, setClientRatesForClient] = useState<any>([]);
	const [vendorRateForVendor, setVendorRateForVendor] = useState<any>([]);

	const [financeVendorForTeamAssociate, setFinanceVendorForTeamAssociate] = useState<any>([]);

	const [financeClient, setFinanceClient] = useState<any>([]);
	const [financeClientForClient, setFinanceClientForClient] = useState<any>([]);
	const [financeClientSelected, setFinanceClientSelected] = useState<any>({});

	const [clientMemberSelected, setClientMemberSelected] = useState<any>({});
	const [breadcrumbs, setBreadcrumbs] = useState<any>([]);

	const [financeClientForTeamAssociate, setFinanceClientForTeamAssociate] = useState<any>([]);

	const [teamAssociateForClient, setTeamAssociateForClient] = useState<any>([]);

	const [ptoAccepted, setPtoAccepted] = useState<any>([]);
	const [projectCodeByAssignments, setProjectCodeByAssignments] = useState<any>([]);
	const [assignmentDetail, setAssignmentDetail] = useState<any>({});
	const [completeNewPass, setCompleteNewPass] = useState<any>({});

	// Recruitment
	const [openPositionsList, setOpenPositionsList] = useState<any>([]);

	//Member for client
	const [membersForClient, setMembersForClient] = useState<any>([]);
	const [membersForVendor, setMembersForVendor] = useState<any>([]);

	//Auth
	const [signUpAuth, setSignUpAuth] = useState<any>({
		emailDes: "",
		username: "",
		password: "",
	});
	const [assignmentRateDetails, setAssignmentRateDetails] = useState<any>({});
	const [authInfo, setAuthInfo] = useState<any>({});
	const [otpInfo, setOtpInfo] = useState<any>({});
	const [isCheckOtp, setIsCheckOtp] = useState<boolean>(false);
	const [isAuth, setIsAuth] = useState<boolean>(false);
	const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
	const [currentDashboard, setCurrentDashboard] = useState(1);
	const [dashboardData, setDashboardData] = useState({});
	const [emailVerified, setEmailVerified] = useState(true);
	const [clientRateByAssignment, setClientRateByAssignment] = useState<any>({});

	const [teamMemberFilter, setTeamMemberFilter] = useState<string>("");
	const [invoicesDetails, setInvoicesDetails] = useState<any>({});
	const [invoicesDetailsList, setInvoicesDetailsList] = useState<any>([]);

	const [invoiceSelected, setInvoiceSelected] = useState<any>({});

	const [paymentList, setPaymentList] = useState<any>([]);
	const [paymentSelected, setPaymentSelected] = useState<any>({});

	const [paymentDetails, setPaymentDetails] = useState<any>({});
	const [paymentDetailsLineList, setPaymentDetailsLineList] = useState<any>([]);
	const [appPhaseList, setAppPhaseList] = useState<any>([]);
	const [appPhaseSelected, setAppPhaseSelected] = useState<any>({});
	const [businessCapabilityList, setBusinessCapabilityList] = useState<any>([]);
	const [businessCapabilitySelected, setBusinessCapabilitySelected] = useState<any>({});
	const [businessCapCategoryList, setBusinessCapCategoryList] = useState<any>([]);
	const [businessCapCategorySelected, setBusinessCapCategorySelected] = useState<any>({});

	const [salarySelected, setSalarySelected] = useState<any>({});

	const [timeSheetFilterParams, setTimeSheetFilterParams] = useState<any>({
		currentClientId: "ALL",
		timeSheetStatus: "ALL",
		reportView: "MONTHLY",
		timeSheetFrequency: "MONTHLY",
		startDate: "",
		currentTeamAssociateId: "ALL",
		memberList: [{ value: "ALL", label: "All" }],
		memberSelected: {},
	});
	
	const { startDateOfMonth, endDateOfMonth } = getCurrentDay();
	const [reportAttendanceFilter, setReportAttendanceFilter] = useState<any>({
		frequency: "MONTHLY",
		startDate: startDateOfMonth,
		endDate: endDateOfMonth,
		memberList: [{ value: "ALL", label: "All Member" }],
		memberMultiSelected: [{ value: "ALL", label: "All Member" }],
		managerList: [{ value: "ALL", label: "All Manager" }],
		managerSelected: { value: "ALL", label: "All Manager" },
	});

	const [reportAttendanceList, setReportAttendanceList] = useState<any>([]);
	// create variable for upload
	const [uploadTimeSheet, setUploadTimeSheet] = useState<any>([{}])
	const [downloadTimeSheet, setDownloadTimeSheet] = useState<any>([])
	const [attendanceList, setAttendanceList] = useState<any>([]);
	const [managerTeamAttendanceData, setManagerTeamAttendanceData] = useState<any>([]);


	const timeRefresh = 1500000;

	//clear data when logout
	useEffect(() => {
		if (!isAuth) {
			setCompanyInformation({});
			setScreenRoleMappingForTeamMember([]);
		}
	}, [isAuth]);

	const [invoicesList, setInvoicesList] = useState<any>([]);
	const [selectedInvoiceLine, setSelectedInvoiceLine] = useState<any>({
		teamAssociateName: "",
		totalBillableHours: "",
		totalOvertimeHours: "",
		normalRates: "",
		overtimeRates: "",
		totalBill: "",
	});
	const [selectedPaymentLine, setSelectedPaymentLine] = useState<any>({
		teamAssociateName: "",
		totalBillableHours: "",
		totalOvertimeHours: "",
		normalRates: "",
		overtimeRates: "",
		totalBill: "",
	});

	const [employeeSalaryData, setEmployeeSalaryData] = useState<any>([]);
	const [employeeSelected, setEmployeeSelected] = useState<any>({});
	const [employeeComponentData, setEmployeeComponentData] = useState<any>([]);
	const [empMarginSelected, setEmpMarginSelected] = useState<any>([]);
	const [employeeSalaryMarginData, setEmployeeSalaryMarginData] = useState<any>([]);
	const [vendorRateMarginSelected, setVendorRateMarginSelected] = useState<any>([]);
	const [singleVendorRateMarginSelected, setSingleVendorRateMarginSelected] = useState<any>([]);
	const [flagLeftSideBar, setFlagLeftSideBar] = useState<boolean>(false);
	const [paymentLineSelected, setPaymentLineSelected] = useState<any>({});

	//currency
	const [currencySelected, setCurrencySelected] = useState<any>({});
	const [currencyList, setCurrencyList] = useState<any>([]);
	const [salaryList, setSalaryList] = useState<any>([]);

	const [countryList, setCountryList] = useState<any>([]);
	const [countrySelected, setCountrySelected] = useState<any>({});
	const [compensationList, setCompensationList] = useState<any>([]);

	const [countryCompensationList, setCountryCompensationList] = useState<any>([]);
	const [compensationDetailsList, setCompensationDetailsList] = useState<any>([]);

	const memoizedValue = useMemo(
		() => ({
			downloadTimeSheet,
			setDownloadTimeSheet,
			uploadTimeSheet,
			setUploadTimeSheet,
			paymentList,
			setPaymentList,
			paymentDetails,
			setPaymentDetails,
			paymentDetailsLineList,
			setPaymentDetailsLineList,
			selectedPaymentLine,
			setSelectedPaymentLine,
			selectedInvoiceLine,
			setSelectedInvoiceLine,
			emailChecked,
			setEmailChecked,
			invoicesDetails,
			setInvoicesDetails,
			invoicesDetailsList,
			setInvoicesDetailsList,
			emailVerified,
			setEmailVerified,
			dashboardData,
			setDashboardData,
			currentDashboard,
			setCurrentDashboard,
			openSnackbarSuccess,
			setOpenSnackbarSuccess,
			openSnackbarFail,
			setOpenSnackbarFail,
			trackingCheckbox,
			setTrackingCheckbox,
			companyType,
			setCompanyType,
			wsStep,
			setWsStep,
			roleStep,
			setRoleStep,
			currentRole,
			setCurrentRole,
			timeSheets,
			setTimeSheets,
			timeSheetsForApproval,
			setTimeSheetsForApproval,
			timeSheetSelected,
			setTimeSheetSelected,
			timeSheetDetail,
			setTimeSheetDetail,
			companyInformation,
			setCompanyInformation,
			companyDepartmentInformation,
			setCompanyDepartmentInformation,
			companyDepartmentSelected,
			setCompanyDepartmentSelected,
			appInfo,
			setAppInfo,
			appSelected,
			setAppSelected,
			members,
			setMembers,
			clientMembers,
			setClientMembers,
			vendorMembers,
			setVendorMembers,
			externalManagers,
			setExternalManagers,
			archieveTeamMembers,
			setArchieveTeamMembers,
			memberSelected,
			setMemberSelected,
			memberDataToAsso,
			setMemberDataToAsso,
			assoSelected,
			setAssoSelected,
			clients,
			setClients,
			clientIsCompany,
			setClientsIsCompany,
			clientSelected,
			setClientSelected,
			clientManagers,
			setClientManagers,
			vendors,
			setVendors,
			vendorSelected,
			setVendorSelected,
			projectCode,
			setProjectCode,
			projectCodeSelected,
			setProjectCodeSelected,
			roles,
			setRoles,
			roleSelected,
			setRoleSelected,
			companyDistributionInfo,
			setCompanyDistributionInfo,
			clientDistributionInfo,
			setClientDistributionInfo,
			tsDetail,
			setTsDetail,
			applsScreen,
			setApplsScreen,
			applsScreenSelected,
			setApplsScreenSelected,
			screenRoleMappingForTeamMember,
			setScreenRoleMappingForTeamMember,
			screenRoleMapping,
			setScreenRoleMapping,
			screenRoleMappingSelected,
			setScreenRoleMappingSelected,
			screenFieldRoleMapping,
			setScreenFieldRoleMapping,
			screenFieldRoleMappingSelected,
			setScreenFieldRoleMappingSelected,
			authenticationRoles,
			setAuthenticationRoles,
			authenticationRolesSelected,
			setAuthenticationRolesSelected,
			isMobile,
			setIsMobile,
			isToggle,
			setIsToggle,
			isToken,
			setIsToken,
			timeSheetDetailsFormat,
			setTimeSheetDetailsFormat,
			isLoading,
			setIsLoading,
			categories,
			setCategories,
			categorySelected,
			setCategorySelected,
			assignments,
			setAssignments,
			assignmentSelected,
			setAssignmentSelected,
			snackbarContentFail,
			setSnackbarContentFail,
			snackbarContentSuccess,
			setSnackbarContentSuccess,
			setTriggerTokenTimeSheet,
			setTriggerTokenCompany,
			triggerTokenTimeSheet,
			triggerTokenCompany,
			timeSheetStatus,
			setTimeSheetStatus,
			snackbarWarning,
			setSnackbarWarning,
			teamAssociate,
			setTeamAssociate,
			signOut,
			setSignOut,
			teamAssociateProfile,
			setTeamAssociateProfile,
			screenPermission,
			setScreenPermission,
			timeSheetReport,
			setTimeSheetReport,
			timeSheetFile,
			setTimeSheetFile,
			selectedFileTimeSheet,
			setSelectedFileTimeSheet,
			fileChooseTemp,
			setFileChooseTemp,
			ptoForTeamAssociate,
			setPtoForTeamAssociate,
			ptoForManager,
			setPtoForManager,
			ptoSelected,
			setPtoSelected,
			ptoAccepted,
			setPtoAccepted,
			assignmentsForTeamAssociate,
			setAssignmentForTeamAssociate,
			projectCodeByAssignments,
			setProjectCodeByAssignments,
			assignmentDetail,
			setAssignmentDetail,
			signUpAuth,
			setSignUpAuth,
			authInfo,
			setAuthInfo,
			otpInfo,
			setOtpInfo,
			isCheckOtp,
			setIsCheckOtp,
			isAuth,
			setIsAuth,
			isGlobalLoading,
			setIsGlobalLoading,
			financeVendor,
			setFinanceVendor,
			financeVendorForVendor,
			setFinanceVendorForVendor,
			vendorRateSelected,
			setVendorRateSelected,
			financeVendorForTeamAssociate,
			setFinanceVendorForTeamAssociate,
			completeNewPass,
			setCompleteNewPass,
			financeClient,
			setFinanceClient,
			financeClientForClient,
			setFinanceClientForClient,
			financeClientSelected,
			setFinanceClientSelected,
			financeClientForTeamAssociate,
			setFinanceClientForTeamAssociate,
			tokenCommunications,
			setTokenCommunications,
			teamAssociateForClient,
			setTeamAssociateForClient,
			openPositionsList,
			setOpenPositionsList,
			assignmentsForClient,
			setAssignmentsForClient,
			teamMemberFilter,
			setTeamMemberFilter,
			assignmentRateDetails,
			setAssignmentRateDetails,
			externalMembers,
			setExternalMembers,
			membersForClient,
			setMembersForClient,
			invoicesList,
			setInvoicesList,
			clientRateSelected,
			setClientRateSelected,
			clientRatesForClient,
			setClientRatesForClient,
			vendorRateForVendor,
			setVendorRateForVendor,
			breadcrumbs,
			setBreadcrumbs,
			membersForVendor,
			setMembersForVendor,
			invoiceSelected,
			setInvoiceSelected,
			paymentSelected,
			setPaymentSelected,
			timeSheetFilterParams,
			setTimeSheetFilterParams,
			appPhaseList,
			setAppPhaseList,
			appPhaseSelected,
			setAppPhaseSelected,
			businessCapabilityList,
			setBusinessCapabilityList,
			businessCapabilitySelected,
			setBusinessCapabilitySelected,
			businessCapCategoryList,
			setBusinessCapCategoryList,
			businessCapCategorySelected,
			setBusinessCapCategorySelected,
			snackbarContentArraySuccess,
			setSnackbarContentArraySuccess,
			snackbarContentArrayFail,
			setSnackbarContentArrayFail,
			employeeSalaryData,
			setEmployeeSalaryData,
			employeeComponentData,
			setEmployeeComponentData,
			employeeSelected,
			setEmployeeSelected,
			empMarginSelected,
			setEmpMarginSelected,
			employeeSalaryMarginData,
			setEmployeeSalaryMarginData,
			salarySelected,
			setSalarySelected,
			vendorRateMarginSelected,
			setVendorRateMarginSelected,
			singleVendorRateMarginSelected,
			setSingleVendorRateMarginSelected,
			currencyList,
			setCurrencyList,
			currencySelected,
			setCurrencySelected,
			flagLeftSideBar,
			setFlagLeftSideBar,
			salaryList,
			setSalaryList,
			attendanceList,
			setAttendanceList,
			managerTeamAttendanceData,
			setManagerTeamAttendanceData,
			paymentLineSelected,
			setPaymentLineSelected,
			reportAttendanceList,
			setReportAttendanceList,
			reportAttendanceFilter,
			setReportAttendanceFilter,
			clientRateByAssignment,
			setClientRateByAssignment,
			countryList,
			setCountryList,
			countrySelected,
			setCountrySelected,
			compensationList,
			setCompensationList,
			countryCompensationList,
			setCountryCompensationList,
			compensationDetailsList,
			setCompensationDetailsList,
		}),
		[
			compensationDetailsList,
			countryCompensationList,
			compensationList,
			countrySelected,
			countryList,
			clientRateByAssignment,
			reportAttendanceFilter,
			reportAttendanceList,
			paymentLineSelected,
			attendanceList,
			flagLeftSideBar,
			currencyList,
			currencySelected,
			salarySelected,
			downloadTimeSheet,
			uploadTimeSheet,
			businessCapCategorySelected,
			businessCapCategoryList,
			businessCapabilitySelected,
			businessCapabilityList,
			appPhaseSelected,
			appPhaseList,
			paymentList,
			paymentDetails,
			paymentDetailsLineList,
			selectedPaymentLine,
			selectedInvoiceLine,
			breadcrumbs,
			emailChecked,
			invoicesDetails,
			invoicesDetailsList,
			emailVerified,
			invoicesList,
			dashboardData,
			currentDashboard,
			openSnackbarSuccess,
			openSnackbarFail,
			trackingCheckbox,
			companyType,
			wsStep,
			roleStep,
			currentRole,
			timeSheets,
			timeSheetsForApproval,
			timeSheetSelected,
			timeSheetDetail,
			companyInformation,
			companyDepartmentInformation,
			companyDepartmentSelected,
			appSelected,
			appInfo,
			members,
			clientMembers,
			vendorMembers,
			externalManagers,
			memberSelected,
			memberDataToAsso,
			assoSelected,
			clients,
			clientIsCompany,
			clientSelected,
			clientManagers,
			vendors,
			vendorSelected,
			projectCode,
			projectCodeSelected,
			roles,
			roleSelected,
			companyDistributionInfo,
			tsDetail,
			screenRoleMappingForTeamMember,
			applsScreen,
			applsScreenSelected,
			screenRoleMapping,
			screenRoleMappingSelected,
			screenFieldRoleMapping,
			screenFieldRoleMappingSelected,
			authenticationRoles,
			authenticationRolesSelected,
			isMobile,
			isToggle,
			isToken,
			timeSheetDetailsFormat,
			isLoading,
			categories,
			categorySelected,
			assignments,
			assignmentSelected,
			archieveTeamMembers,
			snackbarContentFail,
			snackbarContentSuccess,
			triggerTokenTimeSheet,
			triggerTokenCompany,
			timeSheetStatus,
			snackbarWarning,
			teamAssociate,
			signOut,
			teamAssociateProfile,
			screenPermission,
			timeSheetReport,
			timeSheetFile,
			selectedFileTimeSheet,
			fileChooseTemp,
			ptoForTeamAssociate,
			ptoForManager,
			ptoSelected,
			ptoAccepted,
			assignmentsForTeamAssociate,
			projectCodeByAssignments,
			assignmentDetail,
			signUpAuth,
			authInfo,
			otpInfo,
			isCheckOtp,
			isAuth,
			isGlobalLoading,
			financeVendor,
			financeVendorForVendor,
			financeVendorForTeamAssociate,
			vendorRateSelected,
			completeNewPass,
			financeClient,
			financeClientForClient,
			financeClientSelected,
			financeClientForTeamAssociate,
			tokenCommunications,
			teamAssociateForClient,
			openPositionsList,
			assignmentsForClient,
			teamMemberFilter,
			assignmentRateDetails,
			externalMembers,
			membersForClient,
			clientRateSelected,
			clientRatesForClient,
			vendorRateForVendor,
			membersForVendor,
			invoiceSelected,
			paymentSelected,
			timeSheetFilterParams,
			employeeSalaryData,
			employeeComponentData,
			employeeSelected,
			empMarginSelected,
			employeeSalaryMarginData,
			snackbarContentArrayFail,
			vendorRateMarginSelected,
			singleVendorRateMarginSelected,
			salaryList,
			managerTeamAttendanceData,
		]
	);

	//get responsive for left side bar
	const matches = useMediaQuery("(min-width:768px)");
	useEffect(() => {
		if (matches) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
		}
	}, [matches]);

	useEffect(() => {
		tokenCompany(setIsToken, setTriggerTokenCompany, triggerTokenCompany);
		_tokenCommunications(setTokenCommunications);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			tokenCompany(setIsToken, setTriggerTokenCompany, triggerTokenCompany);
			_tokenCommunications(setTokenCommunications);
		}, timeRefresh);
	}, [triggerTokenCompany]);

	// prevent memory leak
	const [didMount, setDidMount] = useState(false);
	useEffect(() => {
		setDidMount(true);
		return () => setDidMount(false);
	}, []);

	if (!didMount) {
		return null;
	}

	return (
		<TimeSheetContext.Provider value={memoizedValue}>
			{children}
		</TimeSheetContext.Provider>
	);
};

export const useTimeSheetContext = (): ITimeSheetContext => useContext(TimeSheetContext);
