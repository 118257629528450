import { makeStyles } from "@material-ui/core";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";
import { Auth } from "aws-amplify";
import FormItemLabel from "common/components/FormItemLabel";
import NotifyText from "common/components/NotifyText";
import UgotaGiftButton from "common/components/UgotaGiftButton";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { GridContainerStyled } from "timesheets/styles/common";
import * as Yup from "yup";
import { applActionCreators } from "../../api/companyActions";
const useStyles = makeStyles({
  wrapperConfirmSignup: {
    padding: "58px",
    position: "relative",
    borderRadius: "24px",
    background: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  },
});

const VerifyEmailForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useTimeSheetContext();
  const {
    signUpAuth,
    setIsAuth,
    setAuthInfo,
    authInfo,
    setOpenSnackbarSuccess,
    setEmailVerified,
    setSnackbarContentSuccess,
    setOpenSnackbarFail,
    setSnackbarContentFail,
  } = context;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const {
    actionCreators: { checkEmailVerify },
  } = applActionCreators();
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Require!"),
    }),
    onSubmit: async (values) => {
      const { code } = values;
      handleSubmit({ code });
    },
  });

  const handleSubmit = (payload) => {
    setLoading(true);
    const { code } = payload;
    Auth.verifyCurrentUserAttributeSubmit("email", code)
      .then(() => {
        setLoading(false);
        setSnackbarContentSuccess("Email verified successfully!");
        setOpenSnackbarSuccess(true);
        window.location.href = "/";
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        width: "100%",
      }}
    >
      <Box
        sx={{ boxShadow: "2px 2px 10px #e2e2e2", rounded: 10, py: 10, px: 8 }}
      >
        <FormikProvider value={formik}>
          <Form>
            <Box display="flex" justifyContent={"center"}>
              <img src={TSLogo} alt="logo" style={{ width: "100px" }} />
            </Box>
            <Typography
              textAlign={"center"}
              fontWeight="bold"
              fontSize={"36px"}
            >
              We Emailed You
            </Typography>
            <Typography textAlign={"center"} sx={{ color: "rgba(0,0,0,0.5)" }}>
              Your code is on the way. To Verify your email, enter the code we
              emailed to
              {""}. It may take a minute to arrive.
            </Typography>
            <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={12}>
                <Field name={`code`}>
                  {({ field, meta }) => (
                    <FormItemLabel label="Verification Code">
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                      ></TextField>
                    </FormItemLabel>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={12}>
                {error && <NotifyText type="error" text={error} />}
              </Grid>
              <Stack
                display={"flex"}
                flexDirection="row"
                gap={"10px"}
                justifyContent="center"
                marginTop={"20px"}
                width={"100%"}
              >
                <UgotaGiftButton type="submit" loading={loading}>
                  Verify
                </UgotaGiftButton>
              </Stack>
            </GridContainerStyled>
            {/* <Typography marginTop="50px" textAlign={"right"}>
            Didn't the code ?
          </Typography>
          <Typography
            color={globalColor.primary}
            fontWeight="bold"
            textAlign={"right"}
            sx={{ cursor: "pointer" }}
            onClick={() => history.push(ROUTES.signUp)}
          >
            Resend
          </Typography> */}
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  );
};

export default VerifyEmailForm;
