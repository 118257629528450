import ApartmentIcon from "@mui/icons-material/Apartment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BedtimeOffIcon from "@mui/icons-material/BedtimeOff";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import FoundationIcon from "@mui/icons-material/Foundation";
import GroupsIcon from "@mui/icons-material/Groups";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LinkIcon from "@mui/icons-material/Link";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentIcon from "@mui/icons-material/Payment";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import StorageIcon from "@mui/icons-material/Storage";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import classNames from "classnames";
import CustomLoader from "common/components/Loader";
import { ROUTES } from "components/Shared/pages";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { screenCode } from "timesheets/utils/constant";

interface ILeftSidebar {
	open?: boolean;
	leftData: any;
}

const GROUP = {
	TIME_SHEET: "time_sheet",
	FINANCE: "finance",
	REPORTS: "reports",
	EXPENSE_REPORTS: "expense_reports",
	RECRUITMENT: "recruitment",
};

export const SIDE_BAR_TABS = [
	{
		icon: <DateRangeIcon />,
		linkUrl: `/${ROUTES.attendanceMember}`,
		tabDetails: screenCode.attendanceMember,
		title: "Attendance Member",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <CalendarMonthIcon />,
		linkUrl: `/${ROUTES.attendanceManager}`,
		tabDetails: screenCode.attendanceManager,
		title: "Attendance Manager",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <LinkIcon />,
		linkUrl: `/${ROUTES.memberDetails}`,
		tabDetails: screenCode.memberDetails,
		title: "Team Associate Details",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <DashboardIcon />,
		linkUrl: `/${ROUTES.dashboard}`,
		tabDetails: screenCode.dashBoard,
		title: "Dashboard",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <AssignmentTurnedInIcon />,
		linkUrl: `/${ROUTES.tsApproval}`,
		tabDetails: screenCode.tsApprovalCode,
		title: "TimeSheet Approval",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <AssignmentTurnedInIcon />,
		linkUrl: `/${ROUTES.timeSheetResource}`,
		tabDetails: screenCode.timeSheetResource,
		title: "TimeSheet Resource",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <AssignmentTurnedInIcon />,
		linkUrl: `/${ROUTES.countryConfig}`,
		tabDetails: screenCode.countryConfig,
		title: "Country Configuration",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <StorageIcon />,
		linkUrl: `/${ROUTES.rbac}`,
		tabDetails: screenCode.rbac,
		title: "Role Base Auth",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <WarehouseIcon />,
		linkUrl: `/${ROUTES.companyResource}`,
		tabDetails: screenCode.companyResource,
		title: "Company Resource",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <QuestionAnswerIcon />,
		linkUrl: `/${ROUTES.communications}`,
		tabDetails: screenCode.communications,
		title: "Communications",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <AssessmentIcon />,
		linkUrl: `/${ROUTES.report}`,
		tabDetails: screenCode.report,
		title: "Report",
		group: GROUP.REPORTS,
	},
	{
		icon: <AssessmentIcon />,
		linkUrl: `/${ROUTES.attendanceReport}`,
		tabDetails: screenCode.attendanceReport,
		title: "Attendance Report",
		group: GROUP.REPORTS,
	},
	{
		icon: <FoundationIcon />,
		linkUrl: `/${ROUTES.client}`,
		tabDetails: screenCode.clientCode,
		title: "Clients",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <FoundationIcon />,
		linkUrl: `/${ROUTES.clientDetails}`,
		tabDetails: screenCode.clientDetails,
		title: "Details",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <ApartmentIcon />,
		linkUrl: `/${ROUTES.company}`,
		tabDetails: screenCode.companyCode,
		title: "Company",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <GroupsIcon />,
		linkUrl: `/${ROUTES.clientTeammember}`,
		tabDetails: screenCode.clientTeamMember,
		title: "Client Team Member",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <GroupsIcon />,
		linkUrl: `/${ROUTES.teamMember}`,
		tabDetails: screenCode.teamMemberCode,
		title: "Team Member",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <AssignmentIcon />,
		linkUrl: `/${ROUTES.assignments}`,
		tabDetails: screenCode.assignments,
		title: "Assignments",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <EventAvailableIcon />,
		linkUrl: `/${ROUTES.timesheet}`,
		tabDetails: screenCode.timeSheetCode,
		title: "Timesheets",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <Inventory2Icon />,
		linkUrl: `/${ROUTES.vendor}`,
		tabDetails: screenCode.vendorCode,
		title: "Vendors",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <Inventory2Icon />,
		linkUrl: `/${ROUTES.vendorDetails}`,
		tabDetails: screenCode.vendorDetails,
		title: "Details",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <BedtimeOffIcon />,
		linkUrl: `/${ROUTES.pto}`,
		tabDetails: screenCode.pto,
		title: "Personal Timeoff",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <MonetizationOnIcon />,
		linkUrl: `/${ROUTES.employeeSalaries}`,
		tabDetails: screenCode.employeeSalaries,
		title: "Employee Salaries",
		group: GROUP.FINANCE,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.vendorRates}`,
		tabDetails: screenCode.vendorRates,
		title: "Vendor Rates",
		group: GROUP.FINANCE,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.clientRates}`,
		tabDetails: screenCode.clientRates,
		title: "Client Rates",
		group: GROUP.FINANCE,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.invoices}`,
		tabDetails: screenCode.invoices,
		title: "Invoices",
		group: GROUP.FINANCE,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.payment}`,
		tabDetails: screenCode.payment,
		title: "Payment",
		group: GROUP.FINANCE,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.openPositions}`,
		tabDetails: screenCode.openPositions,
		title: "Open Positions",
		group: GROUP.RECRUITMENT,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.prospectiveClients}`,
		tabDetails: screenCode.prospectiveClients,
		title: "Prospective Clients",
		group: GROUP.RECRUITMENT,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.prospectiveAssociates}`,
		tabDetails: screenCode.prospectiveAssociates,
		title: "Prospective Associates",
		group: GROUP.RECRUITMENT,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.prospectiveVendors}`,
		tabDetails: screenCode.prospectiveVendors,
		title: "Prospective Vendors",
		group: GROUP.RECRUITMENT,
	},
	{
		icon: <PaymentIcon />,
		linkUrl: `/${ROUTES.mailbox}`,
		tabDetails: screenCode.mailbox,
		title: "Mailbox",
		group: GROUP.RECRUITMENT,
	},
	{
		icon: <DashboardIcon />,
		linkUrl: `/${ROUTES.dashboardClient}`,
		tabDetails: screenCode.dashboardClient,
		title: "Dashboard Client",
		group: GROUP.TIME_SHEET,
	},
	{
		icon: <DashboardIcon />,
		linkUrl: `/${ROUTES.dashboardVendor}`,
		tabDetails: screenCode.dashboardVendor,
		title: "Dashboard Vendor",
		group: GROUP.TIME_SHEET,
	},
	// {
	// 	icon: <DashboardIcon />,
	// 	linkUrl: `/${ROUTES.dashboardClientFinance}`,
	// 	tabDetails: screenCode.dashboardClientFinance,
	// 	title: "Dashboard Client Finance",
	// 	group: GROUP.TIME_SHEET,
	// },
	// {
	// 	icon: <DashboardIcon />,
	// 	linkUrl: `/${ROUTES.dashboardFinanceManager}`,
	// 	tabDetails: screenCode.dashboardFinanceManager,
	// 	title: "Dashboard Finance Manager",
	// 	group: GROUP.TIME_SHEET,
	// },
];

export const useStyles = makeStyles((theme) => ({
	menuActive: {
		background: "#1976d2",
		color: "#fff",
		"& path": {
			fill: "#fff",
		},
	},
}));

const LeftSidebar: React.FC<ILeftSidebar> = ({ open, leftData }): any => {
	const { flagLeftSideBar, setFlagLeftSideBar } = useTimeSheetContext();
	const history = useHistory();
	const location = useLocation();
	const paramsCom = location.pathname.split("/");

	const classes = useStyles();

	const [isTimeSheet, setIsTimeSheet] = React.useState<boolean>(true);
	const [isFinance, setIsFinance] = React.useState<boolean>(true);
	const [isReports, setIsReports] = React.useState<boolean>(true);
	const [isExpense, setIsExpense] = React.useState<boolean>(true);
	const [isRecruitment, setIsRecruitment] = React.useState<boolean>(true);

	const MENU_LIST_ROLE = useMemo(
    () => [
      {
        title: "Time Sheets",
        check: GROUP.TIME_SHEET,
        isOpen: isTimeSheet,
        setIsOpen: setIsTimeSheet,
      },
      {
        title: "Finance",
        check: GROUP.FINANCE,
        isOpen: isFinance,
        setIsOpen: setIsFinance,
      },
      {
        title: "Reports",
        check: GROUP.REPORTS,
        isOpen: isReports,
        setIsOpen: setIsReports,
      },
      {
        title: "Expense Reports",
        check: GROUP.EXPENSE_REPORTS,
        isOpen: isExpense,
        setIsOpen: setIsExpense,
      },
      {
        title: "Recruitment",
        check: GROUP.RECRUITMENT,
        isOpen: isRecruitment,
        setIsOpen: setIsRecruitment,
      },
    ],
    [isRecruitment, isExpense, isReports, isFinance, isTimeSheet]
  );



	useEffect(() => {
		if (leftData.length && !flagLeftSideBar && history?.location?.pathname === "/") {
			history.push(`${leftData[0]?.linkUrl}`);
			setFlagLeftSideBar(true);
		}
	}, [leftData, flagLeftSideBar]);



	const renderMenuAccordion = (value: any) => {
		const menuFilter = leftData.filter((filter: { group: string }) => filter.group === value.check);
		if (!menuFilter.length) return;

		return (
			<React.Fragment key={value?.title}>
				<Divider />
				<List component="div" disablePadding>
					<ListItemButton
						onClick={() => {
							value?.setIsOpen(!value?.isOpen)
						}}
						sx={{ background: "#e8e8e8" }}
					>
						{/* <ListItemIcon></ListItemIcon> */}
						<ListItemText primary={value?.title} />
						{value.isOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={value?.isOpen} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							{menuFilter.map(
								(
									item: { linkUrl: string; title: string; icon: string },
									index: number
								): any | undefined => {
									return (
										<ListItem
											disablePadding
											key={index}
											className={classNames({
												[classes.menuActive]:
													`/${paramsCom[1]}` === item?.linkUrl,
											})}
										>
											<Link
												to={item.linkUrl}
												style={{
													width: "100%",
													color: "inherit",
													textDecoration: "none",
												}}
											>
												<ListItemButton>
													<ListItemIcon
														sx={{
															minWidth: 0,
															mr: open ? 1 : "auto",
															justifyContent: "center",
														}}
													>
														{item.icon}
													</ListItemIcon>
													<ListItemText
														sx={{
															opacity: open ? 1 : 0,
															display: open ? "block" : "none",
															margin: 0,
														}}
														primary={item.title}
													/>
												</ListItemButton>
											</Link>
										</ListItem>
									);
								}
							)}
						</List>
					</Collapse>
				</List>
			</React.Fragment>
		);
	};

	return leftData ? MENU_LIST_ROLE.map((value) => renderMenuAccordion(value)) : <CustomLoader />;
};

export default LeftSidebar;
