import type { Delete, Get, Post, Put } from "common/api/types";
import { gateway } from "../../common/helpers/wretch";
import wretch from "wretch";

export interface ActionCreators {
  checkAssociateNumber: Get<Record<string, unknown>, Record<string, unknown>>;

  // application phase
  getApplPhaseForCompany: Get<Record<string, unknown>, Record<string, unknown>>;
  getApplPhaseDetails: Get<Record<string, unknown>, Record<string, unknown>>;
  updateApplPhase: Put<Record<string, unknown>, Record<string, unknown>>;
  addApplPhase: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteApplPhase: Delete<Record<string, unknown>, Record<string, unknown>>;

  //associate upcoming changes
  getAssociateUpcomingChanges: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  deleteAssociateUpcomingChanges: Delete<Record<string, unknown>, Record<string, unknown>>;

  //associate history changes
  getAssociateHistoryChanges: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  // Business Capability
  getBusinessCapabilityForCompany: Get<Record<string, unknown>, Record<string, unknown>>;
  getBusinessCapabilityForClient: Get<Record<string, unknown>, Record<string, unknown>>;
  updateBusinessCapability: Put<Record<string, unknown>, Record<string, unknown>>;
  addBusinessCapability: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteBusinessCapability: Delete<Record<string, unknown>, Record<string, unknown>>;

  // Business Capability Category
  get_BC_CategoryForBusiness: Get<Record<string, unknown>, Record<string, unknown>>;
  get_BC_CategoryForCompany: Get<Record<string, unknown>, Record<string, unknown>>;
  get_BC_CategoryForClient: Get<Record<string, unknown>, Record<string, unknown>>;
  update_BC_Category: Put<Record<string, unknown>, Record<string, unknown>>;
  add_BC_Category: Post<Record<string, unknown>, Record<string, unknown>>;
  delete_BC_Category: Delete<Record<string, unknown>, Record<string, unknown>>;

  // search by keyword
  getTeamAssociateWithKeywords: Get<Record<string, unknown>, Record<string, unknown>>;
  // company
  updateCompanyInfomation: Post<Record<string, unknown>, Record<string, unknown>>;
}

const baseUrl = process.env.REACT_APP_SUB_COMPANY_URL;
const teamAssociateUrl = baseUrl + "/team-associate";
const applPhaseUrl = baseUrl + "/application-phase";
const businessCapability = baseUrl + "/business-capability";
const businessCapabilityDelete = baseUrl + "/delete-business-capability";

const upcomingChangesUrl = baseUrl + "/associate-upcoming";
const upcomingChangesDeleteUrl = baseUrl + "/delete-associate-upcoming";

const historyChangesUrl = baseUrl + "/associate-history";
const updateCompanyInformation = baseUrl + "/updateCompanyInformation";

export const getActionCreators = (gateway: any): ActionCreators => ({
  checkAssociateNumber: (queryParams = {}) =>
    gateway
      .url(`${teamAssociateUrl}/${queryParams.companyId}/${queryParams.associateNumber}`)
      .get()
      .json(),

  // application phase
  getApplPhaseForCompany: (queryParams = {}) =>
    gateway.url(`${applPhaseUrl}/${queryParams.companyId}`).get().json(),
  getApplPhaseDetails: (queryParams = {}) =>
    gateway
      .url(`${applPhaseUrl}/${queryParams.companyId}/${queryParams.applicationPhaseId}`)
      .get()
      .json(),
  updateApplPhase: (body) => gateway.url(applPhaseUrl).put(body).json(),
  addApplPhase: (body) => gateway.url(applPhaseUrl).post(body).json(),
  deleteApplPhase: (companyId, applicationPhaseId) =>
    gateway.url(`${applPhaseUrl}/${companyId}/${applicationPhaseId}`).delete().json(),

  // Business Capability
  getBusinessCapabilityForCompany: (queryParams = {}) =>
    gateway.url(`${businessCapability}/${queryParams.companyId}`).get().json(),
  getBusinessCapabilityForClient: (queryParams = {}) =>
    gateway
      .url(`${businessCapability}-client/${queryParams.companyId}/${queryParams.clientId}`)
      .get()
      .json(),
  updateBusinessCapability: (body) => gateway.url(businessCapability).put(body).json(),
  addBusinessCapability: (body) => gateway.url(businessCapability).post(body).json(),
  deleteBusinessCapability: (companyId, businessCapabilityId) =>
    gateway.url(`${businessCapabilityDelete}/${companyId}/${businessCapabilityId}`).delete().json(),

  // Business Capability Category
  get_BC_CategoryForBusiness: (queryParams = {}) =>
    gateway
      .url(
        `${businessCapability}-category/${queryParams.companyId}/${queryParams.businessCapabilityId}`
      )
      .get()
      .json(),
  get_BC_CategoryForCompany: (queryParams = {}) =>
    gateway.url(`${businessCapability}-category/${queryParams.companyId}`).get().json(),
  get_BC_CategoryForClient: (queryParams = {}) =>
    gateway
      .url(`${businessCapability}-category-client/${queryParams.companyId}/${queryParams.clientId}`)
      .get()
      .json(),
  update_BC_Category: (body) => gateway.url(`${businessCapability}-category`).put(body).json(),
  add_BC_Category: (body) => gateway.url(`${businessCapability}-category`).post(body).json(),
  delete_BC_Category: (companyId, businessCapabilityCategoryId) =>
    gateway
      .url(`${businessCapabilityDelete}-category/${companyId}/${businessCapabilityCategoryId}`)
      .delete()
      .json(),

  // search by keyword
  getTeamAssociateWithKeywords: (queryParams = {}) =>
    gateway
      .url(`${teamAssociateUrl}/${queryParams.companyId}/filter/${queryParams.keyword}`)
      .get()
      .json(),

  // upComing
  getAssociateUpcomingChanges: (body) => gateway.url(upcomingChangesUrl).post(body).json(),
  deleteAssociateUpcomingChanges: (companyId, associateUpcomingId) =>
    gateway
      .url(`${upcomingChangesDeleteUrl}/${companyId}/${associateUpcomingId}`)
      .delete()
      .json(),
  
  //History
  getAssociateHistoryChanges: (body) => gateway.url(historyChangesUrl).post(body).json(),
  //company
  updateCompanyInfomation: (body) => gateway.url(updateCompanyInformation).post(body).json(),

});

export const useSubCompanyActionCreators = (): {
  actionCreators: ActionCreators;
} => {
  const token = localStorage.getItem("accessToken");
  const actionCreators = getActionCreators(
    gateway({
      token: token,
      xApiKey: `${process.env.REACT_APP_SUB_COMPANY_KEY}`,
      contentType: !token
        ? "application/x-www-form-urlencoded"
        : "application/json",
    })
  );

  return { actionCreators };
};
