import makeRequest from "common/api/makeRequest";
import CryptoJS from "crypto-js";
import moment from "moment";
import { uploadActionCreators } from "timesheets/api/uploadActions";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import {
  AUTH_ROLE_CODE,
  clientAuthRole,
  DATE_FORMAT,
  ROLES,
  SCREEN_ACTION_DEFAULT,
  SCREEN_ACTION_DEFAULT_FL_CODE,
  SNR_APPS,
  vendorAuthRole,
} from "./constant";
const {
  CLIENT_REPORTING_MGR,
  COMPANY_ADMIN,
  REPORTING_MGR,
  RESOURCE_MGR,
  TEAM_MEMBER,
  CLIENT_ADMIN,
  CLIENT_FINANCE_MGR,
  CLIENT_REVIEWER,
  FINANCE_ACCOUNT_MGR,
  VENDOR_FINANCE_MGR,
  VENDOR_REPORTING_MGR,
  VENDOR_ADMIN,
} = AUTH_ROLE_CODE;

//currency
export const displayCurrency = (currencyList: any = [], currencyId, value) => {
  const currency = currencyList.find(
    (currency) => currency.currencyId === currencyId
  );
  if (currency) {
    const formatValue = Number(value).toFixed(
      currency?.decimalNumberFormat ? currency?.decimalNumberFormat : 2
    );
    if (currency.currencyIsLeft == true) {
      return `${currency.currencySymbol} ${formatValue}`;
    } else {
      return `${formatValue} ${currency.currencySymbol}`;
    }
  } else {
    return `$ ${Number(value).toFixed(2)}`;
  }
};

export const handleCityCodeByCityName = (string: string) => {
  const str = string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
  return str.replaceAll(" ", "_");
};

export const getDefaultScreenAction = (screenCode) => {
  const index = SCREEN_ACTION_DEFAULT_FL_CODE.findIndex(
    (item) => item.screenCode === screenCode
  );
  if (index !== -1) {
    return SCREEN_ACTION_DEFAULT_FL_CODE[index].defaultAction;
  }
  return SCREEN_ACTION_DEFAULT;
};

export const handleDateFormat = (date) => {
  return moment(date).format(DATE_FORMAT);
};

export const checkAuthRole = (roleCode, authRoleList) => {
  const _authRoleList = authRoleList ? authRoleList : [];
  const index = _authRoleList.findIndex((item) => item?.roleCode === roleCode);
  if (index !== -1) {
    return true;
  }
  return false;
};

export const getDepartmentInfo = (departments, companyDeptId) => {
  const _departments = departments ? departments : [];
  const index = _departments.findIndex(
    (item) => item?.companyDeptId === companyDeptId
  );
  if (index !== -1) {
    return _departments[index];
  }
  return false;
};

export const checkClientAuthRole = (roleCode) => {
  const index = clientAuthRole.findIndex((item) => item?.roleCode === roleCode);
  if (index !== -1) {
    return true;
  }
  return false;
};

export const checkVendorAuthRole = (roleCode) => {
  const index = vendorAuthRole.findIndex((item) => item?.roleCode === roleCode);
  if (index !== -1) {
    return true;
  }
  return false;
};

export const checkIsManager = (list: any = []) => {
  const index = list?.findIndex(
    (item) =>
      item?.roleCode === CLIENT_REPORTING_MGR ||
      item?.roleCode === COMPANY_ADMIN ||
      item?.roleCode === REPORTING_MGR ||
      item?.roleCode === RESOURCE_MGR ||
      item?.roleCode === CLIENT_ADMIN ||
      item?.roleCode === CLIENT_FINANCE_MGR ||
      item?.roleCode === CLIENT_REVIEWER ||
      item?.roleCode === FINANCE_ACCOUNT_MGR ||
      item?.roleCode === VENDOR_FINANCE_MGR ||
      item?.roleCode === VENDOR_REPORTING_MGR ||
      item?.roleCode === VENDOR_ADMIN
  );
  if (index !== -1) {
    return true;
  }
  return false;
};

export const handleFormatMDY = (string: string) => {
  return moment(string?.substring(0, 10)).format("L");
};

export const handleRenderRoles = (role: any | undefined = false) => {
  for (const key in ROLES) {
    if (role === key) {
      return ROLES[key];
    }
  }
};

export const convertObjectToLength = (param: any | null | undefined) => {
  if (param !== null && param !== undefined) {
    return Object?.keys(param)?.length;
  } else {
    return 0;
  }
};

export const disableStartDate = (current: any, formik: any) => {
  return current && current >= moment(formik.values.endDate);
};

export const disableEndDate = (current: any, formik: any) => {
  return current && current <= moment(formik.values.startDate);
};

export const arrayToObj = (arr) => {
  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    const { name, value } = arr[i];
    obj[name] = value;
  }
  return obj;
};

export const getWeeklyData = (
  _amount = 0,
  _startDate: any = undefined,
  _endDate: any = undefined
) => {
  _startDate = _startDate ? moment(_startDate).format(DATE_FORMAT) : undefined;
  _endDate = _endDate ? moment(_endDate).format(DATE_FORMAT) : undefined;
  _amount = +_amount;
  const weeklyData: any = [];
  for (let i = -_amount; i <= 1; i++) {
    const startDate = moment()
      .startOf("week")
      .add(i, "week")
      .format(DATE_FORMAT);
    const endDate = moment(startDate).add(6, "day").format(DATE_FORMAT);
    const weekNumber = moment(
      moment(startDate).toDate(),
      DATE_FORMAT
    ).isoWeek();
    const year = moment(startDate).format("YYYY");
    const wkly = {
      startDate,
      endDate,
      weekNumber,
      year,
    };

    if (_startDate && _endDate) {
      const weeklyStartDateIsBetWeen =
        moment(wkly.startDate).isSameOrAfter(_startDate) &&
        moment(wkly.startDate).isSameOrBefore(_endDate);

      const weeklyEndDateIsBetWeen =
        moment(wkly.endDate).isSameOrAfter(_startDate) &&
        moment(wkly.endDate).isSameOrBefore(_endDate);

      const assignmentStartDateIsBetWeen =
        moment(_startDate).isSameOrAfter(wkly.startDate) &&
        moment(_startDate).isSameOrBefore(wkly.endDate);

      const assignmentEndDateIsBetWeen =
        moment(_endDate).isSameOrAfter(wkly.startDate) &&
        moment(_endDate).isSameOrBefore(wkly.endDate);

      if (
        weeklyStartDateIsBetWeen ||
        weeklyEndDateIsBetWeen ||
        assignmentStartDateIsBetWeen ||
        assignmentEndDateIsBetWeen
      ) {
        weeklyData.push(wkly);
      }
    } else {
      weeklyData.push(wkly);
    }
  }
  return weeklyData;
};

export const getBiWeeklyData = (_amount, isStartAtCurrent = false) => {
  _amount = +_amount;
  const resArray: any = [];
  const am = isStartAtCurrent ? 0 : _amount;
  for (let i = -_amount; i <= am; i++) {
    const startDateOfStartBW = moment()
      .startOf("month")
      .add(i, "month")
      .format(DATE_FORMAT);

    const endDateOfStartBW = moment(startDateOfStartBW)
      .add(14, "day")
      .format(DATE_FORMAT);

    const startDateOfEndBW = moment(startDateOfStartBW)
      .add(15, "day")
      .format(DATE_FORMAT);

    const endDateOfEndBW = moment(startDateOfStartBW)
      .endOf("month")
      .format(DATE_FORMAT);

    const monthNumber = moment(startDateOfStartBW).format("MM");
    const year = moment(startDateOfStartBW).format("YYYY");

    const startBiWeekly = {
      startDate: startDateOfStartBW,
      endDate: endDateOfStartBW,
      amountOfDays:
        moment
          .duration(moment(endDateOfStartBW).diff(startDateOfStartBW))
          .asDays() + 1,
      monthNumber,
      year,
    };
    const endtBiWeekly = {
      startDate: startDateOfEndBW,
      endDate: endDateOfEndBW,
      amountOfDays:
        moment
          .duration(moment(endDateOfEndBW).diff(startDateOfEndBW))
          .asDays() + 1,
      monthNumber,
      year,
    };

    resArray.push(startBiWeekly);
    resArray.push(endtBiWeekly);
  }

  return resArray;
};

export const getYearlyData = (_amount, isStartAtCurrent = false): number => {
  _amount = +_amount;
  const data: any = [];
  const am = isStartAtCurrent ? 0 : _amount;
  for (let i = -_amount; i <= am; i++) {
    const startDate = moment()
      .startOf("year")
      .add(i, "year")
      .format(DATE_FORMAT);
    const endDate = moment().endOf("year").add(i, "year").format(DATE_FORMAT);
    const year = moment(startDate).format("YYYY");
    const mhly = {
      startDate,
      endDate,
      year,
    };
    data.push(mhly);
  }

  return data;
};

export const getMonthlyData = (_amount, isStartAtCurrent = false): number => {
  _amount = +_amount;
  const monthlyData: any = [];
  const am = isStartAtCurrent ? 0 : _amount;
  for (let i = -_amount; i <= am; i++) {
    const startDate = moment()
      .startOf("month")
      .add(i, "month")
      .format(DATE_FORMAT);
    const daysInMonth = moment(startDate).daysInMonth();
    const endDate = moment(startDate)
      .add(+daysInMonth - 1, "day")
      .format(DATE_FORMAT);
    const monthNumber = moment(startDate).format("MM");
    const year = moment(startDate).format("YYYY");
    const mhly = {
      startDate,
      endDate,
      monthNumber,
      daysInMonth,
      year,
    };
    monthlyData.push(mhly);
  }

  return monthlyData;
};

export const getWeeksInMonth = (startOfMonth) => {
  const list: any = [];
  for (let i = 0; i < 7; i++) {
    let startDate = "";
    if (i === 0) {
      startDate = moment(startOfMonth).format(DATE_FORMAT);
    } else {
      startDate = moment(list[i - 1].endDate)
        .add(1, "day")
        .format(DATE_FORMAT);
    }

    const endDate = moment(startDate).endOf("week").format(DATE_FORMAT);

    if (moment(startDate).format("MM") !== moment(startOfMonth).format("MM")) {
      break;
    } else if (
      moment(endDate).format("MM") !== moment(startOfMonth).format("MM")
    ) {
      list.push({
        startDate,
        endDate: moment(startOfMonth).endOf("month").format(DATE_FORMAT),
      });
      break;
    } else {
      list.push({ startDate, endDate });
    }
  }
  return list;
};

export const getWeeksInAPeriod = (startBW, endBW, elementLimit = 7) => {
  const list: any = [];

  for (let i = 0; i < elementLimit; i++) {
    let startDate = "";
    if (i === 0) {
      startDate = moment(startBW).format(DATE_FORMAT);
    } else {
      startDate = moment(list[i - 1].endDate)
        .add(1, "day")
        .format(DATE_FORMAT);
    }

    const endDate = moment(startDate).endOf("week").format(DATE_FORMAT);

    if (moment(startDate).isAfter(endBW)) {
      break;
    } else if (moment(endDate).isAfter(endBW)) {
      list.push({
        startDate,
        endDate: moment(endBW).format(DATE_FORMAT),
      });
      break;
    } else {
      list.push({ startDate, endDate });
    }
  }
  return list;
};

export const getBiWeeksInAMonth = (startBW, endBW, elementLimit = 2) => {
  const list: any = [];
  for (let i = 0; i < elementLimit; i++) {
    if (moment.duration(moment(endBW).diff(startBW)).asDays() < 20) {
      list.push({ startDate: startBW, endDate: endBW });
      break;
    } else {
      let startDate = "";
      if (i === 0) {
        startDate = moment(startBW).format(DATE_FORMAT);
      } else {
        startDate = moment(list[i - 1].endDate)
          .add(1, "day")
          .format(DATE_FORMAT);
      }

      const fullMoonDay =
        moment(startBW).format("YYYY") +
        "-" +
        moment(startBW).format("MM") +
        "-" +
        "15";

      let endDate = fullMoonDay;

      if (moment(endBW).isSame(moment(endBW).endOf("month"))) {
        startDate = moment(fullMoonDay).add(1, "days").format(DATE_FORMAT);
        endDate = moment(startDate).endOf("months").format(DATE_FORMAT);
        list.push({ startDate, endDate });
        break;
      }

      if (moment(startDate).isAfter(fullMoonDay)) {
        startDate = moment(fullMoonDay).add(1, "days").format(DATE_FORMAT);
        endDate = moment(startDate).endOf("months").format(DATE_FORMAT);
        list.push({ startDate, endDate });
        break;
      }
      list.push({ startDate, endDate });
    }
  }
  return list;
};

interface IFuncCheckClientAndVendorRole {
  isCompany: boolean;
  isClient: boolean;
  isVendor: boolean;
  clientId: string;
  vendorId: string;
}

export const checkClientAndVendorRole = (
  teamAssociate: any,
  authenticationRoles: any[] = []
): IFuncCheckClientAndVendorRole => {
  const teamAssociateObj = {
    authRoleCode: teamAssociate?.authRoleCode || "",
    clientId: teamAssociate?.clientId || "",
    vendorId: teamAssociate?.vendorId || "",
  };
  const resObj = {
    isCompany: false,
    isVendor: false,
    isClient: false,
    clientId: "",
    vendorId: "",
  };

  const index = authenticationRoles.findIndex(
    (item) => item?.roleCode === teamAssociateObj?.authRoleCode
  );

  let roleType = "";
  if (index !== -1) {
    roleType = authenticationRoles[index].roleType;
  }

  if (roleType === "CLIENT") {
    resObj.isClient = true;
    resObj.clientId = teamAssociateObj.clientId;
    return resObj;
  } else if (roleType === "VENDOR") {
    resObj.isVendor = true;
    resObj.vendorId = teamAssociateObj.vendorId;
    return resObj;
  } else {
    resObj.isCompany = true;
    return resObj;
  }
};

export const encryptData = (data: string) => {
  return CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_SECRET_KEY_ENCRYPT || ""
  ).toString();
};

export const decryptData = (data: string) => {
  return CryptoJS.AES.decrypt(
    data,
    process.env.REACT_APP_SECRET_KEY_ENCRYPT || ""
  ).toString(CryptoJS.enc.Utf8);
};

export const ssnFormat = (e) =>
  (e.target as any)?.value
    ?.replace(/\D/g, "")
    .replace(/^(\d{3})/, "$1-")
    .replace(/-(\d{2})/, "-$1-")
    .replace(/(\d)-(\d{4}).*/, "$1-$2");

export const stringToUnicodeText = (str: string) => {
  return str
    ? String(str)
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[đĐ]/g, "d")
        .toUpperCase()
    : "";
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getTypeImageBase64 = (base64) => {
  return base64
    .toString()
    .substring("data:".length, base64.toString().indexOf(";base64"));
};

function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

type InputFile = {
  url: string;
  key: string;
};

export const uploadFiles = async (files: InputFile[]) => {
  const {
    actionCreators: { uploadFile },
  } = uploadActionCreators();

  const result: any = {};

  const promises = files?.map(async (file) => {
    if (file.url.indexOf("http") < 0) {
      await makeRequest(
        () =>
          uploadFile({
            image: file.url.split("base64,")[1],
            type: getTypeImageBase64(file.url),
          }),
        (res: any) => (result[`${file.key}`] = res?.data?.Location),
        (e) => console.log({ e })
      );
    } else {
      return (result[`${file.key}`] = file.url);
    }
  });

  await Promise.all(promises);
  return result;
};

type InputFileFormData = {
  url: any;
  key: string;
};

export const uploadFileFormData = async (files: InputFileFormData[]) => {
  const {
    actionCreators: { uploadFile },
  } = uploadActionCreators();

  const result: any = {};

  const promises = files?.map(async (file, index) => {
    if (file.url.indexOf("http") < 0) {
      await makeRequest(
        () => {
          const _file = DataURIToBlob(file.url);

          const formData = new FormData();
          formData.append("fileData", _file);

          return uploadFile(formData);
        },
        (res: any) => {
          console.log({ res });

          //  (result[`${file.key}`] = res?.data?.Location)
        },
        (e) => console.log({ e })
      );
    } else {
      return (result[`${file.key}`] = file.url);
    }
  });

  await Promise.all(promises);
  return result;
};

export const getValueByFieldNames = (names, data) => {
  const result: any = [];
  if (Array.isArray(names)) {
    names?.forEach((name, i) => {
      let valueForName = "";
      const splitName = name.split(".");
      let valueForItem = "";

      splitName.forEach((item, j) => {
        if (j == 0) valueForItem = data[item] || "";
        else valueForItem = valueForItem[item] || "";

        if (j == splitName.length - 1) valueForName += valueForItem;
      });

      result.push(valueForName);
    });
  }
  if (result.length >= 2) return result.join(" ");

  return result[0];
};

export const getManagerInfo = (id, externalManagers, members = []) => {
  const teamAssociateArray = [...externalManagers, ...members];
  const index = teamAssociateArray.findIndex(
    (item) => item.teamAssociateId === id
  );
  if (index !== -1) {
    return teamAssociateArray[index];
  }
  return null;
};

export const getVendorInfo = (id) => {
  const context = useTimeSheetContext();
  const { vendors } = context;
  const index = vendors.findIndex((item) => item.vendorId === id);
  if (index !== -1) {
    return vendors[index];
  }
  return {};
};

export const getClientInfo = (id) => {
  const context = useTimeSheetContext();
  const { clients } = context;
  const index = clients.findIndex((item) => item.vendorId === id);
  if (index !== -1) {
    return clients[index];
  }
  return {};
};

export const getCurrentDay = () => {
  const startDateOfWeek = moment().startOf("week").format(DATE_FORMAT);
  const endDateOfWeek = moment(startDateOfWeek)
    .endOf("week")
    .format(DATE_FORMAT);

  const startDateOfMonth = moment().startOf("month").format(DATE_FORMAT);
  const endDateOfMonth = moment().endOf("month").format(DATE_FORMAT);

  let startDateOfBW;
  let endDateOfBW;
  if (moment().isBefore(moment(startDateOfMonth).add(15, "days"))) {
    startDateOfBW = startDateOfMonth;
    endDateOfBW = moment(startDateOfMonth).add(14, "days").format(DATE_FORMAT);
  } else {
    startDateOfBW = moment(startDateOfMonth)
      .add(15, "days")
      .format(DATE_FORMAT);
    endDateOfBW = moment(startDateOfMonth).endOf("months").format(DATE_FORMAT);
  }

  return {
    startDateOfWeek,
    endDateOfWeek,
    startDateOfMonth,
    endDateOfMonth,
    startDateOfBW,
    endDateOfBW,
  };
};

export const getAssignmentTime = () => {
  const startDateOfWeek = moment().startOf("week").format(DATE_FORMAT);
  const endDateOfWeek = moment().endOf("week").format(DATE_FORMAT);

  const startDateOfMonth = moment().startOf("month").format(DATE_FORMAT);
  const endDateOfMonth = moment().endOf("month").format(DATE_FORMAT);

  return { startDateOfWeek, startDateOfMonth, endDateOfWeek, endDateOfMonth };
};

export const handleAccessScreen = (
  screenCode,
  action,
  screenRoleMappingForTeamMember
) => {
  const { tabDetails } = screenRoleMappingForTeamMember[0] || {};
  const actionUpperCase = action;

  const _tabDetails =
    typeof tabDetails === "string"
      ? JSON.parse(convertArrayFromTestData(tabDetails))
      : tabDetails;

  const index =
    _tabDetails.length &&
    (_tabDetails || []).findIndex((screen) => screen.name === screenCode);

  if (
    index !== -1 &&
    (_tabDetails[index]?.disabledAction || {})[actionUpperCase]
  ) {
    return false;
  } else {
    return true;
  }
};

export const convertArrayFromTestData = (string) => {
  return string
    .replaceAll(`{`, `{"`)
    .replaceAll(`:`, `":"`)
    .replaceAll(`}`, `"}`);
};

export const formatNumber = (value: number) => {
  if (value) {
    const decimalPosition = value.toString().indexOf(".");
    if (decimalPosition > 0) {
      const intVal = value.toString().substring(0, decimalPosition);
      const decimalVal = value.toString().substring(decimalPosition + 1);
      return `${intVal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalVal}`;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return "";
};

export const generateCodeByName = (value: string | undefined) => {
  if (!value) return;

  return value.toUpperCase().replaceAll(" ", "_");
};

export const handleMapDataManager = (data: any, condition: string) => {
  const tempReporting = data.filter(
    (item: { authRoleList: any; authRoleCode: string }) =>
      checkAuthRole(condition, item?.authRoleList) ||
      item?.authRoleCode === condition
  );
  const resultReporting = tempReporting.map((option) => ({
    value: option.teamAssociateId,
    label: `${option.firstName} ${option.lastName} - ${option.email}`,
    rest: option,
  }));

  return resultReporting;
};

export const handleDateByView = (view = "WEEKLY") => {
  const dateList = {
    ["MONTHLY"]: getMonthlyData(5),
    ["YEARLY"]: getYearlyData(2),
    ["BI-WEEKLY"]: getBiWeeklyData(5),
    ["WEEKLY"]: getWeeklyData(20),
  };

  return dateList[view] || dateList["WEEKLY"];
};

export const handleDateListByView = (view, datesData) => {
  let listTemp: any = [];
  switch (view) {
    case "WEEKLY":
      listTemp = datesData.map((option) => ({
        value: option?.startDate,
        label: `${option?.startDate} - ${option?.endDate} - Week: ${option?.weekNumber}`,
        rest: option,
      }));
      break;
    case "BI-WEEKLY":
      listTemp = datesData.map((option) => ({
        value: option?.startDate,
        label: `From ${option?.startDate} to ${option?.endDate}`,
        rest: option,
      }));
      break;
    case "MONTHLY":
      listTemp = datesData.map((option) => ({
        value: option?.startDate,
        label: `${moment(option?.startDate).format("MMMM - YYYY")}`,
        rest: option,
      }));
      break;
    case "YEARLY":
      listTemp = datesData.map((option) => ({
        value: option?.startDate,
        label: `${moment(option?.startDate).format("YYYY")}`,
        rest: option,
      }));
      break;
  }
  return listTemp;
};

export function isNumber(value) {
  return !isNaN(value);
}

export const validateFileExtension = (
  fileName: string,
  allowExtensions: string[]
) => {
  const ext = fileName.substring(
    fileName.lastIndexOf(".") + 1,
    fileName.length
  );
  const index = allowExtensions.findIndex((allowExt) => allowExt === ext);
  if (index !== -1) {
    return true;
  }
  return false;
};
