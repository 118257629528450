import styled from "@emotion/styled";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useState } from "react";
import { FiUser } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import useCompanyActions from "timesheets/api/actions/companyCalls";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { SNR_APPS } from "timesheets/utils/constant";

const WsWrapper = styled.div`
  width: 600px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  margin: 0 auto;
  margin-top: 50px;
`;

const WsItem = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  cursor: pointer;
  gap: 10px;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

interface IRoleContent {
	rolesArray: any;
}

const RoleContent: React.FC<IRoleContent> = ({ rolesArray }) => {
	const context = useTimeSheetContext();
	const { teamAssociate } = context;
	const {
		handleUpdateTeamAssociateWithoutEffectiveDate,
		fetchScreenMappingForTeamMemberInit,
		fetchTeamAssociateWithCompanyInit,
	} = useCompanyActions();
	const [loading, setLoading] = useState<boolean>(false);
	const history = useHistory();


	const handleChooseRole = (role: any) => {
		handleUpdateTeamAssociateWithoutEffectiveDate(
			setLoading,
			{
				snrId: SNR_APPS,
				companyId: teamAssociate.companyId,
				teamAssociateId: teamAssociate.teamAssociateId,
				email: teamAssociate.email,
				authRoleCode: role.roleCode,
				authRoleName: role.roleName,
				clientId: role?.clientId ? role?.clientId : undefined,
				clientName: role?.clientName ? role?.clientName : undefined,
			},
			() => {
				setLoading(true)
				location.reload();
				// fetchTeamAssociateWithCompanyInit();
				// fetchScreenMappingForTeamMemberInit(setLoading, () => callback(role.roleCode));
			}
		);
	};

	const callback = (roleCode) => {
		context.setRoleStep("ROLE");
		history.push(`/`);
		context.setFlagLeftSideBar(false)
	};





	return (
		<Container maxWidth={"lg"}>
			<Typography textAlign={"center"} fontWeight={"bold"} variant={"h3"} paddingTop={10}>
				Choose a Role
			</Typography>
			<Typography textAlign={"center"} variant={"subtitle1"}>
				Easily switch between roles available
			</Typography>
			<WsWrapper>
				<Box display="flex" alignItems={"center"} justifyContent="center">
					<Typography variant={"subtitle1"} paddingBottom={"10px"}>
						Please select
					</Typography>
					{loading && (
						<Box sx={{ marginLeft: "10px" }}>
							<CircularProgress />
						</Box>
					)}
				</Box>
				{rolesArray?.length ? (
					rolesArray.map((item, index) => {
						return (
							<WsItem key={index} onClick={() => handleChooseRole(item)}>
								<FiUser />
								<Typography fontWeight={"bold"}>{item.roleName}</Typography>
							</WsItem>
						);
					})
				) : (
					<h1>Something went wrong !</h1>
				)}
			</WsWrapper>
		</Container>
	);
};

export default RoleContent;