import { makeStyles } from "@material-ui/core";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";
import { Auth } from "aws-amplify";
import makeRequest from "common/api/makeRequest";
import NotifyText from "common/components/NotifyText";
import FormItemLabel from "common/components/FormItemLabel";
import UgotaGiftButton from "common/components/UgotaGiftButton";
import { ROUTES } from "components/Shared/pages";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { GridContainerStyled } from "timesheets/styles/common";
import * as Yup from "yup";
const useStyles = makeStyles({
  wrapperConfirmSignup: {
    padding: "58px",
    position: "relative",
    borderRadius: "24px",
    background: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  },
});

const ConfirmSignUpForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useTimeSheetContext();
  const { signUpAuth, setIsAuth, setAuthInfo } = context;
  const { username, emailDes, password } = signUpAuth;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Require!"),
    }),
    onSubmit: async (values) => {
      const { code } = values;
      handleSubmit({ code });
    },
  });

  const handleSubmit = useCallback(
    (payload) => {
      setLoading(true);
      const { code } = payload;
      makeRequest(
        () => {
          return Auth.confirmSignUp(username, code);
        },
        async () => {
          const user = await Auth.signIn(username, password);
          const teamAssociateInfoEncrp =
            user.signInUserSession.idToken.payload.teamAssociateInfo;
          const teamAssociateInfoStr = atob(teamAssociateInfoEncrp);
          const teamAssociateInfo = JSON.parse(teamAssociateInfoStr);
          localStorage.setItem("associateToken", teamAssociateInfo.encryptAssociate)
          setLoading(false);
          setError("");
          setAuthInfo(teamAssociateInfo);
          setIsAuth(true);
          history.push("/");
        },
        () => {
          setLoading(false);
          setError("Invalid code provided");
        }
      );
    },
    [username]
  );

  useEffect(() => {
    !username.length && history.push(ROUTES.login);
  }, [username]);

  return (
    <Box className={classes.wrapperConfirmSignup}>
      <FormikProvider value={formik}>
        <Form>
          <Box display="flex" justifyContent={"center"}>
            <img src={TSLogo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography textAlign={"center"} fontWeight="bold" fontSize={"36px"}>
            We Emailed You
          </Typography>
          <Typography textAlign={"center"} sx={{ color: "rgba(0,0,0,0.5)" }}>
            Your code is on the way. To log in, enter the code we emailed to{" "}
            {emailDes}. It may take a minute to arrive.
          </Typography>
          <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={12}>
              <Field name={`code`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Verification Code">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              {error && <NotifyText type="error" text={error} />}
            </Grid>
            <Stack
              display={"flex"}
              flexDirection="row"
              gap={"10px"}
              justifyContent="center"
              marginTop={"20px"}
              width={"100%"}
            >
              <UgotaGiftButton type="submit" loading={loading}>
                CONTINUE
              </UgotaGiftButton>
            </Stack>
          </GridContainerStyled>
          {/* <Typography marginTop="50px" textAlign={"right"}>
            Didn't the code ?
          </Typography>
          <Typography
            color={globalColor.primary}
            fontWeight="bold"
            textAlign={"right"}
            sx={{ cursor: "pointer" }}
            onClick={() => history.push(ROUTES.signUp)}
          >
            Resend
          </Typography> */}
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default ConfirmSignUpForm;
