import React, { Suspense, useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { Route, RouterProps, Switch } from "react-router-dom";

import BackToTop from "common/components/BackToTop";
import CustomLoader from "common/components/Loader";
import { authPages, Page, pages } from "components/Shared/pages";
import CheckNewCompany from "timesheets/components/CheckNewCompany";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { convertArrayFromTestData } from "timesheets/utils";
import { COMPANY_TYPE, screenCode } from "timesheets/utils/constant";
import { AuthLayout } from "timesheets/layouts/AuthLayout";
import PageLayout from "timesheets/layouts/PageLayout";
import UnauthorizedAlert from "./timesheets/components/UnauthorizedAlert";
import ChooseWorkSpace from "common/components/ChooseWorkSpace";
import useCompanyActions from "timesheets/api/actions/companyCalls";
import { getCookie } from "common/helpers/cookies";

import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import "./assets/font/font.css";
import ChooseRole from "./common/components/ChooseRole";
import { MemberUtils } from "timesheets/pages/Members/action";

Amplify.configure(awsconfig);

const App: React.FC = () => {
  const context = useTimeSheetContext();
  const [ loading , setLoading ] = useState<boolean>(false)
  const {
    isToken,
    screenRoleMappingForTeamMember,
    companyType,
    isAuth,
    wsStep,
    teamAssociate
  } = context;

  const {
    fetchCompanyInfoInit,
    fetchScreenMappingForTeamMemberInit,
    fetchTeamAssociateWithCompanyInit,
  } = useCompanyActions();

	const { fetchAuthRole } = MemberUtils();

  



  const companyId = getCookie("companyId");
  const email = getCookie("email");

  useEffect(() => {
    if (companyId && wsStep === "WS") fetchCompanyInfoInit();
  }, [wsStep, companyId]);

  useEffect(() => {
    if (teamAssociate?.companyId) {
		  fetchAuthRole(setLoading);
    }
	}, [teamAssociate?.companyId]);

  useEffect(() => {
    if (companyId && email && wsStep === "WS") {
      fetchScreenMappingForTeamMemberInit();
      fetchTeamAssociateWithCompanyInit();
    }
  }, [wsStep, companyId, email]);

  // handle filter pages and remove client when “End client” option is selected
  const [pagesFilter, setPagesFilter] = useState<any>([]);
  useEffect(() => {
    if (screenRoleMappingForTeamMember.length && isAuth) {
      const tabDetails =
        screenRoleMappingForTeamMember[
          screenRoleMappingForTeamMember.length - 1
        ].tabDetails;
      tabDetails.push({
        name: "CONFIRM_EMAIL",
      });
      const _tabDetails =
        typeof tabDetails === "string"
          ? JSON.parse(convertArrayFromTestData(tabDetails))
          : tabDetails;
      const pagesArray: any = [];
      for (let j = 0; j < _tabDetails?.length; j++) {
        for (let i = 0; i < pages.length; i++) {
          if (
            pages[i].name === _tabDetails[j].name &&
            (_tabDetails[j]?.disabledAction || {})[pages[i].action] !== true
          ) {
            pagesArray.push(pages[i]);
          }
        }
      }

      let resArray: any = [...pagesArray];
      if (companyType === COMPANY_TYPE.END_CLIENT) {
        resArray = pagesArray.filter(
          (item) => item.name !== screenCode.clientCode
        );
      }
      setPagesFilter(resArray);
    } else {
      setPagesFilter(pages);
    }
  }, [screenRoleMappingForTeamMember, isAuth]);

  // prevent memory leak
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  if (!isToken) return <CustomLoader />;
  return (
    <Suspense fallback={<CustomLoader />}>
      <Switch>
        {pagesFilter.map((page: Page, idx: number) => {
          const Component: any = page.component as any;
          return (
            <Route
              exact
              key={`${page.name}-${idx}`}
              path={page.path}
              render={(props: RouterProps) => (
                <PageLayout isCheckAuth={true}>
                  <ChooseWorkSpace>
                    <ChooseRole>
                      <CheckNewCompany>
                        <Component {...props} />
                      </CheckNewCompany>
                    </ChooseRole>
                  </ChooseWorkSpace>
                </PageLayout>
              )}
            />
          );
        })}
        
        {authPages?.map((page: Page, idx) => {
          const Component: any = page.component as any;
          return (
            <Route
              exact
              key={`${page.name}-${idx}`}
              path={page.path}
              render={(props: RouterProps) => (
                <AuthLayout>
                  <Component {...props} />
                </AuthLayout>
              )}
            />
          );
        })}

        {pagesFilter.length && (
          <Route
            render={() => (
              <PageLayout isCheckAuth={true}>
                <ChooseWorkSpace>
                  <ChooseRole>
                    <CheckNewCompany>
                      <UnauthorizedAlert />
                    </CheckNewCompany>
                  </ChooseRole>
                </ChooseWorkSpace>
              </PageLayout>
            )}
          />
        )}
      </Switch>
      <BackToTop />
    </Suspense>
  );
};

export default App;
