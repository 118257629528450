import wretch, { Wretcher } from "wretch";
import type { Post, Get, Put, Delete } from "common/api/types";
import { getCookie } from "../../common/helpers/cookies";
interface ActionCreators {
  //ApplsScreen
  getApplsScreen: Get<Record<string, unknown>, Record<string, unknown>>;
  updateApplsScreen: Put<Record<string, unknown>, Record<string, unknown>>;
  createApplsScreen: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteApplsScreen: Delete<Record<string, unknown>, Record<string, unknown>>;
  //AuthenticationRoles
  getAuthenticationRoles: Get<Record<string, unknown>, Record<string, unknown>>;
  updateAuthenticationRoles: Put<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  createAuthenticationRoles: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  deleteAuthenticationRoles: Delete<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  //ScreenRoleMapping
  getScreenRoleMapping: Get<Record<string, unknown>, Record<string, unknown>>;
  getScreenRoleMappingByRoleCode: Get<Record<string, unknown>, Record<string, unknown>>;
  getScreenRoleMappingForTeamMember: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  getScreenPermissionForTeamMember: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateScreenRoleMapping: Put<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  createScreenRoleMapping: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  deleteScreenRoleMapping: Delete<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  //ScreenFieldRoleMapping
  getScreenFieldRoleMapping: Get<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  updateScreenFieldRoleMapping: Put<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  createScreenFieldRoleMapping: Post<
    Record<string, unknown>,
    Record<string, unknown>
  >;
  deleteScreenFieldRoleMapping: Delete<
    Record<string, unknown>,
    Record<string, unknown>
  >;
}
const baseUrl = process.env.REACT_APP_ROLEMGMT_URL
//ApplsScreen
const applsScreenUrl = baseUrl + "/authScrn";
//AuthenticationRoles
const authenticationRolesUrl = baseUrl + "/authRole";

//ScreenRoleMapping
const screenRoleMappingUrl =
  baseUrl + "/authScrnMapping";
const screenRoleMappingForTeamMemberUrl =
  baseUrl + "/authScrnMpngForTeamMember";
const getAuthScrnMappingsForTeamMemberWithoutTabsUrl =
  baseUrl +
  "/getAuthScrnMappingsForTeamMemberWithoutTabs";
//ScreenFieldRoleMapping
const screenFieldRoleMappingUrl =
  baseUrl + "/screenFieldRoleMapping";

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  //ApplsScreen
  getApplsScreen: (queryParams = {}) =>
    gateway.url(`${applsScreenUrl}/${queryParams.companyId}`).get().json(),
  updateApplsScreen: (body) => gateway.url(applsScreenUrl).put(body).json(),
  createApplsScreen: (body) => gateway.url(applsScreenUrl).post(body).json(),
  deleteApplsScreen: (companyId, authScrnId) =>
    gateway
      .url(`${applsScreenUrl}-delete/${companyId}/${authScrnId}`)
      .delete()
      .json(),

  //AuthenticationRoles
  getAuthenticationRoles: (queryParams = {}) =>
    gateway
      .url(`${authenticationRolesUrl}/${queryParams.companyId}`)
      .get()
      .json(),
  updateAuthenticationRoles: (body) =>
    gateway.url(authenticationRolesUrl).put(body).json(),
  createAuthenticationRoles: (body) =>
    gateway.url(authenticationRolesUrl).post(body).json(),
  deleteAuthenticationRoles: (companyId, authenticationRolesId) =>
    gateway
      .url(
        `${authenticationRolesUrl}-delete/${companyId}/${authenticationRolesId}`
      )
      .delete()
      .json(),

  //ScreenRoleMapping
  getScreenRoleMapping: (queryParams = {}) =>
    gateway
      .url(`${screenRoleMappingUrl}/${queryParams.companyId}`)
      .get()
      .json(),
   //ScreenRoleMapping
  getScreenRoleMappingByRoleCode: (queryParams = {}) =>
   gateway
     .url(`${baseUrl}/get-by-role-code/${queryParams.companyId}/${queryParams.roleCode}`)
     .get()
     .json(),
  getScreenRoleMappingForTeamMember: (queryParams = {}) =>
    gateway
      .url(
        `${screenRoleMappingForTeamMemberUrl}/${queryParams.companyId}/${queryParams.email}`
      )
      .get()
      .json(),
  getScreenPermissionForTeamMember: (queryParams = {}) =>
    gateway
      .url(
        `${getAuthScrnMappingsForTeamMemberWithoutTabsUrl}/${queryParams.companyId}/${queryParams.email}`
      )
      .get()
      .json(),
  updateScreenRoleMapping: (body) =>
    gateway.url(screenRoleMappingUrl).put(body).json(),
  createScreenRoleMapping: (body) =>
    gateway.url(screenRoleMappingUrl).post(body).json(),
  deleteScreenRoleMapping: (companyId, screenRoleMappingId) =>
    gateway
      .url(`${screenRoleMappingUrl}-delete/${companyId}/${screenRoleMappingId}`)
      .delete()
      .json(),

  //ScreenFieldRoleMapping
  getScreenFieldRoleMapping: (queryParams = {}) =>
    gateway
      .url(`${screenFieldRoleMappingUrl}/${queryParams.companyId}`)
      .get()
      .json(),
  updateScreenFieldRoleMapping: (body) =>
    gateway.url(screenFieldRoleMappingUrl).put(body).json(),
  createScreenFieldRoleMapping: (body) =>
    gateway.url(screenFieldRoleMappingUrl).post(body).json(),
  deleteScreenFieldRoleMapping: (companyId, screenFieldRoleMappingId) =>
    gateway
      .url(
        `${screenFieldRoleMappingUrl}-delete/${companyId}/${screenFieldRoleMappingId}`
      )
      .delete()
      .json(),
});

export const roleMgmtActionCreators = (): {
  actionCreators: ActionCreators;
  headers: Record<string, string>;
} => {
  const authHeaders: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const defaultHeaders: { [key: string]: string } = {
    // 'Session-Id': getCookie('sessionId'),
    "X-Api-Key": process.env.REACT_APP_X_API_KEY_ROLEMGMT as any,
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("accessToken")}` || ``,
  };

  const headers = !getCookie("accessToken") ? authHeaders : defaultHeaders;
  const gateway = wretch().headers(headers);
  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
