import Typography from "@mui/material/Typography";
import { useCallback } from "react";

interface NotifyText {
  text: string;
  type: "error" | "warning" | "success";
}

const NotifyText: React.FC<NotifyText> = ({ text, type }) => {
  const handleColor = useCallback(() => {
    switch (type) {
      case "error":
        return {
          color: "rgb(207, 97, 97)",
          backgroundColor: "rgb(253, 237, 237)",
        };

      case "warning":
        return {
          color: "rgb(243, 154, 82)",
          backgroundColor: "rgb(253, 237, 237)",
        };
      case "success":
        return {
          color: "rgb(22, 175, 70)",
          backgroundColor: "rgb(223, 243, 229)",
        };

      default:
        break;
    }
  }, [type]);

  return (
    <Typography
      sx={{
        textAlign: "center",
        padding: "5px 0",
        borderRadius: "4px",
        marginY: "10px",
        ...handleColor(),
      }}
    >
      {text}
    </Typography>
  );
};

export default NotifyText;
