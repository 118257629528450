import styled from "@emotion/styled";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import {  setCookie } from "common/helpers/cookies";
import React, { useState } from "react";
import useCompanyActions from "timesheets/api/actions/companyCalls";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";

const WsWrapper = styled.div`
	width: 600px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding: 10px;
	margin: 0 auto;
	margin-top: 50px;
`;

const WsItem = styled.div`
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	cursor: pointer;
	gap: 10px;
	padding: 10px 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const WsLogo = styled.img`
	object-fit: cover;
	height: 50px;
	width: 50px;
	border-radius: 5px;
`;

interface IWorkSpaceContent {
	companyArrayInfo: any;
	teamAssoArrayInfo: any;
}
 const WorkSpaceContent: React.FC<IWorkSpaceContent> = (props) => {
	const { companyArrayInfo, teamAssoArrayInfo } = props;
	const [ loading, setLoading ] = useState<boolean>(false)

		const context = useTimeSheetContext();

		const handleChooseCompany = (company: any) => {
			setLoading(true)
			setCookie("currentWorkSpace", JSON.stringify(company));
			// setWsStep("WS");
			// fetchTeamAssociateWithCompanyInit(company?.companyId, company?.email);
			window.location.reload()
		};

		return (
			<Container maxWidth={"lg"}>
				<Typography textAlign={"center"} fontWeight={"bold"} variant={"h3"} paddingTop={10}>
					Choose a company
				</Typography>
				<Typography textAlign={"center"} variant={"subtitle1"}>
					Easily switch between companies
				</Typography>
				<Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
					{loading && <CircularProgress />}
				</Box>
				<WsWrapper>
					<Typography variant={"subtitle1"} paddingBottom={"10px"}>
						Your Companies
					</Typography>
					{companyArrayInfo?.length ? (
						companyArrayInfo.map((item, index) => {
							return (
								<WsItem
									key={index}
									onClick={() =>
										handleChooseCompany(
											teamAssoArrayInfo.find(
												(teamAsso) => teamAsso.companyId === item.companyId
											)
										)
									}>
									<WsLogo src={item.logoUrl} />
									<Typography fontWeight={"bold"}>{item.companyName}</Typography>
								</WsItem>
							);
						})
					) : (
						<h1>Something went wrong !</h1>
					)}
				</WsWrapper>
			</Container>
		);
 };

export default WorkSpaceContent;
