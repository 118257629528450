import makeRequest from "common/api/makeRequest";
import useActionCreators from "common/api/useActionCreators";
import { setCookie } from "common/helpers/cookies";

const clientIdForTimeSheet = process.env.REACT_APP_AUTH_CLIENT_ID;
const clientSecretForTimeSheet = process.env.REACT_APP_AUTH_CLIENT_SECRET;
const tokenUrlForTimeSheet = process.env.REACT_APP_AUTH_API_URL;

const clientIdForCompany = process.env.REACT_APP_COMPANY_CLIENT_ID;
const clientSecretForCompany = process.env.REACT_APP_COMPANY_CLIENT_SECRET;
const tokenUrlForCompany = process.env.REACT_APP_COMPANY_API_URL;

const clientIdForCommunication =
  process.env.REACT_APP_COMMUNICATION_AUTH_CLIENT_ID;
const clientSecretForCommunication =
  process.env.REACT_APP_COMMUNICATION_AUTH_CLIENT_SECRET;
const tokenUrlForCommunication = process.env.REACT_APP_COMMUNICATION_TOKEN_URL;

export const helperToken = () => {
  const {
    actionCreators: { getToken },
  } = useActionCreators();

  const tokenCompany = (
    setIsToken,
    setTriggerTokenCompany,
    triggerTokenCompany
  ) => {
    makeRequest(
      () =>
        getToken({
          clientId: clientIdForCompany,
          clientSecret: clientSecretForCompany,
          tokenUrl: tokenUrlForCompany,
        }),
      (data) => {
        setIsToken(true);
        setCookie(
          "accessToken",
          data?.access_token,
          Number(data?.expires_in) / 60
        ); // To Do: need to add expiry time [options]
        const now = new Date();
        const timeExpires = now.getTime() + Number(data?.expires_in) * 1000;
        localStorage.setItem("expireaccessToken", String(timeExpires));
        localStorage.setItem('accessToken', data?.access_token);
        setTriggerTokenCompany(!triggerTokenCompany);
      },
      (err) => {
        setIsToken(false);
      }
    );
  };

  const _tokenCommunications = (setTokenCommunications) => {
    makeRequest(
      () =>
        getToken({
          clientId: clientIdForCommunication,
          clientSecret: clientSecretForCommunication,
          tokenUrl: tokenUrlForCommunication,
        }),
      (data) => {
        setTokenCommunications(data?.access_token || "");
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const tokenTimeSheet = (
    setIsToken,
    setTriggerTokenTimeSheet,
    triggerTokenTimeSheet
  ) => {
    makeRequest(
      () =>
        getToken({
          clientId: clientIdForTimeSheet,
          clientSecret: clientSecretForTimeSheet,
          tokenUrl: tokenUrlForTimeSheet,
        }),
      (data) => {
        setIsToken(true);
        setCookie(
          "accessToken",
          data?.access_token,
          Number(data?.expires_in) / 60
        ); // To Do: need to add expiry time [options]
        const now = new Date();
        const timeExpires = now.getTime() + Number(data?.expires_in) * 1000;
        localStorage.setItem("expireAccessToken", String(timeExpires));
        localStorage.setItem('accessToken', data?.access_token);
        setTriggerTokenTimeSheet(!triggerTokenTimeSheet);
      },
      (err) => {
        setIsToken(false);
      }
    );
  };

  return {
    tokenCompany,
    tokenTimeSheet,
    _tokenCommunications,
  };
};
