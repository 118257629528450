import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import FormItemLabel from "common/components/FormItemLabel";
import UgotaGiftButton from "common/components/UgotaGiftButton";
import { ROUTES } from "components/Shared/pages";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import makeRequest from "common/api/makeRequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { useAppContext } from "apps/context";
import { makeStyles } from "@material-ui/core";
import * as _ from "lodash";
import { GridContainerStyled } from "timesheets/styles/common";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import NotifyText from "common/components/NotifyText";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";
import { awsPasswordPolicy } from "timesheets/utils/regex";
const useStyles = makeStyles({
  wrapperConfirmSignup: {
    padding: "58px",
    position: "relative",
    borderRadius: "24px",
    background: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  },
});

const CompleteNewPasswordForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useTimeSheetContext();
  const { completeNewPass, setAuthInfo, setIsAuth } = context;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Require!"),
      confirmPassword: Yup.string().required("Require!"),
    }),
    onSubmit: async (values) => {
      const { password, confirmPassword } = values;
      if (password !== confirmPassword) {
        setError("The password confirmation does not match");
      } else {
        setError("");
        handleSubmit(values);
      }
    },
  });

  const handleSubmit = useCallback((payload) => {
    setLoading(true);
    const { password } = payload;
    makeRequest(
      () => {
        return Auth.completeNewPassword(completeNewPass, password);
      },
      (user: any) => {
        setLoading(false);
        const teamAssociateInfoEncrp =
          user.signInUserSession.idToken.payload.teamAssociateInfo;
        const teamAssociateInfoStr = atob(teamAssociateInfoEncrp);
        const teamAssociateInfo = JSON.parse(teamAssociateInfoStr);
        localStorage.setItem("associateToken", teamAssociateInfo.encryptAssociate)
        setLoading(false);
        setError("");
        setAuthInfo(teamAssociateInfo);
        setIsAuth(true);
        history.push("/");
      },
      (e) => {
        setLoading(false);
        setError(awsPasswordPolicy.message);
      }
    );
  }, []);

  useEffect(() => {
    _.isEmpty(completeNewPass) && history.push(ROUTES.login);
  }, [completeNewPass]);

  return (
    <Box className={classes.wrapperConfirmSignup}>
      <FormikProvider value={formik}>
        <Form>
          <Box display="flex" justifyContent={"center"}>
            <img src={TSLogo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography textAlign={"center"} fontWeight="bold" fontSize={"36px"}>
            Change your password
          </Typography>
          <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={12}>
              <Field name={`password`}>
                {({ field, meta }) => (
                  <FormItemLabel label="New Password">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      type={isShowPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <VisibilityIcon
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      }}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              <Field name={`confirmPassword`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Confirm New Password">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      type={isShowPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <VisibilityIcon
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      }}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              {error && <NotifyText type="error" text={error} />}
            </Grid>
            <Stack
              display={"flex"}
              flexDirection="row"
              gap={"10px"}
              justifyContent="center"
              marginTop={"20px"}
              width={"100%"}
            >
              <UgotaGiftButton type="submit" loading={loading}>
                CONFIRM
              </UgotaGiftButton>
            </Stack>
          </GridContainerStyled>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default CompleteNewPasswordForm;
