import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	blockHeader: { display: "block" },
	userBox: {
		display: "flex",
		alignItems: "center",
		textAlign: "center",
	},
	userMenu: { display: "block" },
}));
