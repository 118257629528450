import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const PaymentContext = createContext<any>({});

interface IPaymentContextProvider {
  payment: any,
  setPayment: (value: any) => any,
  payments: any,
  setPayments: (value: any) => any
}

export const PaymentContextProvider = ({ children }: any) => {
  //payment
  const [payment, setPayment] = useState<any>({});
  const [payments, setPayments] = useState<any>([]);

  const memoizedValue = useMemo(
    () => ({
      payment,
      setPayment,
      payments,
      setPayments
    }),
    [
      payment,
      setPayment,
      payments,
      setPayments
    ]
  );
  // prevent memory leak
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <PaymentContext.Provider value={memoizedValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = (): IPaymentContextProvider => useContext(PaymentContext);
