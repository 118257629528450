import { Loader } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";

const CustomLoader = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <Loader style={{ width: "50px", height: "50px" }}/>
  </Box>
);

export default CustomLoader;
