import { Box, Button } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const UnauthorizedAlert = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "60vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <p>
          This screen does not exist or you do not have access to this screen !!
        </p>
        <Button
          color="error"
          variant="outlined"
          onClick={() => history.goBack()}
        >
          GO BACK
        </Button>
      </div>
    </Box>
  );
};

export default UnauthorizedAlert;
