import { Box, CircularProgress, MenuItem, TextField } from "@mui/material";
import makeRequest from "common/api/makeRequest";
import FormItemLabel from "common/components/FormItemLabel";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Chart from "react-chartjs-2";
import { dashboardActionCreators } from "timesheets/api/dashboardActions";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { getYearlyData } from "timesheets/utils";

export const options = {
  maintainAspectRatio: true,
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    ],
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
interface ICompDashBoardChart {
  type: "VENDOR" | "CLIENT" | "COMPANY";
  currentId?: string;
  isCompanyView?: boolean;
  title?: string;
  companyChartData?: any;
}

const DashBoardChart: React.FC<ICompDashBoardChart> = (props) => {
  const { type, currentId, isCompanyView = false, title, companyChartData } = props;
  
  const context = useTimeSheetContext();
  const { teamAssociate } = context;
  const companyId = teamAssociate?.companyId;

  const [year, setYear] = useState<any>("");
  const [datesData, setDatesData] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>([]);

  const {
    actionCreators: { getInvoiceChartForClient, getPaymentChartForVendor },
  } = dashboardActionCreators();

  const renderLabel = useCallback(() => {
    switch (type) {
      case "CLIENT":
        return "Invoices";
      case "VENDOR":
        return "Payments";
      case "COMPANY":
        return title;
      default:
        break;
    }
  }, [type]);

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: `Count of ${renderLabel()}`,
          data: chartData.map((item) => {
            const fieldName =
              type === "CLIENT" ? "countsOfInvoice" : "countsOfPayment";
            return item?.[fieldName] || 0;
          }),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    }),
    [chartData]
  );

  const handleGetChartDashboardClient = React.useCallback(
    (clientId, year) => {
      setLoading(true);
      makeRequest(
        () => {
          return getInvoiceChartForClient({ companyId, clientId, year });
        },
        (data: any) => {
          setChartData(data.invoicesInfo);
          setLoading(false);
        },
        () => setLoading(false)
      );
    },
    [getInvoiceChartForClient]
  );

  const handleGetChartDashboardVendor = React.useCallback(
    (vendorId, year) => {
      setLoading(true);
      makeRequest(
        () => {
          return getPaymentChartForVendor({ companyId, vendorId, year });
        },
        (data: any) => {
          setChartData(data.paymentsInfo);
          setLoading(false);
        },
        () => setLoading(false)
      );
    },
    [getPaymentChartForVendor]
  );

  useEffect(() => {
    setYear(moment().format("YYYY"));
    setDatesData(getYearlyData(2, true));
  }, []);

  useEffect(() => {
    if (
      (year && teamAssociate?.clientId && type === "CLIENT") ||
      (year && currentId && type === "CLIENT")
    ) {
      const clientId =
        isCompanyView && currentId ? currentId : teamAssociate?.clientId;
      handleGetChartDashboardClient(clientId, year);
    }

    if (
      (year && teamAssociate?.vendorId && type === "VENDOR") ||
      (year && currentId && type === "VENDOR")
    ) {
      const vendorId =
        isCompanyView && currentId ? currentId : teamAssociate?.vendorId;
      handleGetChartDashboardVendor(vendorId, year);
    }

    if (year && type === "COMPANY") {
      setChartData(companyChartData || []);
    }
  }, [year, teamAssociate, isCompanyView, currentId, type , companyChartData]);

    // prevent memory leak
    const [didMount, setDidMount] = React.useState(false);
    React.useEffect(() => {
      setDidMount(true);
      return () => setDidMount(false);
    }, []);
  
    if (!didMount) {
      return null;
    }
  return (
    <div style={{ minHeight: "600px" }}>
      <Box
        sx={{
          marginBottom: "1.5rem",
          paddingBottom: "1rem",
          borderBottom: "1px solid #eaeaea",
        }}
      >
        {renderLabel()} Statistics
      </Box>
      <Box display={"flex"} gap="15px">
        {type !== "COMPANY" && (
          <FormItemLabel label={`Choose year`}>
            <TextField
              size="small"
              select
              value={year || ""}
              onChange={(e) => {
                return setYear(moment(e.target.value).format("YYYY"));
              }}
              sx={{ minWidth: "100px" }}
            >
              {datesData.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.year}>
                    {moment(item.year).format("YYYY")}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormItemLabel>
        )}
        <Box sx={{ display: "flex", alignItems: "end" }}>
          {loading && <CircularProgress />}
        </Box>
      </Box>
      <Box>
        <Chart
          type="bar"
          data={data}
          width={300}
          height={200}
          options={options}
        />
      </Box>
    </div>
  );
};

export default DashBoardChart;
