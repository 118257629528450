import { getCookie } from "../../common/helpers/cookies";
import wretch, { Wretcher } from "wretch";
import type {
  Get,
  GetOne,
  Post,
  Put,
  Patch,
  Delete,
} from "../../common/api/types";
import { Auth } from "../schema/auth.schema";

export interface ActionCreators {
  getToken: Post<Record<string, unknown>, Auth>;
  // getCampaigns: Post<Record<string, unknown>, Record<string, unknown>>;
  // defaultCampaign: Post<Record<string, unknown>, Record<string, unknown>>;
  // addCampaign: Post<Record<string, unknown>, Record<string, unknown>>;
  // updateCampaign: Put<
  //   Record<string, unknown> | string[],
  //   Record<string, unknown>
  // >;
  getApiList: Post<Record<string, unknown>, Record<string, unknown>>;
}

const token = `${process.env.REACT_APP_AUTH_API_URL}`;
const apiList = `${process.env.REACT_APP_DEFAULT_API_URL}/jotp-webhooks`;

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  getToken: ({ clientId, clientSecret, tokenUrl }: any) =>
    gateway
      .query({
        grant_type: "client_credentials",
        client_id: clientId,
        client_secret: clientSecret,
      })
      .url(tokenUrl)
      .post({})
      .json(),

  getApiList: (body) => gateway.url(apiList).post(body).json(),
});

export const useActionCreators = () => {
  const authHeaders: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  // const defaultHeaders: { [key: string]: string } = {
  //   // 'Session-Id': getCookie('sessionId'),
  //   "X-Api-Key": process.env.REACT_APP_X_API_KEY as any,
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${getCookie("accessToken")}` || ``,
  // };

  // const headers = !getCookie("accessToken") ? authHeaders : defaultHeaders;
  const headers = authHeaders;

  const gateway = wretch().headers(headers);

  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
export default useActionCreators;
