import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { TimeSheetContextProvider } from "timesheets/context/TimeSheetContext";
import { HolidayContextProvider } from "timesheets/context/HolidayContext";
import { PaymentContextProvider } from "timesheets/context/PaymentContext";
import { VendorContextProvider } from "timesheets/context/VendorContext";
import CustomSnackbar from "timesheets/components/CustomSnackbar";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import moment from "moment";

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

ReactDOM.render(
  <TimeSheetContextProvider>
    <HolidayContextProvider>
      <VendorContextProvider>
        <PaymentContextProvider>
			<CustomSnackbar>
				{/* @ts-ignore */}{/* eslint-disable-line */}
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<Router>
						<App />
					</Router>
				</LocalizationProvider>
			</CustomSnackbar>
		</PaymentContextProvider>
      </VendorContextProvider>
    </HolidayContextProvider>
  </TimeSheetContextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
