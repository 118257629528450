import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import FormItemLabel from "common/components/FormItemLabel";
import UgotaGiftButton from "common/components/UgotaGiftButton";
import { ROUTES } from "components/Shared/pages";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import makeRequest from "common/api/makeRequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { useAppContext } from "apps/context";
import { makeStyles } from "@material-ui/core";
import * as _ from "lodash";
import { GridContainerStyled } from "timesheets/styles/common";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";

const useStyles = makeStyles({
  wrapperConfirmSignup: {
    padding: "58px",
    position: "relative",
    borderRadius: "24px",
    background: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  },
});

const ConfirmSignUpForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  // const context = useAppContext();
  // const { setSnackbar, emailAuth } = context;
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Require!"),
    }),
    onSubmit: async (values) => {
      const { code } = values;
      // handleSubmit({ code, email: emailAuth });
    },
  });

  const handleSubmit = useCallback((payload) => {
    setLoading(true);
    const { email, code } = payload;
    makeRequest(
      () => {
        return Auth.confirmSignUp(email, code);
      },
      (d) => {
        setLoading(false);
      },
      (e) => {
        setLoading(false);

        // setSnackbar({
        //   status: true,
        //   type: "error",
        //   content: e?.message,
        // });
      }
    );
  }, []);

  // useEffect(() => {
  //   _.isEmpty(emailAuth) && history.push(ROUTES.login);
  // }, [emailAuth]);

  return (
    <Box className={classes.wrapperConfirmSignup}>
      <FormikProvider value={formik}>
        <Form>
          <Box display="flex" justifyContent={"center"}>
            <img src={TSLogo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography textAlign={"center"} fontWeight="bold" fontSize={"36px"}>
            We Emailed You
          </Typography>
          <Typography textAlign={"center"} sx={{ color: "rgba(0,0,0,0.5)" }}>
            Your code is on the way. To log in, enter the code we emailed to
            p***@g***. It may take a minute to arrive.
          </Typography>
          <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={12}>
              <Field name={`password`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Password">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      type={isShowPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <VisibilityIcon
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      }}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Stack
              display={"flex"}
              flexDirection="row"
              gap={"10px"}
              justifyContent="center"
              marginTop={"20px"}
              width={"100%"}
            >
              <UgotaGiftButton type="submit" loading={loading}>
                LOG IN
              </UgotaGiftButton>
            </Stack>
          </GridContainerStyled>
          <Typography marginTop="50px" textAlign={"right"}>
            Don't have an account ?
          </Typography>
          <Typography
            // color={globalColor.primary}
            fontWeight="bold"
            textAlign={"right"}
            sx={{ cursor: "pointer" }}
            // onClick={() => history.push(ROUTES.signUp)}
          >
            Sign up
          </Typography>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default ConfirmSignUpForm;
