export const DAY_DEFAULT = [
  { label: "Monday", value: "MONDAY" },
  { label: "Tuesday", value: "TUESDAY" },
  { label: "Wednesday", value: "WEDNESDAY" },
  { label: "Thursday", value: "THURSDAY" },
  { label: "Friday", value: "FRIDAY" },
  { label: "Saturday", value: "SATURDAY" },
  { label: "Sunday", value: "SUNDAY" },
];

export const workingHoursList = {
  ["DAILY"]: 8,
  ["WEEKLY"]: 40,
  ["BI-WEEKLY"]: 80,
  ["MONTHLY"]: 160,
};

export const TIMESHEET_PATTERN_LIST = [
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "BI-WEEKLY", label: "Bi Weekly" },
  { value: "MONTHLY", label: "Monthly" },
];

export const TIMESHEET_FINANCE_LIST = [
  { value: "WEEKLY", label: "Weekly" },
  { value: "BI-WEEKLY", label: "Bi Weekly" },
  { value: "MONTHLY", label: "Monthly" },
];
export const DISTRIBUTE_TO_LIST = [
  { value: "VENDOR", label: "Vendor" },
  { value: "MULTIPLE", label: "Multiple" },
  { value: "NONE", label: "None" },
];
export const TIMESHEET_DETAILS_FORMAT_LIST = [
    { value: "1", label: "Default" },
    { value: "2", label: "New" },
  ];
