import { makeStyles } from "@material-ui/core";
import styled from "@emotion/styled";
import {
  AccordionSummary,
  Grid,
  Paper,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

export const sxModalMultiple = [
  {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 850,
    bgcolor: "white",
    borderRadius: "10px",
    maxHeight: "100vh !important",
    overflowY: "auto !important",
    overflowX: "hidden",
  },
];

export const sxDataGrid = {
  "&.MuiDataGrid-root": {
    ".MuiDataGrid-cell": {
      "&:not(:last-child)": {
        borderRight: "1px solid rgba(0,0,0,0.1)",
        justifyContent: "left",
      },
    },
    ".MuiDataGrid-columnHeaderTitleContainer": {
      "&:not(:last-child)": {
        justifyContent: "left",
      },
    },
  },
  // "& .MuiDataGrid-renderingZone": {
  //   maxHeight: "none !important",
  // },
  // "& .MuiDataGrid-cell": {
  //   lineHeight: "unset !important",
  //   maxHeight: "none !important",
  //   whiteSpace: "normal"
  // },
  // "& .MuiDataGrid-row": {
  //   maxHeight: "none !important"
  // }
};

export const useStylesCommon = makeStyles((theme) => ({
	contentWrapper: {
		padding: "1rem",
		[theme.breakpoints.down("xs")]: {
			padding: "0.5rem",
		},
	},
	textRight: {
		textAlign: "right",
		margin: "15px 0",
		display: "flex",
		justifyContent: "flex-end",
	},
	btnWrapper: {
		margin: "0 auto",
		display: " flex",
		gap: "10px",
		justifyContent: "center",
	},
	btnRightWrapper: {
		display: " flex",
		gap: "10px",
		justifyContent: "flex-end",
		width: "100%",
		paddingRight: "1rem",
	},
	pinnedColumnHeaders: {
		background: "#8d918d",
		color: "rgba(255,255,255,0.9)",
	},
	columnHeader: {
		background: "#8d918d",
		color: "rgba(255,255,255,0.9)",
	},
	columnHeaders: {
		background: "#8d918d",
		color: "rgba(255,255,255,0.9)",
	},
	virtualScroller: {
		overflow: "auto !important",
		maxHeight: "780px",
		width: "100%",
	},
	virtualScrollerContent: {
		width: "100%",
	},
	pinnedColumns: {
		'& [data-field="action"]': {
			minWidth: "90px",
			justifyContent: "center",
		},
	},
	highlightRow: {
		background: "#AEC6CF",
	},
	unHighlightRow: {
		background: "unset",
	},
	deleteIcon: {
		cursor: "pointer",
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
	},
	dataGridTable: {
		"& [class~=MuiDataGrid-row]": {
			position: "relative",
			// 	"&:hover": {
			// 		"& svg": {
			// 			display: "block!important",
			// 		},
			// 	},
		},
	},
}));

export const AccordionStyled = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  width: 100%;
`;

export const AccordionStyledResponsive = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  width: 80vw;
`;

export const AccordionTitleStyled = styled.div`
	background-color: #6f736f;
	color: rgba(255, 255, 255, 0.9);
	padding: 1rem;
	text-transform: uppercase;
	@media screen and (max-width: 450px) {
		padding: 0.5rem;
	}
`;

export const AccordionDetailsStyled = styled.div`
  padding: 30px 20px;
`;
export const MarginTableAssignmentStyled = styled.div`
  padding-left: 5px;
  margin-top: 30px;
`;

export const LoadingTable = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  background-color: #cececc;
  color: #1d1c1a;
`;

export const GridContainerStyled = styled(Grid)`
  align-items: center;
  margin: 5px 0;
  width: 100%;
`;

export const GridContainerStyled2 = styled(Grid)`
  align-items: center;
  margin: 5px 0;
  width: 30%;
`;

export const GridButtonStyled = styled(Grid)`
  align-items: center;
  justify-content: space-around;
  margin: 15px 0;
`;

export const sxModalBox = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 850,
  bgcolor: "white",
  borderRadius: "10px",
  p: 4,
  maxHeight: "100vh !important",
  overflowY: "auto !important",
  overflowX: "hidden",
  "& [class~='ql-editor']": {
    minHeight: "300px!important",
  },
};

export const sxModalAddInChildrenScreen = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 850,
  bgcolor: "white",
  borderRadius: "10px",
  maxHeight: "100vh !important",
  overflowY: "auto !important",
  overflowX: "hidden",
};

export const removeLegendTextField = {
  "& .MuiOutlinedInput-notchedOutline legend": { opacity: "0" },
};

export const removeLegendTextFieldTimer = {
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
  width: "150px",
};

export const TextFieldFixLegend = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline legend": { display: "none" },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0,0,0,0.05)",
    color: "rgba(255,255,255,0.05)",
  },
}));

export const RenderHeaderCell = styled.div`
  color: "rgba(255,255,255,0.9)";
`;

export const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "rgba(255,255,255,0.9)",
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));

export const TableHeadStyled = styled(TableHead)`
  background-color: #8d918d;
`;

export const TypoModalTitle = styled(Typography)`
  font-weight: bold;
  text-align: center;
  font-size: 26px;
  margin-bottom: 15px;
`;

export const TitleBold = styled(Typography)`
  font-weight: bold;
`;

export const ModalFormWrapper = styled.div`
  padding: 10px 30px;
  min-width: 500px;
  @media (max-width: 650px) {
    min-width: 400px;
  }
`;

export const AccordionTitleEffect = styled(AccordionSummary)`
  background-color: #6f736f;
  color: rgba(255, 255, 255, 0.9);
  padding: 0px 30px;
  text-transform: uppercase;
`;
