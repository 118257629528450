import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { CachedImage } from "common/components/CachedImage";
import SelectCustomForm from "common/components/Form/SelectCustomForm";
import { useEffect, useState } from "react";
import {
  AccordionSummaryStyled,
  GridContainerStyled,
  GridContainerStyled2,
} from "timesheets/styles/common";
import { uploadActions } from "../../api/uploadActions";
import {
  DAY_DEFAULT,
  DISTRIBUTE_TO_LIST,
  TIMESHEET_DETAILS_FORMAT_LIST,
  TIMESHEET_FINANCE_LIST,
  TIMESHEET_PATTERN_LIST,
  workingHoursList,
} from "./constant";
import makeRequest from "common/api/makeRequest";
import TextFieldInput from "common/components/Form/TextFieldInput";

const logoUrl =
  "https://web.associate360view.com/static/media/a360viewLogo.cbf11896.png";
interface ICompanyTimeSheet {
  formik: any;
  handleOpenModalMultiple?: any;
  isModal?: boolean;
  isView?: boolean;
}

const CompanyTimeSheet: React.FC<ICompanyTimeSheet> = (props) => {
  const {
    formik,
    handleOpenModalMultiple,
    isModal = false,
    isView = false,
  } = props;

  const [inputKey, setInputKey] = useState(Math.random().toString(16));
  const [file, setFile] = useState(null);
  const [financeList, setFinanceList] = useState<
    { value: string; label: string }[]
  >(TIMESHEET_FINANCE_LIST || []);
  const [loading, setLoading] = useState(false);
  const { uploadFileAxios } = uploadActions;

  useEffect(() => {
    if (file) {
      setLoading(true);
      makeRequest(
        () => {
          return uploadFileAxios(file).then((res) => res.data);
        },
        (data: any) => {
          setLoading(false);
          formik.setFieldValue("companyLogoUrl", data?.data?.Location);
        },
        (err: any) => {
          setLoading(false);
        }
      );
    }
  }, [file]);

  const handleClick = () => {
    // setInputKey(Math.random().toString(16));
    document.getElementById("file")?.click();
  };

  useEffect(() => {
    setFinanceList(TIMESHEET_FINANCE_LIST);
  }, []);

  const handleChangePattern = (event: any) => {
    if (event?.value === "MONTHLY")
      formik.setFieldValue("timeSheetFrequency", "MONTHLY");
    if (event?.value === "BI-WEEKLY")
      formik.setFieldValue("timeSheetFrequency", "BI-WEEKLY");
  };

  useEffect(() => {
    let tempArray: { value: string; label: string }[] = [];
    formik.setFieldValue(
      "defaultWorkingHours",
      workingHoursList[formik?.values?.timeSheetPattern]
    );
    switch (formik?.values?.timeSheetPattern) {
      case "MONTHLY":
        tempArray = [{ value: "MONTHLY", label: "Monthly" }];
        break;

      case "BI-WEEKLY":
        tempArray = [
          { value: "BI-WEEKLY", label: "Bi Weekly" },
          { value: "MONTHLY", label: "Monthly" },
        ];
        break;

      default:
        tempArray = TIMESHEET_FINANCE_LIST;
        break;
    }

    setFinanceList(tempArray);
    return () => setFinanceList([]);
  }, [formik?.values?.timeSheetPattern]);

  const handleFrequencyLabel = (value) => {
    console.log({value});
    switch (value) {
      case "MONTHLY":
        return "month";
      case "BI-WEEKLY":
        return "bi week";
      case "WEEKLY":
        return "Week";
      default:
        return "week";
    }
  };

  return (
    <GridContainerStyled container spacing={2}>
      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
            <Typography>Company Logo</Typography>
          </AccordionSummaryStyled>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                gap: 8,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: 90,
                  height: 90,
                  boxShadow: "2px 2px 5px #e2e2e2",
                  overflow: "hidden",
                  p: 1,
                }}
              >
                <CachedImage
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                  src={formik.values?.companyLogoUrl || logoUrl}
                  alt="logo"
                />
              </Box>
              <input
                key={inputKey}
                accept="image/*"
                type="file"
                id="file"
                hidden
                onChange={(e: any) => setFile(e.target.files[0])}
              />
              <Box>
                <LoadingButton
                  loading={loading}
                  onClick={handleClick}
                  variant="contained"
                  disabled={isView}
                >
                  Upload
                </LoadingButton>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
            <Typography>Tell us more about your TimeSheet process ?</Typography>
          </AccordionSummaryStyled>
          <AccordionDetails>
            {/* Candidate should fill time sheets timeSheetFrequency*/}
            <GridContainerStyled container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Typography>Candidate should fill time sheets</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <SelectCustomForm
                  dataList={financeList}
                  form={formik}
                  name="timeSheetFrequency"
                  disabled={isView}
                  placeholder="Candidate should fill time sheets"
                  clearable={false}
                />
              </Grid>
            </GridContainerStyled>

            {/* Once approved send approved time sheets to distributeTo*/}
            <GridContainerStyled container spacing={1}>
              {!isModal && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography>
                      Once approved send approved time sheets to
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <SelectCustomForm
                      clearable={false}
                      dataList={DISTRIBUTE_TO_LIST}
                      form={formik}
                      name="distributeTo"
                      disabled={isView}
                      placeholder="Once approved send approved time sheets to"
                      onChange={(event: any) =>
                        event?.value === "MULTIPLE"
                          ? handleOpenModalMultiple()
                          : undefined
                      }
                    />
                  </Grid>
                </>
              )}
            </GridContainerStyled>

            {/* Choose TimeSheet details format*/}
            <GridContainerStyled container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Typography>Choose TimeSheet details format</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <SelectCustomForm
                  clearable={false}
                  dataList={TIMESHEET_DETAILS_FORMAT_LIST}
                  form={formik}
                  name="timeSheetDetailsFormat"
                  disabled={isView}
                  placeholder="Choose TimeSheet details format"
                />
              </Grid>
            </GridContainerStyled>

            {/* Default working hours / day */}
            {/* {false && ( //
              <GridContainerStyled container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Default working hours /{" "}
                    {formik?.values?.timeSheetPattern?.toLowerCase() || "day"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextFieldInput form={formik} name="defaultWorkingHours" disabled={isView} />
                </Grid>
              </GridContainerStyled>
            )} */}

            {/* TimeSheet format pattern*/}
            <GridContainerStyled container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Typography>Choose TimeSheet pattern format</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <SelectCustomForm
                  clearable={false}
                  dataList={TIMESHEET_PATTERN_LIST}
                  form={formik}
                  name="timeSheetPattern"
                  disabled={isView}
                  placeholder="Choose TimeSheet pattern format"
                  onChange={handleChangePattern}
                />
              </Grid>
            </GridContainerStyled>

            {/* TimeSheet format pattern*/}
            <GridContainerStyled container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Typography>Enforce that timesheets are minimum </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box display={"flex"} gap="5px" alignItems={"center"}>
                  <TextFieldInput
                    name="timeSheetsEnforceHours"
                    form={formik}
                    placeholder="hours"
                    type="number"
                  />
                  <Typography>hours / {handleFrequencyLabel(formik.values.timeSheetFrequency)}</Typography>
                </Box>
              </Grid>
            </GridContainerStyled>

            {/* Project code required in timesheet */}
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 4 }}>
              <GridContainerStyled2 container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Typography>Project code required in TimeSheet</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Checkbox
                    disabled={isView}
                    checked={!!formik?.values?.requiredPjCode}
                    onChange={(e) =>
                      formik.setFieldValue("requiredPjCode", e.target.checked)
                    }
                  />
                </Grid>
              </GridContainerStyled2>

              {/* Is timesheet attachment required */}
              <GridContainerStyled2 container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Typography>Is TimeSheet attachment required?</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Checkbox
                    disabled={isView}
                    checked={!!formik.values?.requiredAttachmentTs}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "requiredAttachmentTs",
                        e.target.checked
                      )
                    }
                  />
                </Grid>
              </GridContainerStyled2>

              <GridContainerStyled2 container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Typography>
                    TimeSheet auto approve when approved by
                    <strong> Reviewer </strong> ?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Checkbox
                    disabled={isView}
                    checked={!!formik?.values?.autoApprove}
                    onChange={(e) =>
                      formik.setFieldValue("autoApprove", e?.target?.checked)
                    }
                  />
                </Grid>
              </GridContainerStyled2>
              <Divider sx={{ width: "100%" }} />
              <GridContainerStyled2 container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Typography> Enable Business Capability </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Checkbox
                    disabled={isView}
                    checked={!!formik.values?.isBusinessCapability}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "isBusinessCapability",
                        e.target.checked
                      );
                      if (!e.target.checked)
                        formik.setFieldValue(
                          "isBusinessCapabilityRequired",
                          false
                        );
                    }}
                  />
                </Grid>
              </GridContainerStyled2>
              {formik?.values?.isBusinessCapability && (
                <GridContainerStyled2 container spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Typography> Is Business Capability required? </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Checkbox
                      disabled={isView}
                      checked={!!formik?.values?.isBusinessCapabilityRequired}
                      onChange={(e) => {
                        if (formik?.values?.isBusinessCapability) {
                          formik.setFieldValue(
                            "isBusinessCapabilityRequired",
                            e?.target?.checked
                          );
                        }
                      }}
                    />
                  </Grid>
                </GridContainerStyled2>
              )}
              <Divider sx={{ width: "100%" }} />
              <GridContainerStyled2 container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Typography> Enable Application </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Checkbox
                    disabled={isView}
                    checked={!!formik?.values?.isApplication}
                    onChange={(e) => {
                      formik.setFieldValue("isApplication", e?.target?.checked);
                      if (!e?.target?.checked) {
                        formik.setFieldValue("isApplicationRequired", false);
                        formik.setFieldValue("isApplicationPhase", false);
                        formik.setFieldValue(
                          "isApplicationPhaseRequired",
                          false
                        );
                      }
                    }}
                  />
                </Grid>
              </GridContainerStyled2>
              {formik?.values?.isApplication && (
                <>
                  <GridContainerStyled2 container spacing={1}>
                    <Grid item xs={12} sm={8}>
                      <Typography> Is Application required? </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Checkbox
                        disabled={isView}
                        checked={!!formik?.values?.isApplicationRequired}
                        onChange={(e) => {
                          if (formik?.values?.isApplication) {
                            formik.setFieldValue(
                              "isApplicationRequired",
                              e?.target?.checked
                            );
                          }
                        }}
                      />
                    </Grid>
                  </GridContainerStyled2>
                  <Divider sx={{ width: "100%" }} />
                  <GridContainerStyled2 container spacing={1}>
                    <Grid item xs={12} sm={8}>
                      <Typography> Enable Application Phase ? </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Checkbox
                        disabled={isView}
                        checked={!!formik?.values?.isApplicationPhase}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "isApplicationPhase",
                            e?.target?.checked
                          );
                          if (formik?.values?.isApplicationPhase) {
                            formik.setFieldValue(
                              "isApplicationPhaseRequired",
                              e?.target?.checked
                            );
                          }
                        }}
                      />
                    </Grid>
                  </GridContainerStyled2>
                  {formik?.values?.isApplicationPhase && (
                    <GridContainerStyled2 container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <Typography>
                          {" "}
                          Is Application Phase required?{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Checkbox
                          disabled={isView}
                          checked={!!formik?.values?.isApplicationPhaseRequired}
                          onChange={(e) => {
                            if (formik?.values?.isApplicationPhase) {
                              formik.setFieldValue(
                                "isApplicationPhaseRequired",
                                e?.target?.checked
                              );
                            }
                          }}
                        />
                      </Grid>
                    </GridContainerStyled2>
                  )}
                </>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Tell us more about your TimeSheet notification ?
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetails>
            {/* Send pre TimeSheet reminder */}
            <GridContainerStyled container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Stack direction={"row"} alignItems="center" spacing={1}>
                  <Typography>Send pre TimeSheet reminder on</Typography>
                  <Box>
                    <SelectCustomForm
                      clearable={false}
                      dataList={DAY_DEFAULT}
                      form={formik}
                      name="dayToSendReminder"
                      disabled={isView}
                      placeholder="Choose TimeSheet pattern format"
                    />
                  </Box>
                </Stack>
              </Grid>
            </GridContainerStyled>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </GridContainerStyled>
  );
};

export default CompanyTimeSheet;
