import { makeStyles } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import TSLogo from "assets/images/V1_SnR/png/a360viewLogo.png";
import { Auth } from "aws-amplify";
import makeRequest from "common/api/makeRequest";
import NotifyText from "common/components/NotifyText";
import FormItemLabel from "common/components/FormItemLabel";
import UgotaGiftButton from "common/components/UgotaGiftButton";
import { globalColor } from "common/styles/common";
import { ROUTES } from "components/Shared/pages";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { applActionCreators } from "timesheets/api/companyActions";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { GridContainerStyled } from "timesheets/styles/common";
import { PASSWORD_EXPIRED_NOTIF } from "timesheets/utils/constant";
import * as Yup from "yup";

const useStyles = makeStyles({
  wrapperLogin: {
    padding: "58px",
    position: "relative",
    borderRadius: "24px",
    background: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
  },
  forgotPasswordText: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const LoginForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const context = useTimeSheetContext();

  const { setAuthInfo, setIsAuth, setCompleteNewPass } = context;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const {
    actionCreators: { resetTempPassword },
  } = applActionCreators();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Require!"),
      password: Yup.string().required("Require!"),
    }),
    onSubmit: async (values) => {
      const payload = {
        email: values.email.trim(),
        password: values.password.trim(),
      };
      handleSubmit(payload);
    },
  });

  const handleSubmit = useCallback((payload) => {
    setLoading(true);
    const { email, password } = payload;
    makeRequest(
      () => {
        return Auth.signIn(email, password);
      },
      (user: any) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setCompleteNewPass(user);
          history.push(ROUTES.completedNewPass);
        } else {
          const teamAssociateInfoEncrp =
            user.signInUserSession.idToken.payload.teamAssociateInfo;
          const teamAssociateInfoStr = atob(teamAssociateInfoEncrp);
          const teamAssociateInfo = JSON.parse(teamAssociateInfoStr);
          localStorage.setItem("associateToken", teamAssociateInfo.encryptAssociate)
          setLoading(false);
          setError("");
          setAuthInfo(teamAssociateInfo);
          setIsAuth(true);
          // history.push("/");
          window.location.href = "/";
        }
      },
      (e) => {
        if (e?.message?.includes(PASSWORD_EXPIRED_NOTIF)) {
          handleResetTempPassword(setLoading, email);
        } else {
          setError(e?.message || "FAIL");
          setLoading(false);
        }
      }
    );
  }, []);

  const handleResetTempPassword = useCallback((setLoading, email) => {
    setLoading(true);
    makeRequest(
      () => {
        return resetTempPassword({
          email,
        });
      },
      (data: any) => {
        formik.setFieldValue("password", "");
        setLoading(false);
        setError(
          "Temporary password has expired, please check your email to get a new password"
        );
      },
      (err: any) => {
        context.setOpenSnackbarFail(true);
        setLoading(false);
      }
    );
  }, []);

  return (
    <Box className={classes.wrapperLogin}>
      <FormikProvider value={formik}>
        <Form>
          <Box display="flex" justifyContent={"center"}>
            <img src={TSLogo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography textAlign={"center"} fontWeight="bold" fontSize={"36px"}>
            LOGIN
          </Typography>
          <Typography textAlign={"center"} sx={{ color: "rgba(0,0,0,0.5)" }}>
            Please sign in to continue
          </Typography>
          <GridContainerStyled container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12} md={12}>
              <Field name={`email`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Email">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              <Field name={`password`}>
                {({ field, meta }) => (
                  <FormItemLabel label="Password">
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      type={isShowPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <VisibilityIcon
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      }}
                    ></TextField>
                  </FormItemLabel>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              {error && <NotifyText text={error} type="error" />}
            </Grid>
            <Stack
              display={"flex"}
              flexDirection="row"
              gap={"10px"}
              justifyContent="center"
              marginTop={"20px"}
              width={"100%"}
            >
              <UgotaGiftButton type="submit" loading={loading}>
                LOG IN
              </UgotaGiftButton>
            </Stack>
          </GridContainerStyled>
          <Typography
            className={classes.forgotPasswordText}
            marginTop="50px"
            textAlign={"right"}
            onClick={() => history.push(ROUTES.forgotPassword)}
          >
            Forgot password ?
          </Typography>
          <Typography marginTop="5px" textAlign={"right"}>
            Don't have an account ?
          </Typography>
          <Typography
            color={globalColor.primary}
            fontWeight="bold"
            textAlign={"right"}
            sx={{ cursor: "pointer" }}
            onClick={() => history.push(ROUTES.signUp)}
          >
            Sign up
          </Typography>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default LoginForm;
