import makeRequest from "common/api/makeRequest";
import { useCallback } from "react";
import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { NO_RECORDS_FOUND } from "timesheets/utils/constant";
import { allArchiveActionCreators } from "../allArchiveActions";
import { timeSheetActionCreators } from "../timeSheetActions";

const useTimeSheetActions = () => {
  const context = useTimeSheetContext();
  const { teamAssociate } = context;
  const companyId = teamAssociate?.companyId;

  const {
    actionCreators: {
      // Vendors
      getVendors,
      getVendorDetails,
      createVendor,
      updateVendor,
      deleteVendor,
      archiveVendor,

      // Client
      getClients,
      getClientDetails,
      createClient,
      updateClient,
      deleteClient,
      archiveClient,

      // TimeSheet
      getTimeSheet,
      createTimeSheet,
      getTimeSheetCoreDetails,
      deleteTimeSheet,

      // Roles
      deleteRole,

      getTimeSheetsToReport,
      getPtoForTeamAssociate,
      getPtoForManager,
      getPtoForCompany,
      deletePto,
      createPto,
      updatePto,

      getMemberForCompanyByManager,
    },
  } = timeSheetActionCreators();

  

  const {actionCreators: {getArchiveClients, getArchiveVendors}} = allArchiveActionCreators()

  // Vendors
  const fetchVendors = useCallback(
    (setLoading?) => {
      setLoading && setLoading(true);
      makeRequest(
        () => {
          return getVendors({ companyId });
        },
        (data: any) => {
          data.length ? context.setVendors(data) : context.setVendors([]);
          setLoading && setLoading(false);
        },
        () => {
          setLoading && setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getVendors]
  );

  const fetchArchiveVendors = useCallback(
    (setLoading?) => {
      setLoading && setLoading(true);
      makeRequest(
        () => {
          return getArchiveVendors({ companyId });
        },
        (data: any) => {
          data.length ? context.setVendors(data) : context.setVendors([]);
          setLoading && setLoading(false);
        },
        () => {
          setLoading && setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getArchiveVendors]
  );

  const handleGetVendorDetails = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: (params: any) => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return getVendorDetails({
            companyId: payload.companyId || companyId,
            vendorId: payload.vendorId
          });
        },
        (data) => {
          setLoading(false);
          callbackResult && callbackResult(data);
        },
        () => {
          callbackError && callbackError();
          setLoading(false);
        }
      );
    },
    [getVendorDetails]
  );

  const handleCreateVendor = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: () => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return createVendor(payload);
        },
        () => {
          setLoading(false);
          callbackResult && callbackResult();
          context.setOpenSnackbarSuccess(true);
        },
        () => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [createVendor]
  );

  const handleUpdateVendor = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: () => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return updateVendor(payload);
        },
        () => {
          setLoading(false);
          callbackResult && callbackResult();
          context.setOpenSnackbarSuccess(true);
          fetchVendors();
        },
        () => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [updateVendor]
  );

  const handleDeleteVendor = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: (params?: any) => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return deleteVendor(payload?.companyId, payload?.vendorId);
        },
        (data: any) => {
          setLoading(false);
          callbackResult && callbackResult(data);
          context.setOpenSnackbarSuccess(true);
          fetchVendors();
        },
        (err: any) => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [deleteVendor]
  );

  const handleArchiveVendor = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: () => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return archiveVendor(payload);
        },
        () => {
          setLoading(false);
          callbackResult && callbackResult();
          context.setOpenSnackbarSuccess(true);
          fetchVendors();
        },
        () => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [archiveVendor]
  );
  // Clients
  const fetchClients = useCallback(
    (setLoading) => {
      setLoading(true);
      makeRequest(
        () => {
          return getClients({ companyId });
        },
        (data: any) => {
          data.length ? context.setClients(data) : context.setClients([]);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getClients]
  );

  const fetchArchiveClients = useCallback(
    (setLoading) => {
      setLoading(true);
      makeRequest(
        () => {
          return getArchiveClients({ companyId });
        },
        (data: any) => {
          data.length ? context.setClients(data) : context.setClients([]);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getArchiveClients]
  );

  const handleGetClientDetails = useCallback(
    (
      setLoading,
      clientId,
      callbackResult?: (params: any) => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return getClientDetails({ companyId, clientId });
        },
        (data) => {
          setLoading(false);
          callbackResult && callbackResult(data);
        },
        () => {
          callbackError && callbackError();
          setLoading(false);
        }
      );
    },
    [getClientDetails]
  );

  const handleCreateClient = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: () => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return createClient(payload);
        },
        () => {
          setLoading(false);
          callbackResult && callbackResult();
          context.setOpenSnackbarSuccess(true);
        },
        () => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [createClient]
  );

  const handleUpdateClient = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: () => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return updateClient(payload);
        },
        () => {
          setLoading(false);
          callbackResult && callbackResult();
          context.setOpenSnackbarSuccess(true);
          fetchClients(setLoading);
        },
        () => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [updateClient]
  );

  const handleDeleteClient = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: (params?: any) => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return deleteClient(payload?.companyId, payload?.clientId);
        },
        (data: any) => {
          setLoading(false);
          callbackResult && callbackResult(data);
          context.setOpenSnackbarSuccess(true);
          fetchClients(setLoading);
        },
        (err: any) => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [deleteClient]
  );

  const handleArchiveClient = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: () => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return archiveClient(payload);
        },
        () => {
          setLoading(false);
          callbackResult && callbackResult();
          context.setOpenSnackbarSuccess(true);
          fetchClients(setLoading);
        },
        () => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [archiveClient]
  );
  
  // TimeSheet
  const handleGetTimeSheet = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return getTimeSheet({
						companyId: payload.companyId || companyId,
						teamAssociateId: payload.teamAssociateId,
					});
				},
				(data: any) => {
          callbackResult && callbackResult(data);
					setLoading(false);
				},
				() => {
          callbackError && callbackError();
					setLoading(false);
					context.setOpenSnackbarFail(true);
				}
			);
		},
		[getTimeSheet]
	);

  const handleGetTimeSheetDetails = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return getTimeSheetCoreDetails({
						companyId: payload.companyId || companyId,
						timeSheetId: payload.timeSheetId,
					});
				},
				(data: any) => {
          callbackResult && callbackResult(data);
					setLoading(false);
				},
				() => {
          callbackError && callbackError();
					setLoading(false);
					context.setOpenSnackbarFail(true);
				}
			);
		},
		[getTimeSheetCoreDetails]
	);

  const handleCreateTimeSheet = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return createTimeSheet(payload);
				},
				(data: any) => {
          callbackResult && callbackResult(data);
					setLoading(false);
          context.setOpenSnackbarSuccess(true);
				},
				() => {
          callbackError && callbackError();
					setLoading(false);
					context.setOpenSnackbarFail(true);
				}
			);
		},
		[createTimeSheet]
	);


  const handleDeleteTimeSheet = useCallback(
		(setLoading, payload, callbackResult?: (params?: any) => void, callbackError?: () => void) => {
			setLoading(true);
			makeRequest(
				() => {
					return deleteTimeSheet(payload?.companyId, payload?.timeSheetId);
				},
				(data: any) => {
					setLoading(false);
					callbackResult && callbackResult(data);
					context.setOpenSnackbarSuccess(true);
				},
				(err: any) => {
					setLoading(false);
					callbackError && callbackError();
					context.setOpenSnackbarFail(true);
				}
			);
		},
		[deleteTimeSheet]
	);

  // Roles
  const handleGetRole = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: (params?: any) => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return deleteRole(payload?.companyId, payload?.applRoleId);
        },
        (data: any) => {
          setLoading(false);
          callbackResult && callbackResult(data);
          context.setOpenSnackbarSuccess(true);
        },
        (err: any) => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [deleteRole]
  );

  const handleDeleteRole = useCallback(
    (
      setLoading,
      payload,
      callbackResult?: (params?: any) => void,
      callbackError?: () => void
    ) => {
      setLoading(true);
      makeRequest(
        () => {
          return deleteRole(payload?.companyId, payload?.applRoleId);
        },
        (data: any) => {
          setLoading(false);
          callbackResult && callbackResult(data);
          context.setOpenSnackbarSuccess(true);
        },
        (err: any) => {
          setLoading(false);
          callbackError && callbackError();
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [deleteRole]
  );

  const fetchClientsLocal = useCallback(
    (setLoading, setData?, setClientList?) => {
      setLoading(true);
      makeRequest(
        () => {
          return getClients({ companyId });
        },
        (data: any) => {
          data.length && setData && setData(data);
          data.length && setClientList && setClientList(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getClients]
  );

  const fetchTimeSheetsReport = useCallback(
    (payload, setLoading) => {
      setLoading(true);

      makeRequest(
        () => {
          return getTimeSheetsToReport({ companyId, ...payload });
        },
        (data: any) => {
          if (data.status === NO_RECORDS_FOUND) {
            context.setTimeSheetReport([]);
          } else {
            context.setTimeSheetReport(data);
          }
          setLoading(false);
        },
        () => {
          context.setTimeSheetReport([]);
          setLoading(false);
        }
      );
    },
    [getTimeSheetsToReport]
  );

  const fetchPtoForTeamAssociate = useCallback(
    (setLoading, teamAssociateId) => {
      setLoading(true);
      makeRequest(
        () => {
          return getPtoForTeamAssociate({ companyId, teamAssociateId });
        },
        (data: any) => {
          data.length
            ? context.setPtoForTeamAssociate(data)
            : context.setPtoForTeamAssociate([]);
          setLoading(false);
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getPtoForTeamAssociate]
  );

  const fetchPtoForManager = useCallback(
    (setLoading, managerId) => {
      setLoading(true);
      makeRequest(
        () => {
          return getPtoForManager({ companyId, managerId });
        },
        (data: any) => {
          data.length
            ? context.setPtoForManager(data)
            : context.setPtoForManager([]);
          setLoading(false);
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getPtoForManager]
  );

  const fetchPtoForCompany = useCallback(
    (setLoading) => {
      setLoading(true);
      makeRequest(
        () => {
          return getPtoForCompany({ companyId });
        },
        (data: any) => {
          data.length
            ? context.setPtoForManager(data)
            : context.setPtoForManager([]);
          setLoading(false);
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [getPtoForCompany]
  );

  const addPto = useCallback(
    (setLoading, payload, cb?) => {
      setLoading(true);
      makeRequest(
        () => {
          return createPto(payload);
        },
        (data: any) => {
          context.setOpenSnackbarSuccess(true);
          setLoading(false);
          cb && cb();
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [createPto]
  );

  const handleUpdatePto = useCallback(
    (setLoading, payload, cb?) => {
      setLoading(true);
      makeRequest(
        () => {
          return updatePto(payload);
        },
        (data: any) => {
          setLoading(false);
          context.setOpenSnackbarSuccess(true);
          cb && cb();
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [updatePto]
  );

  const handleDeletePto = useCallback(
    (setLoading, ptoId, cb?) => {
      setLoading(true);
      makeRequest(
        () => {
          return deletePto(companyId || "", ptoId);
        },
        (data: any) => {
          context.setOpenSnackbarSuccess(true);
          setLoading(false);
          cb && cb();
        },
        () => {
          setLoading(false);
          context.setOpenSnackbarFail(true);
        }
      );
    },
    [deletePto]
  );

  	const handleGetMemberForCompanyByManager = useCallback(
		(
			setLoading,
			payload: { companyId: string, managerId: string },
			callbackResult?: (params: any) => void,
			callbackError?: () => void
		) => {
			setLoading(true);
			makeRequest(
				() => {
					return getMemberForCompanyByManager({
						companyId: payload.companyId || companyId,
						managerId: payload.managerId
					});
				},
				(data) => {
					setLoading(false);
					callbackResult && callbackResult(data);
				},
				() => {
					callbackError && callbackError();
					setLoading(false);
				}
			);
		},
		[getMemberForCompanyByManager]
	);


  return {
		//Vendors
		fetchVendors,
    fetchArchiveVendors,
		handleGetVendorDetails,
		handleCreateVendor,
		handleUpdateVendor,
		handleDeleteVendor,
		handleArchiveVendor,

		// Client
		fetchClients,
    fetchArchiveClients,
		handleGetClientDetails,
		handleCreateClient,
		handleUpdateClient,
		handleDeleteClient,
		handleArchiveClient,

		// TimeSheet
    handleGetTimeSheet,
		handleCreateTimeSheet,
		handleGetTimeSheetDetails,
		handleDeleteTimeSheet,

		// Roles
		handleGetRole,
		handleDeleteRole,

		fetchTimeSheetsReport,
		fetchClientsLocal,
		fetchPtoForTeamAssociate,
		fetchPtoForManager,
		fetchPtoForCompany,

		addPto,
		handleUpdatePto,
		handleDeletePto,

    handleGetMemberForCompanyByManager,
	};
};

export default useTimeSheetActions;
