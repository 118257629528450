import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const VendorContext = createContext<any>({});


interface IPaymentContextProvider {
  vendor: any,
  setVendor: (value: any) => any,
  vendors: any,
  setVendors: (value: any) => any
}


export const VendorContextProvider = ({ children }: any) => {
  //vendor
  const [vendor, setVendor] = useState<any>({});
  const [vendors, setVendors] = useState<any>([]);

  const memoizedValue = useMemo(
    () => ({
      vendor,
      setVendor,
      vendors,
      setVendors
    }),
    [
      vendor,
      setVendor,
      vendors,
      setVendors
    ]
  );
  // prevent memory leak
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  return (
    <VendorContext.Provider value={memoizedValue}>
      {children}
    </VendorContext.Provider>
  );
};

export const useVendorContext = (): IPaymentContextProvider => useContext(VendorContext);
