import wretch, { Wretcher } from "wretch";
import type { Post, Get, Put, Delete } from "common/api/types";
import { getCookie } from "../../common/helpers/cookies";
interface ActionCreators {
  //ROLES
  getRoles: Get<Record<string, unknown>, Record<string, unknown>>;
  updateRole: Put<Record<string, unknown>, Record<string, unknown>>;
  createRole: Post<Record<string, unknown>, Record<string, unknown>>;
  deleteRole: Delete<Record<string, unknown>, Record<string, unknown>>;
}
//ROLE
const roleUrls = process.env.REACT_APP_COMPANYMGMT_API_URL + "/applRoles";
const roleUrl = process.env.REACT_APP_COMPANYMGMT_API_URL + "/applRole";

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  //ROLES
  getRoles: (queryParams = {}) =>
    gateway.url(`${roleUrls}/${queryParams.companyId}`).get().json(),
  updateRole: (body) => gateway.url(roleUrl).put(body).json(),
  createRole: (body) => gateway.url(roleUrl).post(body).json(),
  deleteRole: (companyId, roleId) =>
    gateway.url(`${roleUrl}-delete/${companyId}/${roleId}`).delete().json(),
});

export const roleActionCreators = (): {
  actionCreators: ActionCreators;
  headers: Record<string, string>;
} => {
  const authHeaders: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const defaultHeaders: { [key: string]: string } = {
    // 'Session-Id': getCookie('sessionId'),
    "X-Api-Key": process.env.REACT_APP_COMPANY_API_KEY as any,
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("accessToken")}` || ``,
  };

  const headers = !getCookie("accessToken") ? authHeaders : defaultHeaders;
  const w = wretch().catcher(401, (err) => console.log({ err: "401 nè" }));
  const gateway = w.headers(headers);
  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
