import { useTimeSheetContext } from "timesheets/context/TimeSheetContext";
import { decryptData } from "timesheets/utils";

export const makeRequest = async <R,>(
  actionCreator: () => Promise<R>,
  cb?: (res: any) => void,
  logErr?: (error: R) => void
): Promise<R | undefined> => {
  try {
    const res: any = await actionCreator();

    let _res = "";
    if (res?.encryptData) {
      _res = JSON.parse(decryptData(res.encryptData));
    } else {
      _res = res;
    }

    cb && cb(_res);
    return Promise.resolve(res);
  } catch (err: any) {
    if (process.env.REACT_APP_ENVNAME != "prod") {
      console.log({err});
    }
    logErr && logErr(err);
    // throw new Error(err);
  }
};

export default makeRequest;
