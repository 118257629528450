import type { Post } from "common/api/types";
import wretch, { Wretcher } from "wretch";
import { getCookie } from "../../common/helpers/cookies";

interface ActionCreators {
  uploadImage: Post<Record<string, unknown>, Record<string, unknown>>;
  uploadFile: Post<Record<any, any>, Record<any, any>>;
}

const baseUrl = process.env.REACT_APP_COMPANYMGMT_API_URL;
const uploadUrl =
  "https://am6p6g9khl.execute-api.us-east-1.amazonaws.com/test/fileUploadImage";

const uploadFileUrl = baseUrl + "/upload-doc";

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
  uploadImage: (body) => gateway.url(uploadUrl).post(body).json(),
  uploadFile: (body) => gateway.url(uploadFileUrl).post(body),
});

export const uploadActionCreators = (): {
  actionCreators: ActionCreators;
  headers: Record<string, string>;
} => {
  const headers: { [key: string]: string } = {
    "X-Api-Key": process.env.REACT_APP_COMPANY_API_KEY as string,
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getCookie("accessToken")}`,
  };

  const gateway = wretch().headers(headers);
  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
// ==========================
import Axios from "axios";

const instance: any = Axios.create({
  baseURL: process.env.REACT_APP_COMPANYMGMT_API_URL,

  headers: {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${getCookie("accessToken")}`,
    "X-Api-Key": process.env.REACT_APP_COMPANY_API_KEY as string,
  },
});

instance.interceptors.response.use(
  (response) => {
    return { data: response.data?.data ?? response.data, status: response.status };
  },
  (error) => {
    const { code, message } = error;
    return { code, message };
  }
);

export const uploadActions: any = {
  uploadFileAxios: async (payload: any) => {
    const formData = new FormData();
    formData.append("file", payload, payload.name);
    formData.append("companyId", `${getCookie("companyId")}`);
    return instance.post("/fileUploadImage", formData, {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${getCookie("accessToken")}`,
        "X-Api-Key": `${process.env.REACT_APP_COMPANY_API_KEY}`,
      },
      baseURL: `${process.env.REACT_APP_COMPANYMGMT_API_URL}`
    });
  },
};


export const uploadActionsFiles: any = {
  uploadFileAction: async (file: any, typeSelected: any) => {
    const formData = new FormData();
    formData.append("file", file, file.fileName);
    formData.append("code", typeSelected);
    formData.append("companyId", getCookie("companyId") as string);
    return instance.post("https://api.smsj.link/sub-companymgmt/upload-csv/post", formData);
  },
};

export const uploadEmployeeActions: any = {
  uploadFileAxios: async (payload: any) => {
    const formData = new FormData();
    formData.append("file", payload, payload.name);
    formData.append("companyId", `${getCookie("companyId")}`);
    return instance.post("/fileUploadImage", formData, {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${getCookie("accessToken")}`,
        "X-Api-Key": `${process.env.REACT_APP_COMPANY_API_KEY}`,
      },
      baseURL: `${process.env.REACT_APP_COMPANYMGMT_API_URL}`
    });
  },
};
