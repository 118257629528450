import wretch, { Wretcher } from "wretch";
import type { Post, Get, Put, Delete } from "common/api/types";
import { getCookie } from "../../common/helpers/cookies";
interface ActionCreators {
  getArchiveRole: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveTaskCategory: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveAppPhase: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveBusinessCapability: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveBusinessCategory: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveCurrencies: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveSalary: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveApplication: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveAssignments: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveClients: Get<Record<string, unknown>, Record<string, unknown>>;
  getArchiveVendors: Get<Record<string, unknown>, Record<string, unknown>>;
  addTeamAssociateForClient: Post<Record<string, unknown>, Record<string, unknown>>;
  addTeamAssociateForVendor: Post<Record<string, unknown>, Record<string, unknown>>;
  updateTeamAssociateForClient: Put<Record<string, unknown>, Record<string, unknown>>;
  updateTeamAssociateForVendor: Put<Record<string, unknown>, Record<string, unknown>>;
  deleteTeamAssociateForClient: Delete<Record<string, unknown>, Record<string, unknown>>;
  deleteTeamAssociateForVendor: Delete<Record<string, unknown>, Record<string, unknown>>;
}
const allArchiveUrl = process.env.REACT_APP_COMPANY_SERVICE_MGMT_API_URL;
const memberMgmtForClientUrl = allArchiveUrl + "/teamAssociateForClient";
const memberMgmtForVendorUrl = allArchiveUrl + "/teamAssociateForVendor";

export const getActionCreators = (gateway: Wretcher): ActionCreators => ({
    getArchiveRole: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/archiveApplicationRole/${queryParams.companyId}`).get().json(),
    getArchiveTaskCategory: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/archiveApplicationCategory/${queryParams.companyId}`).get().json(),
    getArchiveAppPhase: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/archiveApplicationPhase/${queryParams.companyId}`).get().json(),
    getArchiveBusinessCapability: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/archiveBusinessCapability/${queryParams.companyId}`).get().json(),
    getArchiveBusinessCategory: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/archiveBusinessCapabilityCategory/${queryParams.companyId}`).get().json(),
    getArchiveCurrencies: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/archiveCurrency/${queryParams.companyId}`).get().json(),
    getArchiveSalary: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/archiveSalaryComponent/${queryParams.companyId}`).get().json(),
    getArchiveApplication: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/getArchiveCompApplication/${queryParams.companyId}`).get().json(),
    getArchiveAssignments: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/getArchiveCompAssignment/${queryParams.companyId}`).get().json(),
    getArchiveClients: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/getArchiveCompanyClient/${queryParams.companyId}`).get().json(),
    getArchiveVendors: (queryParams = {}) =>
        gateway.url(`${allArchiveUrl}/getArchiveCompanyVendor/${queryParams.companyId}`).get().json(),
    addTeamAssociateForClient: (body) => gateway.url(memberMgmtForClientUrl).post(body).json(),
    addTeamAssociateForVendor: (body) => gateway.url(memberMgmtForVendorUrl).post(body).json(),
    updateTeamAssociateForClient: (body) => gateway.url(memberMgmtForClientUrl).put(body).json(),
    updateTeamAssociateForVendor: (body) => gateway.url(memberMgmtForVendorUrl).put(body).json(),
    deleteTeamAssociateForClient: (clientId, email) => gateway.url(`${memberMgmtForClientUrl}/${clientId}/${email}`).delete().json(),
    deleteTeamAssociateForVendor: (vendorId, email) => gateway.url(`${memberMgmtForVendorUrl}/${vendorId}/${email}`).delete().json(),
});

export const allArchiveActionCreators = (): {
  actionCreators: ActionCreators;
  headers: Record<string, string>;
} => {
  const authHeaders: { [key: string]: string } = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const defaultHeaders: { [key: string]: string } = {
    // 'Session-Id': getCookie('sessionId'),
    "X-Api-Key": process.env.REACT_APP_COMPANY_SERVICE_MGMT_KEY as any,
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("accessToken")}` || ``,
  };

  const headers = !getCookie("accessToken") ? authHeaders : defaultHeaders;
  const w = wretch().catcher(401, (err) => console.log({ err: "401 nè" }));
  const gateway = w.headers(headers);
  const actionCreators = getActionCreators(gateway);

  return { actionCreators, headers };
};
