import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core";

type Props = {
	label?: string;
	required?: boolean;
	hiddenLabel?: boolean;
};

const useStyles = makeStyles((theme) => {
	return {
		block: {
			fontSize: "1rem",
			marginBottom: "1rem",
			[theme.breakpoints.down("xs")]: {
				marginBottom: "0.5rem",
			},
		},
		required: { color: "#C22026", paddingRight: "5px", display: "inline" },
		hiddenLabel: { opacity: 0, display: "inline" },
		label: { display: "inline" },
	};
});

const FormItemLabel: React.FC<Props> = (props) => {
	const { children, label, required, hiddenLabel } = props;

	const classes = useStyles();
	return (
		<>
			{label && (
				<Box className={classes.block}>
					{required && <Box className={classes.required}>*</Box>}
					{hiddenLabel ? (
						<Box className={classes.hiddenLabel}>{label}</Box>
					) : (
						<Box className={classes.label}>{label}</Box>
					)}
				</Box>
			)}
			<Box>{children}</Box>
		</>
	);
};

export default FormItemLabel;
